<template>
  <div
    :class="{ overlay: tipOverlay?.visible }"
    v-if="tipEnabledList?.[props.tipId] == true"></div>
  <OverlayPanel
    v-if="!(tipEnabledList?.[props.tipId] == false)"
    ref="tipOverlay"
    :showCloseIcon="showCloseIcon"
    alignOverlay
    :dismissable="dismissable"
    @hide="tipHide"
    :pt="{
      root: { class: 'p-overlaypanelTip' },
    }">
    <div class="p-3 border-round-top tipTitel flex justify-content-center">
      {{ tip.title }}
    </div>
    <div class="grid p-3">
      <div class="col-2">
        <i :class="tip.contentIcon + ' text-blue-500 text-3xl'"></i>
      </div>
      <div class="col-10" v-html="tip.contentText"></div>
      <div v-html="tip.image"></div>
      <div v-html="tip.contentFooter"></div>
    </div>
    <div class="p-1 grid text-xs">
      <div class="col-12 p-0 m-0 flex justify-content-center">
        <Button
          text
          label="Diesen Tip nicht mehr anzeigen"
          class="text-gray-400 text-xs"
          icon="pi pi-eye-slash"
          @click="dontShowTip" />
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, computed } from "vue";
import tips from "./tips.js";
import router from "@/router";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

const store = userStore();
const { tipEnabledList } = storeToRefs(store);

const tipEnabled = () => {
  if (store.debug) {
    console.log("🚀 ~ file: Tip.vue:40 ~ tipEnabledList:", tipEnabledList);
    console.log(
      "🚀 ~ file: Tip.vue:39 ~ tipEnabledList?.value?.[props.tipId]:",
      tipEnabledList?.value?.[props.tipId]
    );
    console.log("🚀 ~ file: Tip.vue:41 ~ props:", props);
  }
  return;
  tipEnabledList?.value?.[props.tipId];
};

const tipOverlay = ref();

const RdyToShow = ref(false);
const props = defineProps({
  overlay: {
    type: Boolean,
    required: false,
    default: true,
  },
  tipId: {
    type: String,
    required: true,
  },
  target: {
    required: false,
    default: "false",
  },
  placement: {
    type: String,
    required: false,
    default: "top",
  },
  route: {
    type: String,
    required: false,
    default: "",
  },
  routeLabel: {
    type: String,
    required: false,
    default: "OK",
  },
  sideTitel: {
    type: String,
    required: false,
    default: "",
  },
  timeout: {
    required: false,
    default: 0,
  },
  showCheckbox: {
    required: false,
    default: true,
  },
  showCloseIcon: {
    required: false,
    default: true,
  },
  dismissable: {
    required: false,
    default: false,
  },
});

const tip = computed(() => {
  return tips.find((tip) => tip.tipId === props.tipId);
});

function dontShowTip(event) {
  const tipId = props.tipId;
  if (!tipEnabledList?.value) {
    tipEnabledList.value = {};
  }
  tipEnabledList.value[tipId] = false;
  store.setCompanyTips(tipEnabledList.value);
  tipHide();
}
let target = null;
const tipShow = () => {
  if (tipEnabled() === false) return;
  if (tipOverlay.value) {
     target = document.querySelector(props.target);
    if (!target) return;
    const event = { currentTarget: target };
    setTimeout(() => {
      tipOverlay?.value?.show(event);
      if (target) {
        target.classList.add("target--highlighted");
      }
    }, 100);
  }
};

const tipHide = () => {
  setTimeout(() => {
    if (!target) return;
    //const target = document.querySelector(props.target);
    if (tipOverlay.value) {
      tipOverlay.value.hide();
      if (target) {
        target.classList.remove("target--highlighted");
      }
    }
  }, 300);


};

onMounted(async () => {
  try {
  } catch (e) {
    console.error(e);
  }
  if (props.route != "" && props.route != "OK") {
    const newZIndexValue = 1000;
    const targetElement = document.querySelector(props.target);
    if (targetElement) {
      targetElement.style.zIndex = newZIndexValue;
    }
  }

  RdyToShow.value = true;
  if (RdyToShow.value)
    setTimeout(() => {
      tipShow();
    }, 20);
});
</script>
<style>
.tipTitel {
  font-weight: 500;
  font-size: 1.1rem;
  color: #fff;
  background: #20858e;
}

.p-overlaypanelTip {
  /* background: #20858e; */
  /* box-shadow: 15px 5px 15px 45px rgba(255, 255, 255, 0.5);*/
  max-width: 38rem;
  box-shadow: 15px 5px 100px 70px rgb(0 121 69 / 50%);
}

.p-overlaypanelTip.p-overlaypanel-flipped::before {
  border-top-color: #20858e;
}

.p-overlaypanelTip.p-overlaypanel-flipped::after {
  border-top-color: #20858e;
}

.p-overlaypanelTip.p-overlaypanel::before {
  border-bottom-color: #20858e;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
}

.p-overlaypanelTip.p-overlaypanel::after {
  border-bottom-color: #20858e;
}
</style>
<style scoped>
:deep(.overlay) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1 !important;
}
</style>
<style>
/*tips*/
@keyframes glowing {
  0% {
    box-shadow: 0 0 0 2px #00b11db8;
  }

  50% {
    box-shadow: 0 0 0 10px #00ff2a;
  }

  100% {
    box-shadow: 0 0 0 2px #00b11db8;
  }
}

.target--highlighted {
  animation: glowing 1300ms infinite;
  /* box-shadow: 0 0 0 99999px rgba(0,0,0,.4); */
  /*  box-shadow: 0 0 0 4px #06ff30b8!important; */
}

/*tips*/
</style>
