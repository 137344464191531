import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const InvoicesService = {
  async getData(timeStamp) {
    try {
      const store = userStore();
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/invoice-list");
      const invoices = await response.data;
      store.dataStates.invoices = timeStamp;
      store.setDataToStorage("invoices", invoices);
      return Promise.resolve(invoices);
    } catch (e) {
      console.log(e);
    }
  },

  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/change-stats ");
    const data = await response.data;

    console.log("🚀 ~ file: InvoiceService.js:56 ~ data:", data)

    return Promise.resolve(data);
  },

  async getInvoices() {
    const key = "invoices";
    const store = userStore();
    const states = await this.checkForChanges();
    const timestampBE = states?.invoices;
    const timestampFE = store?.dataStates?.invoices;

    if (states.cid != store.dataStates.cid) {
      store.dataStates.cid = states.cid;
      return Promise.resolve(this.getData(timestampBE));
    }
    
    const foragePassed = await store.forageCheckForKey(key);
    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
      return Promise.resolve(this.getData(timestampBE));
    } else {
      return store.getDataFromStorage(key);
    }
  },
};
