// src/models/Item.js
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export class Item {
  constructor() {
    const store = userStore();
    const { companySettings } = storeToRefs(store);

    this.posNr = 1;
    this.itemDescription = null;
    this.rabatt = 0;
    this.stock = 0;
    this.quantity = 1;
    this.VPE = { list: [{ VPE_EAN: null, VPE_QTY: 1, customInput: true }] };
    this.VPE_QTY = 1;
    this.isVPE = false;
    this.inputKind = null;
    this.itemActive = null;
    this.itemAlias = null;
    this.itemCustomId = null;
    this.itemImageUrl = null;
    this.itemInPrice = 0.0;
    this.itemName = null;
    this.itemOutPrice = 0.0;
    this.rabattAbzug = 0;
    this.itemType = "goods";
    this.itemUnit = companySettings.value.defaultItemUnit;
    this.item_id_company = null;
    this.options = null;
    this.itemTaxId = companySettings.value.defaultTaxId;
    this.itemTaxValue = companySettings.value.defaultTaxValue;
    this.itemTaxValueFloat = companySettings.value.defaultTaxValue / 100;
    this.updated = null;
    this.createdUser = null;
    this.updatedUser = null;
    this.isMHD = companySettings.value.defaultMHD;
    this.VkPreisBrutto = null;
    this.linkedItem = false;
    this.linkedVPEItem = null;
    this.summe = 0;
    this.selectedItem = null;
    this.searchTag= { tags: [] };
    this.itemParameters= {
      weight: 0,
      linkedItem: null,
      forbidSingleSale: false,
      discount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
    this.category={
      path: "Allgemein",
      selectedKey: "0",
      selectedLabel: "Allgemein",
      structure: {
        children: [{}],
        discountImageUrl: null,
        key: "0",
        label: "Allgemein",
        newProductImageUrl: null,
      },
    };
  }
}
