<template>
    <div class="card">
        <div class="flex-grow-1 flex justify-content-end">

            <Button class="shadow-7" label="Speichern" icon="pi pi-check" size="small" @click="saveForm()" />
        </div>
        <TabView>
            <TabPanel header="Impressum">
                <div class="card">
<!--                     <InputSwitch name="showImpressum" textValue v-model="formData.showImpressum" />
                    <label for="showImpressum" class="ml-2">
                        Link Impressum anzeigen
                    </label> -->
                    <Button label="Impressum generieren"  text icon="pi pi-refresh" class="p-button-success text-xs p-0 m-0" v-tooltip="'Die Rechtssicherheit vom Impressum bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit. Durch anklicken, wird der aktuelle Inhalt überschrieben!'" @click="generateImpressum()"/>
                    <small class="text-red-300 text-xs">Die Rechtssicherheit vom Impressum bitte selber überprüfen. Keine Gewähr auf Rechtsicherheit.</small>
                    <Editor v-model="formData.impressum" contentType="html" editorStyle="height: 640px" />

              </div>
            </TabPanel>
            <TabPanel header="AGB">
                <div class="card">
                    <Editor v-model="formData.agb" editorStyle="height: 640px" />
                </div>
            </TabPanel>
            <TabPanel header="Widerrufsbelehrung">
                <div class="card">
                    <Editor v-model="formData.widerruf" editorStyle="height: 640px" />
                </div>
            </TabPanel>
            <TabPanel header="Datenschutzerklaerung">
                <div class="card">
                    <Editor v-model="formData.datenschutz" editorStyle="height: 640px" />

                </div>
            </TabPanel>
            <TabPanel header="Versandbedingungen">
                <div class="card">
                    <Editor v-model="formData.versandBedingungen" textValue  editorStyle="height: 640px" />
                </div>
            </TabPanel>
        </TabView>
    </div>
</template>

<script setup>
import { ref, reactive, onBeforeMount } from "vue";
import { userStore } from "@/store/userStore"
import { useToast } from "primevue/usetoast";
import iAxios from "@/store/axiosInterface";

const value = ref('');
const store = userStore();
const toast = useToast();

const formData = reactive({
    agb: "",
    widerruf: "",
    datenschutz: "",
    versandBedingungen: "",
    impressum: "",
    showImpressum: true,
});

const generateImpressumHTML = () => {
  const data = store.whoAmIData;
  const rechtsform = data.rechtsForm || "Einzelunternehmer"; // Standard: Einzelunternehmer
  const name = data.cname || `${data.firstName} ${data.surName}`;
  const address = `${data.streetName} ${data.houseNumber}<br>${data.postCode} ${data.city}<br>Deutschland`;
  const contact = `
    Telefon: ${data.phone || "Nicht angegeben"}<br>
    ${data.email ? `E-Mail: ${data.email}<br>` : ""}
    ${data.website ? `Internet: ${data.website}<br>` : ""}
  `;
  let html = `<h1>Impressum</h1><p><br></p>`;

  switch (rechtsform) {
    case "Einzelunternehmer":
    case "Kleinstunternehmer":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "GbR":
      html += `
        <p>${name} GbR<br>${address}</p>
        <p><strong>Vertretung des Unternehmens:</strong><br>Vertreten durch ${data.firstName} ${data.surName}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "GmbH":
    case "UG (haftungsbeschränkt)":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Vertretungsberechtigter Geschäftsführer:</strong><br>${data.firstName} ${data.surName}</p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${data.registerGericht ? `Registergericht: ${data.registerGericht}</p>` : ""}
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "AG":
    case "SE (Europäische Aktiengesellschaft)":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Vorstand:</strong><br>${data.firstName} ${data.surName}</p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${data.registerGericht ? `Registergericht: ${data.registerGericht}</p>` : ""}
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "PartG":
    case "PartGmbB":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        <p><strong>Partner:</strong><br>${data.firstName} ${data.surName}</p>
        ${data.kammer ? `<p><strong>Angaben zur Kammer:</strong><br>${data.kammer}</p>` : ""}
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "Freiberufler":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.kammer ? `<p><strong>Angaben zur Kammer:</strong><br>${data.kammer}</p>` : ""}
        ${data.berufsBezeichnung ? `<p><strong>Berufsbezeichnung:</strong><br>${data.berufsBezeichnung}</p>` : ""}
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    case "e.V.":
    case "eG":
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.hrbNummer ? `<p>Registernummer: ${data.hrbNummer}<br>` : ""}
        ${data.registerGericht ? `Registergericht: ${data.registerGericht}</p>` : ""}
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;

    default:
      html += `
        <p>${name}<br>${address}</p>
        <p><br></p><p><strong>Kontakt:</strong><br>${contact}</p><p><br></p>
        ${data.steuerUmsatzId ? `<p>Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: ${data.steuerUmsatzId}</p>` : ""}
      `;
      break;
  }

  return html;
};



const generateImpressum = () => {
  formData.impressum = generateImpressumHTML();
  //return html;
};

const saveForm = async () => {
    try {
        //await validationSchema.validate(formData, { abortEarly: false });
        iAxios.patch(process.env.VUE_APP_BASE_API_URL +"/company/fullfill", formData);
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Rechtstexte erfolgreich gespeichert",
            life: 3000,
        });
    } catch (error) {
        toast.add({
            severity: "error",
            summary: "Eingabefehler",
            detail: error.message,
            life: 3000,
        });
    }
};

async function werteLaden() {
    const companyData = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/details");
   if (store.debug) console.log("🚀 ~ file: LegalTextsSettingsForm.vue:91 ~ werteLaden ~ companyData:", companyData)
    Object.assign(formData, companyData.data);

}

onBeforeMount(() => {
    werteLaden();
});
</script>