import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'

//formatiert das Datum nach dem Schema: dd.mm.jjjj
const formatDatum = (datum) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(datum);
    return date.toLocaleDateString(undefined, options);
};



/**
 * Speichert die Briefsdaten und erhält die PDF-Datei.
 * @param {Object} injectedValue - Die injizierten Werte, die die Briefsdaten enthalten.
 * @param {string} proceedType - Der Vorgangstyp, der angibt, wie die Funktion ausgeführt werden soll.
 * Mögliche Werte:  "save" => default bei null speichert und lädt direkt runter. Erstellt eine Brief.
 *                  "print"=> direkt Ausdruck, ohne speichern,
 *                  "download=>nur download ohne speichern".
 * @param {boolean} preview - Gibt an, ob eine Vorschau angezeigt werden soll.
 * @returns {Promise} - Ein Promise-Objekt, das die Antwort der Serveranfrage enthält.
 */
export const saveAndGetPDF = async (injectedValue, proceedType, preview, setDownload) => {
    //injectedValue.data.loading = true;
    console.log("🚀 ~ file: letterUtils.js:69 ~ saveAndGetPDF ~ setDownload:", setDownload)

    //injectedValue.data.briefsDaten.docContent.documentDatum = formatDatumUS(injectedValue.data.briefsDaten.docContent.documentDatum);

    console.log("🚀 ~ file: letterUtils.js:69 ~ saveAndGetPDF ~ preview:", preview)


    console.log("🚀 ~ file: letterUtils.js:69 ~ saveAndGetPDF ~ proceedType:", proceedType)


    console.log("🚀 ~ file: letterUtils.js:69 ~ saveAndGetPDF ~ injectedValue:", injectedValue)

    //console.clear();
    const isPrint = (proceedType === "print");
    const downloadOnly = (proceedType === "download");

    console.log("🚀 ~ file: letterUtils.js:41 ~ downloadOnly:", downloadOnly)

    const isDownload = (proceedType === "download") || setDownload;
    const deleteLetter = (proceedType === "deleteLetter");
    const briefsDaten = injectedValue.data.briefsDaten;
    const letterDocumentId = briefsDaten.letterDocumentId;
    const saveLetter = (proceedType === "saveLetter");
    const isletterDocumentId = letterDocumentId != null;
    const documentDownlaod = letterDocumentId ? letterDocumentId + "/download" : "";
    const docId = letterDocumentId != null ? "/" + letterDocumentId : '';

    console.log("🚀 ~ file: letterUtils.js:102 ~ saveAndGetPDF ~ letterDocumentId:", letterDocumentId)

    console.log("🚀 ~ file: letterUtils.js:102 ~ saveAndGetPDF ~ saveLetter:", saveLetter)

    briefsDaten.as_attachment = isDownload;
    //delete flatBrief.productItemList;
    let url = "/company";
    switch (proceedType) {
        case "saveLetter": url += "/create-letter"; break;
        case "print": url += "/download-letter" + docId; break;
        //case "print": (readOnly ? url += "/download-letter" + docId : url += "/create-letter-draft" + docId); break;
        //case "print": url += "/create-letter"; break;
        case "download": url += "/download-letter" + docId; break;
       // case "download": (readOnly ? url += "/download-letter/" + documentDownlaod : url += "/create-letter-draft/") + documentDownlaod; break;
        case "deleteDraft": break;
        default: console.log("Unbehandelter Proceed Type!"); break;
    }
    console.log("🚀 ~ file: letterUtils.js:112 ~ saveAndGetPDF ~ url:", url)
    console.log("🚀 ~ file: letterUtils.js:112 ~ saveAndGetPDF ~ docId:", docId)

    //patch
    if ((docId && !isPrint && !downloadOnly)) {
        console.log("patch",proceedType)
        url="company/letter-edit"
        await iAxios.patch(url, briefsDaten, {
            responseType: 'blob',
        })
            .then((response) => {

                console.log("🚀 ~ file: letterUtils.js:140 ~ .then ~ response:", response)
                console.log("🚀 ~ file: letterUtils.js:98 ~ briefsDaten:", briefsDaten)

                //setDocNameFromHeader(response, injectedValue);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "saveLetter": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                    case "download": download(response.data, "Brief_" + briefsDaten.receiver.name1 + "_" + formatDatum(briefsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                    case "print": { printPDF(fileURL); break; }

                    default:
                        {
                            downloadPdf(blob, briefsDaten, injectedValue); break;
                        }
                }


            })

    }
    //get
     else if ( isPrint || downloadOnly) {
        console.log("get");
        await iAxios.get(url, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        })
            .then((response) => {
                console.log("🚀 ~ file: letterUtils.js:153 ~ saveAndGetPDF ~ url:", url)

                console.log("🚀 ~ file: letterUtils.js:156 ~ .then ~ response:", response)

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "print": { console.log("print"); printPDF(fileURL); break; }
                    case "downloadOnly": download(response.data, "Brief_" + briefsDaten.receiver.name1 + "_" + formatDatum(briefsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;

                    default:
                        {
                            downloadPdf(blob, briefsDaten, injectedValue); break;
                        }
                }
            })
    }
    else {
        console.log("else post")
        //post
        console.log("🚀 ~ file: letterUtils.js:179 ~ briefsDaten:",JSON.stringify( briefsDaten))
        console.log("🚀 ~ file: letterUtils.js:181 ~ injectedValue:", JSON.stringify(injectedValue.data.briefsDaten))

        await iAxios.post(url, briefsDaten, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then((response) => {

            console.log("🚀 ~ file: letterUtils.js:210 ~ saveAndGetPDF ~ response:", response)

            setDocNameFromHeader(response, injectedValue);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            switch (proceedType) {
                case "print": printPDF(fileURL); break;
                case "saveLetter":
                    if (preview) {
                        injectedValue.data.pdfFile = fileURL;
                       // injectedValue.data.letter.isDraft = false;
                        break;
                    } else {
                       // injectedValue.data.letter.isDraft = false;
                       downloadPdf(blob, briefsDaten, injectedValue); //DEBUG?
                        break;
                    } case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                case "download": downloadPdf(blob, briefsDaten, injectedValue); break;
                default:
                    {
                        downloadPdf(blob, briefsDaten, injectedValue); break;
                    }
            }

            console.log("🚀 ~ file: letterUtils.js:254 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)
        })




            .catch((error) => {
                console.error(error);
            });
    }
    injectedValue.data.loading = false;

    return injectedValue;
};


const setDocNameFromHeader = (response, injectedValue) => {
    console.log('Alle Header:');
    Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    });

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {
        injectedValue.data.briefsDaten.letterDocumentId = response.headers.letterdocumentid;
        injectedValue.data.briefsDaten.docName = response.headers.letterdocname;
    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    }
}

const downloadPdf = (blob, briefsDaten, injectedValue) => {
    console.log("🚀 ~ file: letterUtils.js:285 ~ downloadPdf ~ injectedValue:", injectedValue)

    download(blob, "Brief_" + briefsDaten.receiver.name1 + "_" + formatDatum(briefsDaten.docContent.documentDatum) + ".pdf", "application/pdf");
};



/**Vorraussetzung eines iframe mit der id=pdfFrame setzen in der Komponente von der aus der Druck aufgerufen wird.
 * <iframe id="pdfFrame" style="display:none"></iframe>
 * @param {*} pdfFile -url der PDF-Datei.
 * @returns
 */
export const printPDF = async (pdfFile) => {
    const iframe = document.getElementById('pdfFrame');
    iframe.src = pdfFile;

    // Warten, bis die PDF-Datei geladen wurde
    await new Promise((resolve) => {
        iframe.onload = resolve;
    });
    iframe.contentWindow.print();
};

export const setDocumentStatus = async (letterDocumentId, status) => {

    console.log("🚀 ~ file: letterUtils.js:294 ~ setDocumentStatus ~ status:", status)


    console.log("🚀 ~ file: letterUtils.js:295 ~ setDocumentStatus ~ letterDocumentId:", letterDocumentId)

    if (isNaN(letterDocumentId)) {
        console.error("letterDocumentId ist keine Zahl!: " + letterDocumentId);
        return false;
    }
    let url = process.env.VUE_APP_BASE_API_URL + "/company/letter-status";
    const formData = new FormData();
    formData.append("letterDocumentId", parseInt(letterDocumentId));
    formData.append("documentStatus", status);
    let response = await iAxios.post(url, formData)
        .then((response) => {
            //  console.log("🚀 ~ file: letterUtils.js:18 ~ .then ~ response:", response)
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};
