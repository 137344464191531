<template>
  <div class="letterbody p-0 m-0">
    <template v-if="!store.rules.activatedModules.businessLetter">
      <UpgradeLicence />
    </template>
    <template v-else>
      <iframe id="pdfFrame" style="display: none"></iframe>
      <Menubar :model="items" :ariaLabel="items.label">
        <template #item="{ item, props, hasSubmenu, root }">
          <a
            v-ripple
            class="flex align-items-center text-xs"
            :class="{ 'pl-1': item.label === '', 'pr-0': item.pr == '0' }"
            v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <Badge
              v-if="item.badge"
              :class="{ 'ml-auto': !root, 'ml-2': root }"
              :value="item.badge" />
            <span
              v-if="item.shortcut"
              class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1"
              >{{ item.shortcut }}</span
            >
            <InputSwitch
              v-if="item.sign"
              class="ml-2"
              v-model="briefDaten.docContent.showSignDoc"
              id="showSignDoc"
              invalid
              aria-labelledby="Unterschrift setzen"
              @update:model-value="toggleSignShow()">
            </InputSwitch>
            <InputSwitch
              v-if="item.stamp"
              class="ml-2"
              v-model="briefDaten.docContent.showStampDoc"
              id="showStampDoc"
              invalid
              aria-labelledby="Stempel setzen"
              @update:model-value="toggleStampShow()">
            </InputSwitch>
            <i
              v-if="hasSubmenu && item.label == ''"
              :class="[
                'pi pi-angle-down',
                {
                  'pi-angle-down -ml-2 text-primary': root,
                  'pi-angle-right ml-auto': !root,
                },
              ]"></i>
            <i
              v-if="hasSubmenu && item.label != ''"
              :class="[
                'pi pi-angle-down',
                { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root },
              ]"></i>
          </a>
        </template>
        <template #end>
          <!--           <div class="flex align-items-center gap-1">
            <div class="flex align-items-center">
              <InputSwitch
                v-model="briefDaten.docContent.showSignDoc"
                id="showSignDoc"
                invalid
                @update:model-value="toggleSignShow()">
              </InputSwitch
              ><span class="text-xs">Unterschrift</span>
            </div>
            <div class="flex align-items-center">
              <InputSwitch
                v-model="briefDaten.docContent.showStampDoc"
                id="showStampDoc"
                invalid
                @update:model-value="toggleStampShow()">
              </InputSwitch
              ><span class="text-xs">Stempel</span>
            </div>
          </div> -->
        </template>
      </Menubar>

      <div class="grid">
        <div class="col-6">
          <div v-if="showCustomer" class="CustomerOverlay"></div>
          <div class="CustomerField">
            <CustomerInputSearch
              v-if="showCustomer"
              :showCancel="true"
              :openPaymentsCheck="false"
              @inputSelected="handleCustomerSelected"
              @clearInput="clearInput"
              @cancel="showCustomer = false"
              auswahlText="Empfänger auswählen"
              neuErstellenText="neuen Kontakt erstellen" />
          </div>
        </div>
      </div>
      <div>
        <ScrollPanel style="width: 100%; height: 85vh">
          <div class="main">
            <DocumentEditor
              v-if="kopfBereich"
              class="editor"
              ref="editor"
              v-model:content="content"
              :overlay="overlay"
              :zoom="zoom"
              :page_format_mm="page_format_mm"
              :page_margins="page_margins"
              :display="display"
              :do_not_break="(elt) => elt.tagName.match(/section/i)" />
          </div>
        </ScrollPanel>
      </div>
    </template>

    <Dialog
      v-model:visible="saveDialog"
      modal
      :header="'Bezeichnung ' + bezeichnungKind"
      :style="{ width: '25rem' }">
      <span class="p-text-secondary block mb-5"
        >Die Bezeichnung ist keine Pflicht, kann aber beim wiederfinden von
        Vorteil sein.</span
      >
      <div class="flex align-items-center gap-3 mb-3">
        <InputText
          v-model="briefDaten.searchWords"
          id="searchWords"
          autofocus
          class="flex-auto"
          autocomplete="off"
          placeholder="Bezeichnung(optional)" />
      </div>
      <div class="flex justify-content-end gap-2">
        <Button
          type="button"
          label="Abbrechen"
          severity="secondary"
          @click="saveDialog = false"></Button>
        <Button
          type="button"
          label="Speichern"
          @click="
            saveDialog = false;
            saveLetter();
          "></Button>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  reactive,
  defineAsyncComponent,
  markRaw,
} from "vue";
import iAxios from "@/store/axiosInterface";
import { onBeforeMount } from "vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import CustomerInputSearch from "@/components/CustomerInputSearch.vue";
import { useConfirm } from "primevue/useconfirm";
import DocumentEditor from "@/components/DocumentEditor/DocumentEditor.vue";
import { saveAndGetPDF, printPDF } from "@/utils/letterUtils";
import { useDialog } from "primevue/usedialog";
import { errorToast } from "@/components/useDebugToast";
const dialog = useDialog();
const saveDialog = ref(false);
const LetterView = defineAsyncComponent(() => import("@/views/LetterView.vue"));
const LetterViewHeader = defineAsyncComponent(() =>
  import("@/views/LetterViewHeader.vue")
);
const LetterViewFooter = defineAsyncComponent(() =>
  import("@/views/LetterViewFooter.vue")
);
const EmailEditDialog = defineAsyncComponent(() =>
  import("@/views/EmailEditDialog.vue")
);
const store = userStore();
const toast = useToast();
const showCustomer = ref(false);
const companySettings = reactive({});
const companyData = reactive({});
const confirm = useConfirm();
const editor = ref();
const signatureImage = ref(require("@/assets/1x1-ffffffff.png"));
const stampImage = ref(require("@/assets/1x1-ffffffff.png"));

const companyLogoUrl = ref(require("@/assets/1x1-ffffffff.png"));
const imagePositionClass = ref("");
const imageSizeClass = ref("");
const logoImageRef = ref();
const maxPossibleBriefbogenHeight = ref(400);
const maxPossibleBriefbogenWidth = ref(595);
const maxImageLogoWidth = ref(0);
const maxImageLogoHeight = ref(0);
const imageLogoWidth = ref();
const imageLogoHeight = ref();
const imageBriefbogenHeight = ref(400);
const imageBriefbogenWidth = ref(595);
const adjustToHeight = ref(false);

const originalContent = ref({});
const eventListeners = [];
const bezeichnungKind = ref("eingeben");
const enableSaveButton = ref(true);

const kopfBereich = ref(null);
let content = ref([]);
let zoom = ref(0.8);
let page_format_mm = ref([210, 297]);
let page_margins = ref("10mm 24mm 10mm 24mm");
let display = ref("grid");
let mounted = ref(false);
let undo_count = ref(-1);
let content_history = ref([]);

async function werteLaden() {
  const resCompanyData = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/details"
  );
  Object.assign(companyData, resCompanyData.data);
  const resCompanySettings = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/settings"
  );

  //console.log("🚀 ~ file: BusinessLetter.vue:122 ~ werteLaden ~ resCompanySettings:", resCompanySettings)
  Object.assign(companySettings, resCompanySettings.data);
  await changeLogoImageClass();

  //companySettings= await JSON.parse(JSON.stringify(resCompanySettings.data)) ;

  //console.log("🚀 ~ file: BusinessLetter.vue:126 ~ werteLaden ~ companySettings:", companySettings)
}

console.log("🚀 ~ file: BusinessLetter.vue:74 ~ companyData:", companyData);

let initialDocumentSubject =
  "Bitte diesen Bereich mit dem Betreff überschrieben. Bsp. Kündigung xyz";
let initialTextContent =
  "Sehr geehrte Damen und Herren, <br/><br/> bitte diesen Bereich mit dem Text überschrieben. Bsp. hiermit kündige ich den Vertrag xyz fristgerecht zum 31.12.2021.<br/><br/>Mit freundlichen Grüßen";
let initialReceiver = {
  sendungsvermerk: "",
  name1: "Empfängername",
  name2: "",
  address1: "Strasse Hausnr.",
  address2: "",
  city: "Ort",
  postCode: "12345",
  emailAddress: null,
};

const initalBriefDaten = reactive({
  as_attachment: true,
  receiver: initialReceiver,
  //letter: { documentId: null },
  letterDocumentId: null,
  searchWords: null,
  docName: null,
  customerId: null,
  customerUid: null,
  docContent: {
    salutationText: store.companySettings.salutationText,
    documentDatum: new Date().toISOString().slice(0, 10),
    documentSubject: initialDocumentSubject,
    textContent: initialTextContent,
    greetings: null,
    showStampDoc: store.companySettings.inputStampInLetters,
    showSignDoc: store.companySettings.inputHandSignLetters,
  },
});

const briefDaten = reactive(JSON.parse(JSON.stringify(initalBriefDaten)));

console.log("🚀 ~ file: BusinessLetter.vue:267 ~ briefDaten:", briefDaten);

const toggleSignShow = () => {
  const hasNoSignImage =
    signatureImage.value == require("@/assets/1x1-ffffffff.png") ||
    !signatureImage.value ||
    signatureImage.value == "";
  if (hasNoSignImage) {
    toast.add({
      severity: "warn",
      summary: "Unterschrift",
      detail: "Keine Unterschrift in Einstellungen hinterlegt.",
      life: 3000,
    });
    briefDaten.docContent.showSignDoc = false;
    return;
  } else {
    briefDaten.docContent.showSignDoc = !briefDaten.docContent.showSignDoc;
  }
  enableSaveButton.value = true;
  updateStampSignOverlay();
};

const toggleStampShow = () => {
  const hasNoStampImage =
    stampImage.value == require("@/assets/1x1-ffffffff.png") ||
    !stampImage.value ||
    stampImage.value == "";
  if (hasNoStampImage) {
    toast.add({
      severity: "warn",
      summary: "Stempel",
      detail: "Kein Stempel in Einstellungen hinterlegt.",
      life: 3000,
    });
    briefDaten.docContent.showStampDoc = false;
    return;
  } else {
    briefDaten.docContent.showStampDoc = !briefDaten.docContent.showStampDoc;
  }
  enableSaveButton.value = true;
  updateStampSignOverlay();
};

const updateStampSignOverlay = async (noChange) => {
  enableSaveButton.value = enableSaveButton.value ?? true;
  const combinedImage = await combineImages(
    signatureImage.value,
    stampImage.value
  );
  const stampsign = document.getElementById("stampsign");
  stampsign.innerHTML = `<img src="${combinedImage}" alt="Stempel Unterschrift" class="h-4rem"/>`;
};

const combinedImage = ref();
onBeforeMount(async () => {
  await werteLaden();
  await loadImage();
  loadImage("signature");
  loadImage("stamp");

  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile

  //await onImageLogoLoad();
  setTimeout(async () => {
    kopfBereich.value =
      '<div contenteditable="false" style="height:35mm" class="letterLogoPlace"><img ref="logoImageRef" src="' +
      companyLogoUrl.value +
      '" class="' +
      imagePositionClass.value +
      imageSizeClass.value +
      '" alt="letterCompanyLogo" /></div>';
    let adressBereich =
      '<div contenteditable="false" class=" linie-0"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div contenteditable="false" class="address-overline baseline-8pt"><br/><br/><br/><br/>' +
      companyData.cname +
      ", " +
      companyData.streetName +
      " " +
      companyData.houseNumber +
      ", " +
      companyData.postCode +
      " " +
      companyData.city +
      '</div><div contenteditable="false"  style="height: 39.6mm !important;" class="address baseline-10pt" id="receiver">' +
      receiverFeld +
      "</div>";
    let sideText =
      '<div contenteditable="false" class="sideText"><div class="align-bottom"><div contenteditable="false">' +
      companyData.firstName +
      " " +
      companyData.surName +
      "<br/>" +
      (companyData?.replyEmail ? companyData.replyEmail : companyData.email) +
      "<br/><span class='text-2xl'>✆</span> " +
      companyData.phone +
      "<br/><br/>" +
      '<br/><br/><br/><input type="date" class="inputDate p-component" value="' +
      briefDaten.docContent.documentDatum +
      '"></div></div></div>';
    let betreff =
      '<div id="documentSubject" contenteditable="true" class="editable documentSubject w-full">' +
      briefDaten.docContent.documentSubject +
      "</div><br/><br/><br/>";
    const textbereich = briefDaten.docContent.textContent;
    const greetings = briefDaten.docContent.greetings;
    let signs =
      '<div contenteditable="false" id="stampsign"><img contenteditable="false" class="h-4rem" src="' +
      stampImage.value +
      '" alt="" /> <img contenteditable="false" class="bg w-10rem" src="' +
      (signatureImage.value
        ? signatureImage.value
        : require("@/assets/BeispielUnterschrift.png")) +
      '" alt="" /></div>';
    let testLinie =
      '<div contenteditable="false" class=" linie-test"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div>';
    let combiSign =
      store.companySettings.stamp_sign_overlay && combinedImage.value
        ? '<div contenteditable="false" id="stampsign"><img class="h-4rem" src="' +
          combinedImage.value +
          '" alt="Stempel Unterschrift" /></div>'
        : signs;
    let addressOverlay =
      '<div style="cursor: pointer;" contenteditable="false" id="AdresseSetzen" class="address-overlay"></div>';
    let writerName =
      '<p contenteditable="false">' +
      companyData.firstName +
      " " +
      companyData.surName +
      "</p>";
    content = ref([
      "<span contenteditable=false>" +
        kopfBereich.value +
        adressBereich +
        sideText +
        addressOverlay +
        betreff +
        '<div class="editable textContent w-full" contenteditable="true">' +
        textbereich +
        "</div>" +
        "<section class='-mt-4' contenteditable=false id='stampsignSection'><br/>" +
        combiSign +
        "</div><div class='-mt-3' contenteditable=false>" +
        writerName +
        "</div><div contenteditable=false>" +
        "<br/><br/></div></section>" +
        "</span>",
    ]);
  }, 10); //heruntergesetzt am 03.10. von 1000 auf 10
  paintStampSign();
});

const paintStampSign = () => {
  setTimeout(() => {
    if (
      briefDaten.docContent.showStampDoc ||
      briefDaten.docContent.showSignDoc
    ) {
      updateStampSignOverlay(false);
    }
  }, 1000); //heruntergesetzt am 03.10. von 1000 auf 100
};

function removeWhiteBackground(imageSrc) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      let data = imageData.data;
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] > 200 && data[i + 1] > 200 && data[i + 2] > 200) {
          // wenn die Farbe weiß ist
          data[i + 3] = 0; // setze die Transparenz auf 0
        }
      }
      ctx.putImageData(imageData, 0, 0);
      resolve(canvas.toDataURL());
    };
    img.onerror = reject;
  });
}

const setAddress = () => {
  showCustomer.value = true;
  console.log("setAddress");
};
const clearInput = () => {
  console.log("clearInput");
};

const handleCustomerSelected = (customer) => {
  if (customer) {
    console.log("🚀 ~ file: BusinessLetter.vue:185 ~ customer:", customer);

    briefDaten.receiver.name1 = customer.value.name1;
    briefDaten.receiver.name2 = customer.value.name2;
    briefDaten.receiver.address1 =
      customer.value.street + " " + customer.value.houseNumber;
    briefDaten.receiver.address2 = customer.value.address2;
    briefDaten.receiver.postCode = customer.value.postCode;
    briefDaten.receiver.city = customer.value.city;
    briefDaten.customerUid = customer.value.customerUid;
    briefDaten.customerId = customer.value.customerId;
    briefDaten.receiver.emailAddress = customer.value.emailAddress;
  }

  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile
  let receiverDiv = document.getElementById("receiver");
  receiverDiv.innerHTML = receiverFeld;
  setTimeout(() => {
    showCustomer.value = false;
  }, 200); //geäbdert von 1000 auf 200 als test
};

async function combineImages(signSrc, stampSrc) {
  console.log("🚀 ~ file: BusinessLetter.vue:506 ~ signSrc:", signSrc);

  //falls img null ist, wird ein weißes Bild erstellt
  const whitePixel =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAgAB/axp9XQAAAAASUVORK5CYII=";

  const img1 = signSrc ? await removeWhiteBackground(signSrc) : whitePixel;
  const img2 = stampSrc ? await removeWhiteBackground(stampSrc) : whitePixel;

  let canvas = document.createElement("canvas");
  let ctx = canvas.getContext("2d");

  let image1 = new Image();
  image1.src = img1;
  await new Promise((resolve) => (image1.onload = resolve));

  let image2 = new Image();
  image2.src = img2;

  // Skaliere den Stempel auf eine maximale Breite von 58mm, wenn er größer ist
  const maxWidth = 58 * 3.779528; // Umrechnung von mm zu px

  //falls img null ist, wird ein weißes Bild erstellt und größe angegeben
  if (!stampSrc) {
    image2.width = maxWidth;
    image2.height = maxWidth;
  }
  if (!signSrc) {
    image1.width = maxWidth;
    image1.height = maxWidth;
  }

  await new Promise((resolve) => (image2.onload = resolve));

  if (image2.width > maxWidth) {
    let scale = maxWidth / image2.width;
    image2.width *= scale;
    image2.height *= scale;
  }

  // Skaliere die Unterschrift auf die Höhe des Stempels, wenn sie größer ist
  if (image1.height > image2.height) {
    let scale = image2.height / image1.height;
    image1.width *= scale;
    image1.height *= scale;
  }

  // Setze die Canvas-Größe auf die Größe des größeren Bildes
  canvas.width = Math.max(image1.width, image2.width);
  canvas.height = Math.max(image1.height, image2.height);

  // Zeichne die Bilder auf das Canvas
  if (briefDaten.docContent.showSignDoc)
    ctx.drawImage(image1, 0, 0, image1.width, image1.height);
  if (briefDaten.docContent.showStampDoc)
    ctx.drawImage(image2, 0, 0, image2.width, image2.height);

  return canvas.toDataURL();
}

const formatDatumUS = (datum) => {
  const date = new Date(datum);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Monate von 0-11, also +1 und führende Nullen hinzufügen
  const day = String(date.getDate()).padStart(2, "0"); // Führende Nullen hinzufügen
  return `${year}-${month}-${day}`;
};

// Methode, um die Seitenränder der letzten Seite zu ändern
const updateLastPageMargins = () => {
  // Stelle sicher, dass der Editor geladen ist
  if (editor.value) {
    // Suche alle Seiten innerhalb des Editors
    const pages = editor.value.$el.querySelectorAll(".page"); // Annahme: jede Seite hat eine Klasse 'page'

    if (pages.length > 0) {
      // Wähle die letzte Seite aus
      const lastPage = pages[pages.length - 1];

      // Ändere die Ränder der letzten Seite
      lastPage.style.margin = "10mm 24mm 38mm 24mm"; // Beispiel: Setze einen anderen unteren Rand für die Fußzeile
    }
  }
};

onMounted(() => {
  setTimeout(() => {
    briefDaten.docContent.showStampDoc =
      store.companySettings.inputStampInLetters;
    briefDaten.docContent.showSignDoc =
      store.companySettings.inputHandSignLetters;
  }, 200);
  const timeout = ref(2000);
  setTimeout(() => {
    console.log("eventlistener gesetzt");
    const clickableElementAddress = document.getElementById("AdresseSetzen");
    if (clickableElementAddress) {
      clickableElementAddress.addEventListener("click", setAddress);
    }

    mounted.value = true;
    addListeners(0);
    // Überprüfen, ob eine Änderung vorgenommen wurde
  }, timeout.value); //notwendig, damit die Elemente geladen werden am 03.10. von 2000 auf 200
});

const removeListeners = () => {
  eventListeners.forEach(({ div, listener }) => {
    div.removeEventListener("input", listener);
  });
};

onUnmounted(() => {
  // Entfernen der Event-Listener
  const clickableElementAddress = document.getElementById("AdresseSetzen");
  if (clickableElementAddress) {
    clickableElementAddress.removeEventListener("click", setAddress);
  }
  removeListeners();
});

watch(content, (new_content) => {
  //  Undo/Redo-Historie
});

const current_margins_name = computed(() => {
  const margins = this.margins.find(
    ([, margins]) => page_margins.value == margins
  );
  return margins ? margins[0] : page_margins.value;
});

const current_text_style = computed(() => {
  return mounted.value ? this.$refs.editor.current_text_style : false;
});

const isBold = computed(() => {
  const fontWeight = current_text_style.value.fontWeight;
  return (
    fontWeight &&
    (parseInt(fontWeight) > 400 || fontWeight.indexOf("bold") == 0)
  );
});

const isItalic = computed(() => {
  return current_text_style.value.fontStyle == "italic";
});

const isUnderline = computed(() => {
  const stack = current_text_style.value.textDecorationStack;
  return stack && stack.some((d) => d.indexOf("underline") == 0);
});

const isStrikeThrough = computed(() => {
  const stack = current_text_style.value.textDecorationStack;
  return stack && stack.some((d) => d.indexOf("line-through") == 0);
});

const can_undo = computed(() => {
  return undo_count.value > 0;
});

const can_redo = computed(() => {
  return content_history.value.length - undo_count.value - 1 > 0;
});

const overlay = (page, total) => {
  let footer =
    '<div contenteditable="false" class="linie-4"><img src="' +
    require("@/assets/1pxGray.png") +
    '" class="linie-img-helper" alt="linie"></div><table><tr><th style="width: 4.9cm">Anschrift </th><th style="width: 4.9cm">Bankverbindung</th><th style="width: 6.6cm">Kontakt</th></tr><tr><td>' +
    companyData.cname +
    "</td><td>" +
    companyData.kontoInhaber +
    "</td><td>" +
    (companyData.website ? companyData.website : "") +
    " " +
    companyData.email +
    " - " +
    companyData.phone +
    "</td></tr><tr><td>" +
    companyData.streetName +
    " " +
    companyData.houseNumber +
    "</td><td>" +
    companyData.bankName +
    "</td><td>" +
    (companyData.rechtsForm ? companyData.rechtsForm : "") +
    " - Geschäftsführer: " +
    companyData.firstName +
    " " +
    companyData.surName +
    "<td><tr><td>" +
    companyData.postCode +
    " " +
    companyData.city +
    "</td><td>" +
    companyData.iban +
    "</td><td>" +
    (companyData.registerGericht ? companyData.registerGericht : "") +
    " - " +
    (companyData.hrbNummer ? companyData.hrbNummer : "") +
    "</td><tr><td>" +
    (companyData.steuerNrArt ? companyData.steuerNrArt : "") +
    " " +
    (companyData.steuerUmsatzId ? companyData.steuerUmsatzId : "") +
    "</td><td>" +
    companyData.bic +
    "</td></tr>";
  const subPageinfo =
    '<div class="designedBy" contenteditable="false">' +
    "Designed by FAMTURA - famtura.de" +
    '</div><div class="letterPageMarker">' +
    page +
    "/" +
    total +
    "</div>";

  let html = subPageinfo;

  if (page == total) {
    html +=
      '<div contenteditable="false" class="linie linie-1"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div class="linie linie-2"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div><div class="linie linie-3"><img src="' +
      require("@/assets/1pxGray.png") +
      '" class="linie-img-helper" alt="linie"></div>';
    html += '<div contenteditable="false" class="address-wrapper"></div>';
    html += '<div editable="false" class="letterFooter">' + footer + "</div>";
  }
  return html;
};

/* const loadImage = async (type) => {
type = type ?? "logo";
const stamp = type === "stamp";
const signature = type === "signature";
try {
let url = process.env.VUE_APP_BASE_API_URL + "/company/" + type;
let response = await iAxios.get(url, {
responseType: 'arraybuffer',
headers: {
'Content-Type': 'application/json',
}
})
.then(async (response) => {
const blob = new Blob([response.data]);
const fileURL = URL.createObjectURL(blob);
if (stamp) {
  stampImage.value = fileURL;
} else if (signature) {
  signatureImage.value = fileURL;
} else {
  companyLogoUrl.value = fileURL;
}
})
.catch((error) => {
console.error(error);
});
} catch (error) {
console.error(error);
}
}; */

const loadImage = async (type) => {
  type = type ?? "logo";
  const isStamp = type === "stamp";
  const isSignature = type === "signature";
  try {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/" + type;
    let response = await iAxios
      .get(url, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(async (response) => {
        const blob = new Blob([response.data]);
        const fileURL = URL.createObjectURL(blob);
        if (isStamp) {
          if (response.status == 200) {
            stampImage.value = fileURL;
          } else {
            stampImage.value = null;
          }
        } else if (isSignature) {
          if (response.status == 200) {
            signatureImage.value = fileURL;
          } else {
            signatureImage.value = null;
          }
        } else {
          if (response.status == 200) {
            companyLogoUrl.value = fileURL;
          }
          if (response.status == 204) {
            companyLogoUrl.value = require("@/assets/1x1-ffffffff.png");
          }
        }

        // Wenn sowohl das Stempelbild als auch das Unterschriftsbild geladen sind, kombinieren Sie sie
        //if (stampImage.value && signatureImage.value) {
        if (store.companySettings.stamp_sign_overlay) {
          //Wenn überlappen aktiviert ist
          combinedImage.value = await combineImages(
            signatureImage.value,
            stampImage.value
          );
        }
      })
      .catch((error) => {
        // errorToast(error);
        //console.error(error);
      });
  } catch (error) {
    // errorToast(error);
    //console.error(error);
  }
};
const getCompanyLogoSize = () => {
  /*   if (logoImageRef.value?.offsetWidth) {
    const width = logoImageRef.value.offsetWidth;
    const height = logoImageRef.value.offsetHeight;
    imageLogoWidth.value = width;
    imageLogoHeight.value = height;
    console.log(`-companyLogo Größe: ${width}x${height}`);
  }*/
  imageLogoHeight.value = store.companySettings.guiSettings.companyLogoHeight;
  imageLogoWidth.value = store.companySettings.guiSettings.companyLogoWidth;
};
const changeLogoImageClass = async () => {
  const size = companySettings.companyLogoSize; // Größe kann "LARGE", "MEDIUM" oder "SMALL" sein
  const position = companySettings.companyLogoPosition; // Position kann "LEFT", "RIGHT" oder "CENTER" sein

  console.log("🚀 ~ file: BusinessLetter.vue ~ position:", position);

  let newLogoPositionClass = "p-0 overlayLogo"; // Standardklasse für das Logo
  let newLogoSizeClass = "";

  // Setze die Größenklasse basierend auf der Einstellung
  if (size === "LARGE") {
    newLogoSizeClass += " letterLogoSizeLarge";
  } else if (size === "MEDIUM") {
    newLogoSizeClass += " letterLogoSizeMedium";
  } else if (size === "SMALL") {
    newLogoSizeClass += " letterLogoSizeSmall";
  }

  // Setze die Position basierend auf der Einstellung
  switch (position) {
    case "LEFT":
      newLogoPositionClass += " letterLogoPosition-LEFT";
      break;
    case "RIGHT":
      newLogoPositionClass += " letterLogoPosition-RIGHT";
      break;
    case "CENTER":
      newLogoPositionClass += " letterLogoPosition-CENTER";
      break;
    default:
      break;
  }

  imagePositionClass.value = newLogoPositionClass;
  imageSizeClass.value = newLogoSizeClass;

  // Dynamische Styles für Logo-Größen setzen
  const style = document.createElement("style");
  let existingStyle = document.querySelector('style[data-id="custom-style"]');

  if (!existingStyle) {
    style.setAttribute("data-id", "custom-style");
    document.head.appendChild(style);
    existingStyle = style;
  }

  let logoHeightLarge = "30mm"; // Beispielgröße für Groß
  let logoHeightMedium = "20mm"; // Beispielgröße für Mittel
  let logoHeightSmall = "10mm"; // Beispielgröße für Klein

  existingStyle.innerHTML = `
    .letterLogoSizeLarge {
      height: ${logoHeightLarge};
      width: auto;
    }
    .letterLogoSizeMedium {
      height: ${logoHeightMedium};
      width: auto;
    }
    .letterLogoSizeSmall {
      height: ${logoHeightSmall};
      width: auto;
    }
    .letterLogoPosition-LEFT {
      position: absolute;
      left: 40px;
    }
    .letterLogoPosition-RIGHT {
      position: absolute;
      right: 40px;
    }
    .letterLogoPosition-CENTER {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  `;
};

/* const changeLogoImageClass = async () => {
  const size = companySettings.companyLogoSize;
  const position = companySettings.companyLogoPosition;

  console.log("🚀 ~ file: BusinessLetter.vue:398 ~ position:", position);

  let newLogoPositionClass = "p-0 overlayLogo"; // Standardklasse
  let newLogoSizeClass = "";
  if (size == "LARGE") {
    newLogoSizeClass += " letterLogoSizeLarge";
  } else if (size == "MEDIUM") {
    newLogoSizeClass += " letterLogoSizeMedium";
  } else if (size == "SMALL") {
    newLogoSizeClass += " letterLogoSizeSmall";
  }
  // Positionsbehandlung
  switch (position) {
    case "LEFT":
      newLogoPositionClass += " letterLogoPosition-LEFT";
      break;
    case "RIGHT":
      newLogoPositionClass += " letterLogoPosition-RIGHT";
      break;
    case "CENTER":
      newLogoPositionClass += " letterLogoPosition-CENTER";
      break;
    default:
      break;
  }

  const style = document.createElement("style");
  let existingStyle = document.querySelector('style[data-id="custom-style"]');

  setTimeout(async () => {
    // getCompanyLogoSize();
    if (!existingStyle) {
      style.setAttribute("data-id", "custom-style");
      document.head.appendChild(style);
      existingStyle = style;
    }

    let logoHeightLarge = "";

    let logoWidthLarge = "";
    let logoWidthMedium = "";
    let logoHeightMedium = "";
    let logoWidthSmall = "";
    let logoHeightSmall = "";
    if (adjustToHeight.value == true) {
      logoWidthLarge = "auto";
      logoHeightLarge = `${maxImageLogoHeight.value}px`;
      logoWidthMedium = "auto";
      logoHeightMedium = `${maxImageLogoHeight.value * (2 / 3)}px`;
      logoWidthSmall = "auto";
      logoHeightSmall = `${maxImageLogoHeight.value * (1 / 3)}px`;
    } else {
      logoWidthLarge = `${maxImageLogoWidth.value}px`;
      logoHeightLarge = "auto";
      logoWidthMedium = `${maxImageLogoWidth.value * (2 / 3)}px`;
      logoHeightMedium = "auto";
      logoWidthSmall = `${maxImageLogoWidth.value * (1 / 3)}px`;
      logoHeightSmall = "auto";
    }

    existingStyle.innerHTML = `
.letterLogoSizeLarge {
    width: ${logoWidthLarge};
    height: ${logoHeightLarge};
}
.letterLogoSizeMedium {
    width: ${logoWidthMedium};
    height: ${logoHeightMedium};
}
.letterLogoSizeSmall {
    width: ${logoWidthSmall};
    height: ${logoHeightSmall};
}
.letterLogoPosition-LEFT {
    position: absolute;
    left: 40px;
}
.letterLogoPosition-RIGHT {
    position: absolute;
    right:  40px;
}
.letterLogoPosition-CENTER {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);}`;
  }, 10);

  imagePositionClass.value = newLogoPositionClass;
  imageSizeClass.value = newLogoSizeClass;
}; */

const checkAdjustToHeight = () => {
  console.log("loaf");
  if (logoImageRef.value) {
    const logoWidth = imageLogoWidth.value;
    const logoHeight = imageLogoHeight.value;
    adjustToHeight.value = logoWidth > logoHeight;
  }
};

const onImageLogoLoad = async () => {
  console.log("onImageLogoLoad");
  getCompanyLogoSize();
  maxPossibleBriefbogenHeight.value = Math.floor(
    imageBriefbogenHeight.value / 4
  );
  maxPossibleBriefbogenWidth.value = Math.floor(
    imageBriefbogenWidth.value - 80
  );
  maxImageLogoWidth.value =
    (await imageLogoWidth.value) > maxPossibleBriefbogenWidth.value
      ? maxPossibleBriefbogenWidth.value
      : imageLogoWidth.value;

  console.log(
    "🚀 ~ file: BusinessLetter.vue:281 ~ imageLogoWidth.value:",
    imageLogoWidth.value
  );

  console.log(
    "🚀 ~ file: BusinessLetter copy 2.vue:565 ~ maxImageLogoWidth.value :",
    maxImageLogoWidth.value
  );

  maxImageLogoHeight.value =
    (await imageLogoHeight.value) > maxPossibleBriefbogenHeight.value
      ? maxPossibleBriefbogenHeight.value
      : imageLogoHeight.value;
  checkAdjustToHeight();
  await changeLogoImageClass();
};

const newDocument = () => {
  confirm.require({
    header: "Neues Dokument erstellen?",
    message:
      "Nicht gespeicherte Änderungen gehen verloren, möchten Sie ein neues Dokument erstellen?",
    icon: "pi pi-question-circle",
    acceptLabel: "Ja",
    acceptIcon: "pi pi-check",
    rejectLabel: "Nein",
    rejectIcon: "pi pi-times",
    accept: () => {
      toast.add({
        severity: "info",
        summary: "neues Dokument",
        detail: "Neues DOkument erstellen gestartet",
        life: 3000,
      });
      const initData = JSON.parse(JSON.stringify(initalBriefDaten));
      Object.assign(briefDaten, initData);
      fillDocument(briefDaten);
      paintStampSign();
    },
    reject: () => {
      toast.add({
        severity: "error",
        summary: "Abbruch",
        detail: "Neues Dokument erstellen abgebrochen",
        life: 3000,
      });
    },
  });
};

const commandSaveUnder = () => {
  bezeichnungKind.value = "eingeben";
  saveDialog.value = true;
};

const commandChangeBezeichnung = () => {
  bezeichnungKind.value = "ändern";
  saveDialog.value = true;
};

const items = ref([
  {
    label: "Neues Dokument",
    icon: "pi pi-file",
    command: () => {
      newDocument();
    },
  },
  {
    label: "Öffnen",
    icon: "pi pi-folder-open",
    command: () => {
      openLetterView();
    },
  },
  {
    label: "Speichern",
    disabled: () => {
      return !enableSaveButton.value;
    },
    pr: 0,
    icon: "pi pi-save",
    command: () => {
      bezeichnungKind.value = "eingeben";
      if (briefDaten.receiver.name1 == "Empfängername") {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail:
            "Brief konnte nicht gespeichert werden. Bitte vorerst Empfänger auswählen.",
          life: 3000,
        });
        return;
      }
      saveDialog.value = true;
    },
  },
  {
    label: "Speichern unter",
    icon: "pi pi-save",
    class: "lg:hidden",
    command: () => {
      commandSaveUnder();
    },
  },
  {
    label: "Bezeichnung ändern",
    icon: "pi pi-bookmark",
    class: briefDaten.letterDocumentId ? "lg:hidden" : "hidden lg:hidden",
    command: () => {
      commandChangeBezeichnung();
    },
  },
  {
    label: "",
    class: "hidden lg:block",
    items: [
      {
        label: "Speichern unter",
        icon: "pi pi-save",
        command: () => {
          commandSaveUnder();
        },
      },
      {
        label: "Bezeichnung ändern",
        icon: "pi pi-bookmark",
        disabled: () => {
          return !briefDaten.letterDocumentId;
        },
        class: "hidden lg:block",
        command: () => {
          commandChangeBezeichnung();
        },
      },
    ],
  },
  {
    label: "Herunterladen",
    disabled: () => {
      return enableSaveButton.value;
    },
    icon: "pi pi-download",
    command: () => {
      if (downloadDocument()) {
        toast.add({
          severity: "success",
          summary: "Heruntergeladen",
          detail: "Brief wurde in den Downloadordner heruntergeladen",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Fehler",
          detail: "Brief konnte nicht heruntergeladen werden",
          life: 3000,
        });
      }
    },
  },
  {
    label: "Drucken",
    disabled: () => {
      return enableSaveButton.value;
    },
    icon: "pi pi-print",
    command: () => {
      printDocument();
      toast.add({
        severity: "info",
        summary: "Drucken",
        detail: "Druckvorgang gestartet",
        life: 2000,
      });
      setTimeout(() => {
        return;
      }, 10);
    },
  },
  {
    label: "per Email senden",
    disabled: () => {
      return enableSaveButton.value;
    },
    icon: "pi pi-send",
    command: () => {
      openEmailDialog();
    },
  },
  {
    label: "Unterschrift",
    sign: true,
    command: () => {
      toggleSignShow();
    },
  },
  {
    label: "Stempel",
    stamp: true,
    command: () => {
      toggleStampShow();
    },
  },
  {
    label: "Direkt Print",
    icon: "pi pi-print",
    visible: store.debug,
    command: () => {
      window.print();
    },
  },
  {
    label: "saveData Ausgabe auf Konsole",
    visible: store.debug,
    icon: "pi pi-send",
    command: () => {
      console.log(
        "🚀 ~ BUTTON file: BusinessLetter.vue:983 ~ JSON.stringify(briefDaten):",
        JSON.stringify(briefDaten)
      );
      saveData(briefDaten);
    },
  },
]);

const saveLetter = () => {
  if (saveDocument()) {
    enableSaveButton.value = false; //Speichern erfolgreich, daher andere Buttons freischalten und speichern Button deaktivieren
    toast.add({
      severity: "success",
      summary: "Speichern",
      detail: "Brief gespeichert",
      life: 3000,
    });
  } else {
    toast.add({
      severity: "error",
      summary: "Fehler",
      detail: "Brief konnte nicht gespeichert werden",
      life: 3000,
    });
  }
};

const saveData = (briefDaten) => {
  // Daten von den HTML-Elementen abrufen und an briefDaten übergeben
  briefDaten.docContent.documentDatum =
    document.querySelector(".inputDate").value;
  /*   briefDaten.docContent.greetings =
    document.querySelector("#greetings").innerHTML; */

  // Wähle alle Elemente mit der Klasse "textContent"
  const textContentDivs = document.querySelectorAll(".textContent");

  // Variable für den gesamten kombinierten Inhalt
  let updatedContent = "";

  // Iteriere über alle ausgewählten Elemente
  textContentDivs.forEach((textContentDiv) => {
    // Hole den aktuellen Inhalt des divs und ersetze alle <p> Tags durch <br/>
    let content = textContentDiv.innerHTML
      .replace(/<\/p>/g, "<br\/><br\/>") // Ersetze das schließende </p> durch <br/>
      .replace(/<p>/g, ""); // Entferne die öffnenden <p> Tags

    // Füge den aktualisierten Inhalt zum gesamten Inhalt hinzu
    updatedContent += content;

    // Aktualisiere den Inhalt des jeweiligen div
    textContentDiv.innerHTML = content;
  });

  // Weise den kombinierten Inhalt `briefDaten.docContent.textContent` zu
  briefDaten.docContent.textContent = updatedContent;

  //document.querySelector("#textContent")?.innerHTML;
  briefDaten.docContent.documentSubject =
    document.querySelector("#documentSubject").innerHTML;

  // Ersetzungen für textContent vornehmen
  /*   briefDaten.docContent.textContent =
    briefDaten.docContent.textContent?.replace(/<br>/g, "<br\/>"); */
  /*   briefDaten.docContent.greetings = briefDaten.docContent.greetings?.replace(
    /<br>/g,
    "<br\/>"
  ); */
  briefDaten.docContent.documentSubject =
    briefDaten.docContent.documentSubject?.replace(/<br>/g, "<br\/>");
  briefDaten.docContent.textContent =
    briefDaten.docContent.textContent?.replace(/<br>/g, "<br\/>");

  // Debugging-Ausgabe
  console.log("Daten nach Zuweisung:", JSON.stringify(briefDaten));

  // briefDaten zurückgeben
  return briefDaten;
};

const addListeners = (timeOut) => {
  const timeout = timeOut ?? 1000;
  setTimeout(() => {
    const editableDivs = document.querySelectorAll(".editable");

    // Speichern des ursprünglichen Inhalts
    editableDivs.forEach((div) => {
      originalContent.value[div.id] = div.innerHTML;
    });
    editableDivs.forEach((div) => {
      const listener = () => {
        if (div.innerHTML !== originalContent.value[div.id]) {
          console.log(`Änderung in ${div.id} vorgenommen`);
          enableSaveButton.value = true;
          originalContent.value[div.id] = div.innerHTML; // Aktualisieren des ursprünglichen Inhalts
        }
      };
      div.addEventListener("input", listener);
      eventListeners.push({ div, listener });
    });
  }, 1000);
};

const fillDocument = (letterData) => {
  removeListeners();
  editor.value.reset_content();
  console.log("🚀 ~ file: BusinessLetter.vue:611 ~ letterData:", letterData);

  Object.assign(briefDaten, letterData);
  const docDate = formatDatumUS(letterData.docContent.documentDatum);
  document.querySelector(".inputDate").value = docDate;

  document.querySelector(".textContent").innerHTML =
    letterData.docContent.textContent;
  /*   document.querySelector("#greetings").innerHTML =
    letterData.docContent.greetings; */
  document.querySelector("#documentSubject").innerHTML =
    letterData.docContent.documentSubject;
  let receiverFeld = "";
  receiverFeld +=
    briefDaten.receiver.name2 &&
    briefDaten.receiver.address2 &&
    briefDaten.receiver.name1
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //1.zeile
  receiverFeld +=
    briefDaten.receiver.name2 && briefDaten.receiver.address2
      ? briefDaten.receiver.name2 + "<br/>"
      : (briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
        (!briefDaten.receiver.name2 && briefDaten.receiver.address2)
      ? briefDaten.receiver.name1 + "<br/>"
      : "<br/>"; //2.zeile
  receiverFeld +=
    !briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name1 + "<br/>"
      : briefDaten.receiver.name2 && !briefDaten.receiver.address2
      ? briefDaten.receiver.name2
      : briefDaten.receiver.address1 + "<br/>"; //3.zeile
  receiverFeld +=
    (!briefDaten.receiver.name2 && !briefDaten.receiver.address2) ||
    (briefDaten.receiver.name2 && !briefDaten.receiver.address2)
      ? briefDaten.receiver.address1 + "<br/>"
      : briefDaten.receiver.address2 + "<br/>"; //4.zeile
  receiverFeld += briefDaten.receiver.postCode + " " + briefDaten.receiver.city; //5.zeile
  let receiverDiv = document.getElementById("receiver");
  receiverDiv.innerHTML = receiverFeld;
  enableSaveButton.value = false;
  updateLastPageMargins();
  editor.value.fit_content_over_pages();
  addListeners();
};

const openLetterView = async (event) => {
  dialog.open(LetterView, {
    props: {
      header: "Brief Öffnen",
      modal: true,
      closable: true,
      style: {
        width: "85vw",
      },
      breakpoints: {
        "960px": "75vw",
        "640px": "100vw",
      },
    },
    templates: {
      header: markRaw(LetterViewHeader),
      footer: markRaw(LetterViewFooter),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        const customerUid = options.data.customerUid;
        if (buttonType == "openLetter") {
          const letterData = await options.data.letterData;

          console.log(
            "🚀 ~ file: BusinessLetter.vue:1025 ~ letterData:",
            letterData
          );
          fillDocument(letterData);
        }
      }
    },
  });
};

const saveDocument = async () => {
  const download = false;
  const preview = !download;
  const briefsDaten = saveData(briefDaten);
  const data = { briefsDaten: briefsDaten };

  console.log("🚀 ~ file: BusinessLetter.vue:1050 ~ briefsDaten:", briefDaten);

  let response = await saveAndGetPDF({ data }, "saveLetter", preview, download);

  console.log(
    "🚀 ~ file: InvoicesEditFooter.vue:68 ~ saveDraftPreview ~ response:",
    response
  );
  return response;
};

const downloadDocument = async () => {
  const download = true;
  const preview = !download;
  const data = { briefsDaten: saveData(briefDaten) };

  let response = await saveAndGetPDF({ data }, "download", preview, download);

  console.log(
    "🚀 ~ file: InvoicesEditFooter.vue:68 ~ saveDraftPreview ~ response:",
    response
  );
  return response;
};

const printDocument = async () => {
  const download = false;
  const preview = !download;
  const data = { briefsDaten: saveData(briefDaten) };

  console.log("🚀 ~ file: BusinessLetter.vue:1026 ~ data:", data);

  let responseFile = await saveAndGetPDF({ data }, "print", preview, download);
};

const openEmailDialog = async (header) => {
  const isDraft = header == "Entwurf per Email senden";
  dialog.open(EmailEditDialog, {
    props: {
      header: "Brief per Email senden",
      subHeader: "",
      style: {
        width: "65vw",
      },
      breakpoints: {
        "960px": "55vw",
        "640px": "65vw",
      },
      modal: true,
    },
    data: {
      docEmailSettings: { message: "siehe Anlage. Mit freundlichen Grüßen" },
      docType: "LETTER",
      mainDocType: "LETTER",
      docName: briefDaten.docName,
      docTitel: briefDaten.docContent.documentSubject,
      documentId: briefDaten.letterDocumentId,
      emailAddress:
        briefDaten.receiver?.emailAddress != ""
          ? briefDaten.receiver.emailAddress
          : await findCustomerEmail(briefDaten.customerUid),

      previewURL: "/company/download-letter/" + briefDaten.letterDocumentId,
    },
    onClose: async (options) => {},
  });
};

const findCustomerEmail = async (customerUid) => {
  let email = "";
  try {
    const response = await iAxios.get(
      process.env.VUE_APP_BASE_API_URL +
        "/company/customer-details/" +
        customerUid
    );
    if (response.data) {
      debugResponse(toast, response);
      email = await response.data.customer.emailAddress;
    }
    return email;
  } catch (error) {
    //errorToast(toast, error);
    return "";
  }
};
</script>

<style>
.letterPageMarker {
  position: absolute;
  bottom: 8mm;
  right: 8mm;
  font-size: 8pt;
}

.designedBy {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  left: 4mm;
  font-size: 8pt;
  position: absolute;
  bottom: 7mm;
  white-space: nowrap; /* Verhindert Zeilenumbruch */
}

.letterFooter {
  position: absolute;
  line-height: 8pt;
  font-size: 8pt;
  font-family: Arial, sans-serif;
  left: 24mm;
  right: 24mm;
  bottom: 3mm;
  text-align: left;
  font-size: 8pt;
  margin-left: -2pt;
}

.textContent {
  font-family: Arial, sans-serif !important;
  font-size: 10pt !important;
  line-height: 13pt !important;
  margin: 0 !important;
  padding: 0 !important;
}
.editable {
  display: inline-block;
}
.editable:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.stampsignSwitch {
  z-index: 2;
}

@media screen and (min-width: 768px) {
  .md\:stampsignSwitch {
    bottom: 15rem !important;
    z-index: 2;
  }
}

.documentSubject {
  font-weight: bold;
  font-family: Arial, sans-serif !important;
  font-size: 10pt !important;
  line-height: 13pt !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>

<style scoped>
:deep().p-submenu-list {
  top: auto !important;
  right: auto !important;
  left: auto !important;
  z-index: 10;
}

:deep(.CustomerOverlay) {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1 !important;
}

.CustomerField {
  position: relative;
  z-index: 10 !important;
}

.main {
  width: fit-content;
  min-width: 100%;
}
</style>
