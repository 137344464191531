import { ItemsService } from "@/service/ItemsService";
import { userStore } from "@/store/userStore";
import { deleteLinkedItems } from "./linkedItemsUtils";
import { Item } from "@/models/Item";


export const isStockCheckInvalid = async (xDaten) => {
  const store = userStore();
  if (!store?.companySettings?.stockControl) return;
  let invalidStockItems = await getInvalidStockItems(xDaten);
  return invalidStockItems.itemsNotEnough || invalidStockItems.itemsNotExist;
}

/**
 * 
 * @param {*} xDaten 
 * @returns message: string, invalidStockItems: array, length: number
 */
export const getInvalidStockItems = async (xDaten) => {
  const store = userStore();
  let invalidStockItems = await getInvalidStockItemList(xDaten)
  let message = "";
  let itemsNotExist = false;
  let itemsNotEnough = false;
  invalidStockItems.forEach((element) => {
    if (element.itemNotExisting == true) {
      itemsNotExist = true;
      message += `Katalog-Artikel ${element.selectedItem.itemName} existiert nicht. Bitte ändern oder entfernen.\n`;
    } else if (!element.isDeposit && element.hasStock == false) {
      itemsNotEnough = true;
      message += `Katalog-Artikel ${element.selectedItem.itemName} ist nicht ausreichend auf Lager!\n`;
    }
  });
  //inalidStockItems wird in den shareSpace gespeichert
  let invalids = {message: message, itemsList: invalidStockItems, length: invalidStockItems.length, itemsNotExist: itemsNotExist, itemsNotEnough: itemsNotEnough}
  store.setShareSpace({invalidStockItems: invalids});
  return invalids;
}

//filter nach items die hasStock false sind oder itemNotExisting true sind
const getInvalidStockItemList = async (xDaten) => {
  console.log(xDaten);
  await checkStockExist(xDaten); //aktuelle Postionen werden mit dem zusätzlich Lagerbestand-Daten beinhaltende Liste ersetzt
  return xDaten.itemList.filter((element) => {
    return element.hasStock == false || element.itemNotExisting == true;
  });
}

export const checkStockExist = async (xDaten) => {
  console.log(xDaten);
  const itemList = await ItemsService.getItemStockExist(xDaten.itemList);
  Object.assign(xDaten.itemList,itemList);
}






//entferne alle Positionen, die nicht mehr im Katalog existieren itemList.itemNotExisting == true
export const removeDeletedArticlePositions = (xDaten) => {
  let store = userStore();
  let invalidStockItems = store.getShareSpace.invalidStockItems;
  invalidStockItems.itemsList.forEach((element) => {
    if (!element.itemNotExisting) {
      return;
    }
    let index = xDaten.itemList.indexOf(element);
    if (index !== -1) {
      deleteLinkedItems(xDaten, element);
      let deleted = xDaten.itemList.splice(index, 1);
    } else {
      console.log("Position nicht gefunden");
    }
  });
  xDaten.itemList.forEach((position, index) => {
    position.posNr = index + 1;
  });
  if (xDaten.itemList.length == 0) {
    addPosition(xDaten);
  }
}

//setze alle Positionen, die nicht ausreichend auf Lager sind, auf die maximal mögliche Menge stockVPE
export const setMaxPossibleQuantity = async (xDaten) => {
  let invalidStockItems = await getInvalidStockItemList(xDaten);
  invalidStockItems.forEach((element) => {
    if (!element.isDeposit) {
      element.quantity = element.stockVPE;
    }
  });
}


/* export const isStockCheckInvalid = async (xDaten) => {
  let store = userStore();
  let validation = {};

  store.setItems(await ItemsService.getItemsXLarge());

  xDaten.itemList.forEach((element, index) => {
    if (element?.selectedItem?.item_id_company) {
      let item = store.getItemMap[element.selectedItem.item_id_company];
      if (item) {
        if (item.isDeposit) {
          return;
        }
        let quantity = element.quantity;
        if (element.VPE_QTY) {
          quantity *= element.VPE_QTY;
        }
        if (item.stock < quantity) {
          if (element.VPE_QTY) {
            validation[index] = { hasStock: false, fixQuantity: Math.trunc(item.stock / element.VPE_QTY) };
          } else {
            validation[index] = { hasStock: false, fixQuantity: item.stock };
          }
        }
      } else {
        validation[index] = { hasStock: false, itemNotExisting: true };
      }
    }
  });

  if (Object.entries(validation).length > 0) {
    xDaten.validation = validation;
  } else {
    xDaten.validation = null;
  }
  return xDaten.validation;
} */

export const showToastAndReturnStockCount = async (position, toast, xDaten) => {
  let store = userStore();

  let companySettings = store.companySettings;
  let stockItem = position.selectedItem;

  if (stockItem?.isDeposit) {
    return;
  }

  if ( position.quantity === undefined) {
    position.quantity = 1;
  }
  let positionQuantity = await position.quantity;
  if (position.VPE_QTY) {
    positionQuantity *=  position.VPE_QTY;
  }

  if (stockItem.item_id_company) {
    const itemid=stockItem.item_id_company;
    stockItem =  await ItemsService.getItem(itemid);
  }
  if (store.debug) {
    console.log(
      "🚀 ~ file: stockUtils.vue:8 ~   stockItem:",
      stockItem
    );
    console.log(
      "🚀 ~ file: stockUtils.vue:12 ~   positionQuantity:",
      positionQuantity
    );
  }
  const stockControl = companySettings.stockControl;
  if (!stockControl) return positionQuantity;
  const isItemInStock = stockItem.stock >= positionQuantity;
  if (!isItemInStock) {
    let retStock = stockItem.stock;
    if (stockItem.stock < 0) {
      retStock = 0;
    }
    if (position.VPE_QTY) {
      retStock = Math.trunc(stockItem.stock / position.VPE_QTY);
    }

    const msg = `Artikel ${stockItem.itemName} ist nicht ausreichend auf Lager!`;
    const msg2 = `Artikel-Menge von ${stockItem.itemName} wurde auf ${retStock
      } reduziert!`;
    toast.add({ severity: "error", summary: msg, life: 3000 });
    toast.add({ severity: "info", summary: msg2, life: 3000 });

    return retStock;
  } else {
    let index = xDaten.itemList.indexOf(position);
    if (xDaten?.validation?.hasOwnProperty(index)) {
      delete xDaten.validation[index];
    }
    return position.quantity;
  }
};


/* export const getValidationTooltip = (rowIndex, xDaten) => {
  if (itemNotExists(rowIndex, xDaten)) {
    return "Dieser Artikel existiert nicht. Bitte position löschen, Artikel hinzufügen oder in einen manuellen artikel umwandeln."
  } else if (itemStockNotEnough(rowIndex, xDaten)) {
    return "Der Warenbestand dieses Artikels ist nicht genügend verfügbar.";
  }
}
 */
/* 
export const removePosValidation = (rowIndex, xDaten) => {
  if (xDaten?.validation?.hasOwnProperty(rowIndex)) {
    delete xDaten.validation[rowIndex];
  }
  Object.keys(xDaten.validation).forEach(o => {
    if (rowIndex < o) {
      let tempVal = xDaten.validation[o];
      delete xDaten.validation[o];
      xDaten.validation[o-1] = tempVal;
    }
  });

  checkAndDeleteValidationProp(xDaten);
}

const checkAndDeleteValidationProp = (xDaten) => {
  if (Object.entries(xDaten.validation).length == 0) {
    delete xDaten.validation;
  }
} 

export const itemStockNotEnough = (rowIndex, xDaten) => {
  return (xDaten?.validation?.hasOwnProperty(rowIndex) && !xDaten?.validation[rowIndex]?.hasStock);
}

export const itemNotExists = (rowIndex, xDaten) => {
  return (xDaten?.validation?.hasOwnProperty(rowIndex) && xDaten?.validation[rowIndex]?.itemNotExisting);
}

export const setMaxPossibleQuantity = (xDaten) => {
  Object.keys(xDaten.validation).forEach((v) => {
    
    if (!xDaten.validation[v].hasOwnProperty("fixQuantity")) {
      return;
    }
    xDaten.itemList[v].quantity = xDaten.validation[v].fixQuantity;
    delete xDaten.validation[v];
  });

  checkAndDeleteValidationProp(xDaten);
}

export const removeDeletedArticlePositions = (xDaten) => {
  Object.keys(xDaten.validation).forEach((v) => {
    if (!xDaten.validation[v].itemNotExisting) {
      return;
    }
    deletePos(xDaten.itemList[v], xDaten);
  });
}

export const deletePos = (position, xDaten) => {
  let index = xDaten.itemList.indexOf(position);
  if (index !== -1) {
    deleteLinkedItems(xDaten, position);
    let deleted = xDaten.itemList.splice(index, 1);
  } else {
    console.log("Position nicht gefunden");
  }
  removePosValidation(index, xDaten);

  if (xDaten.itemList.length == 0) {
    addPosition(xDaten);
  }
  //es wird durch die itemliste gegangen und die posNr der jeweiligen Positionen position.posNr, je nach index mit index+1 neu gesetzt
  xDaten.itemList.forEach((position, index) => {
    position.posNr = index + 1;
  });
}; */

export const addPosition = (xDaten) => {
  const itemList = new Item();
  itemList.posNr = xDaten.itemList.length + 1;
  xDaten.itemList.push({ ...itemList });
}