import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const setDocumentStatus = async (orderId, status) => {
  let url = process.env.VUE_APP_BASE_API_URL + "/company/order/" + orderId;
  let response = await iAxios.post(url, { documentStatus: status });
  return response;
}

export const OrdersService = {
  async getData(timeStamp) {
    try {
      const store = userStore();
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/order-list");
      const orders = await response.data;
      store.dataStates.orders = timeStamp;
      store.setDataToStorage("orders", orders);
      return Promise.resolve(orders);
    } catch (e) {
      console.log(e);
    }
  },

  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/change-stats ");
    const data = await response.data;

    console.log("🚀 ~ file: OrdersService.js:56 ~ data:", data)

    return Promise.resolve(data);
  },

  async getOrders() {
    const key = "orders";
    const store = userStore();
    const states = await this.checkForChanges();
    const timestampBE = states?.orders;
    const timestampFE = store?.dataStates?.orders;

    if (states.cid != store.dataStates.cid) {
      store.dataStates.cid = states.cid;
      return Promise.resolve(this.getData(timestampBE));
    }

    const foragePassed = await store.forageCheckForKey(key);
    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
      return Promise.resolve(this.getData(timestampBE));
    } else {
      return store.getDataFromStorage(key);
    }
  },

};
