import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { useToast } from "primevue/usetoast";
import { nextTick } from "vue";
import { Item } from "@/models/Item";
import { vkStufen } from "@/utils/calculationUtils.js";
import {
  syncLinkedItems,
  deleteLinkedItems,
} from "@/utils/linkedItemsUtils.js";
import { showToastAndReturnStockCount, removePosValidation } from "@/utils/stockUtils";

export const useDocumentUtils = () => {
  let toast;
  try {
    toast = useToast(); // Versuch, den Toast-Service zu initialisieren
  } catch (error) {
    toast = null; // Fallback auf null, falls nicht verfügbar, wegen Rasteransicht
  }
  const store = userStore();
  const { companySettings } = storeToRefs(store);

/*   const checkStockQuantityField = (position) => {
    const stockControl = companySettings.value.stockControl;
    if (!stockControl) {
      return;
    }
    if (position.selectedItem?.item_id_company) {
      position.quantity = checkStockItemField(
        position.selectedItem,
        position.quantity
      );
    } else {
      return;
    }
  };
 */

  const checkStockQuantityField = async (position,toast,docDaten) => {
    if (store.debug) {
      console.log(
        "🚀 ~ file: OffersEditComponent.vue:832 ~   position:",
        position
      );
    }
    const stockControl = companySettings.value.stockControl;
    if (!stockControl) {
      if (store.debug) {
        console.log(
          "🚀 ~ file: OffersEditComponent.vue:837 ~   stockControl:",
          stockControl
        );
      }
      return;
    }
    if (position.selectedItem?.item_id_company) {
      position.quantity = await showToastAndReturnStockCount(await position, toast, docDaten);
    } else {
      if (store.debug) {
        console.log(
          "🚀 ~ file: OffersEditComponent.vue:1345 ~ position.selectedItem:",
          position.selectedItem
        );
        console.log(
          "🚀 ~ file: OffersEditComponent.vue:1345 ~ position:",
          position
        );
      }
      return;
    }
  };
  

/*   const checkStockItemField = (stockItem, positionQuantity) => {
    const stockControl = companySettings.value.stockControl;
    if (!stockControl) return positionQuantity;
    const isItemInStock = stockItem.stock >= positionQuantity;
    if (!isItemInStock) {
      const msg = `Artikel ${stockItem.itemName} ist nicht ausreichend auf Lager!`;
      const msg2 = `Artikel-Menge von ${stockItem.itemName} wurde auf ${stockItem.stock < 0 ? 0 : stockItem.stock
        } reduziert!`;
      toast.add({ severity: "error", summary: msg, life: 3000 });
      toast.add({ severity: "info", summary: msg2, life: 3000 });
      if (stockItem.stock < 0) {
        return 0;
      }
      return stockItem.stock;
    } else return positionQuantity;
  }; */



  //TODO:  customer?.creditLimit ? activateCreditLimit == true  und Betrag unter creditLimit, sonst Fehlerwarnung anzeigen, das es das Kreditlimit übersteigt
  //TODO: dazu vorher von CustomerInputSeach creditLimit bei selectedCustomer übergeben.

  const addPosition = (itemList) => {
    const item = new Item();
    item.posNr = itemList.length + 1;
    itemList.push({ ...item });

    setTimeout(() => {
      markiereItemNameFeld(itemList.length);
    }, 10);
  };

  const markiereItemNameFeld = async (posNr) => {
    await nextTick();
    // Fügen  kurze Verzögerung hinzu, bevor Fokus setzen
    setTimeout(() => {
      // Finden des Element und setzen Sie den Fokus darauf
      const inputElement = document.getElementById("pos-itemBezeichnung-" + posNr);
      inputElement.focus();
      inputElement.select();
    }, 10);
  };

  const calculateGesamtNetto = (docDaten) => {
    let gesamtNetto = 0;
    let gesamtAbzug = 0;

    let nettoSteuerGruppe = {};
    docDaten.itemList.forEach((position) => {
      let netto = position.itemOutPrice * position.quantity;
      let abzug = position.rabattAbzug * position.quantity;
      if (position.VPE_QTY) {
        netto = netto * position.VPE_QTY;
        abzug = abzug * position.VPE_QTY;
      }

      if (!nettoSteuerGruppe[position.itemTaxValue]) {
        nettoSteuerGruppe[position.itemTaxValue] = 0;
      }
      nettoSteuerGruppe[position.itemTaxValue] += netto;

      if (!isNaN(netto)) {
        gesamtNetto += netto;
        gesamtAbzug += abzug;
      }
    });
    docDaten.docContent.itemTotalNetto = gesamtNetto;
    docDaten.docContent.itemTotalAbzug = gesamtAbzug;
    docDaten.docContent.documentValueNetto =
      gesamtNetto + docDaten.docContent.shipping.shippingPrice;
    return nettoSteuerGruppe;
  }

  function calculateGesamtUst(mwstSatz, docDaten) {
    let gesamtUst = 0;
    const positionenMitMwst = docDaten.itemList.filter((position) => {
      return position.itemTaxValue === mwstSatz;
    });
    positionenMitMwst.forEach((position) => {
      let ust =
        position.itemTaxValueFloat * position.itemOutPrice * position.quantity;
      if (position.VPE_QTY) {
        ust = ust * position.VPE_QTY;
      }
      if (!isNaN(ust)) {
        gesamtUst += ust;
      }
    });
    return gesamtUst;
  }

  const calculateTaxValue = (docDaten) => {
    const taxValue = { taxSum: 0 };
    const taxAnteil = {};
    if (docDaten?.itemList) {
      docDaten.itemList.forEach((position) => {
        if (position.itemTaxValue !== 0) {
          if (taxValue[position.itemTaxValue]) {
            return;
          }
          position.itemTaxValueFloat = position.itemTaxValue / 100;
          const ust = calculateGesamtUst(position.itemTaxValue, docDaten);

          taxAnteil[position.itemTaxValue] = calculateGesamtNettoProSteuersatz(
            position.itemTaxValue, docDaten
          );

          if (!taxValue.taxSum) taxValue.taxSum = ust;
          else taxValue.taxSum += ust;

          if (!taxValue[position.itemTaxValue]) {
            taxValue[position.itemTaxValue] = ust;
          } else {
            taxValue[position.itemTaxValue] += ust;
          }
        } else if (position.itemTaxValue == 0) {
          position.itemTaxValueFloat = 0.0;
        }
      });
    }

    if (docDaten.docContent.shipping.shippingPrice) {
      let gesamtNetto = 0;
      for (const [key, value] of Object.entries(taxAnteil)) {
        gesamtNetto += value;
      }

      for (const [key, value] of Object.entries(taxAnteil)) {
        let anteil = value / gesamtNetto;

        taxValue[key] +=
          docDaten.docContent.shipping.shippingPrice * anteil * (key / 100);
        taxValue.taxSum +=
          docDaten.docContent.shipping.shippingPrice * anteil * (key / 100);
      }
      docDaten.docContent.taxAnteil = taxAnteil;
    }
    docDaten.docContent.taxValue = taxValue;
    return taxValue;
  }

  function calculateGesamtNettoProSteuersatz(mwstSatz, docDaten) {
    let gesamtUst = 0;
    const positionenMitMwst = docDaten.itemList.filter((position) => {
      return position.itemTaxValue === mwstSatz;
    });
    positionenMitMwst.forEach((position) => {
      const gesamtNetto = position.itemOutPrice * position.quantity;
      if (!isNaN(gesamtNetto)) {
        gesamtUst += gesamtNetto;
      }
    });
    return gesamtUst;
  }

  const berechneFaelligkeitsDatum = (docDaten, docDatum) => {
    const zahlungsZiel = docDaten.docContent.zahlungsZiel;
    if (zahlungsZiel === "0") {
      docDaten.docContent.faelligkeitsDatum = docDatum;
    } else if (zahlungsZiel === "-1") {
      docDaten.docContent.faelligkeitsDatum = null;
    } else if (docDatum && zahlungsZiel) {
      const faelligkeitsDatum = new Date(docDatum);
      faelligkeitsDatum.setDate(
        faelligkeitsDatum.getDate() + parseInt(zahlungsZiel)
      );
      docDaten.docContent.faelligkeitsDatum = faelligkeitsDatum;
    }
  };
  const berechneSkontoBisDatum = (docDaten, docDatum) => {
    const skontoZiel = docDaten.docContent.skontoZiel;
    if (skontoZiel === "0") {
      docDaten.docContent.skontoBisDatum = null;
    } else if (docDatum && skontoZiel) {
      const skontoBisDatum = new Date(docDatum);
      skontoBisDatum.setDate(skontoBisDatum.getDate() + parseInt(skontoZiel));
      docDaten.docContent.skontoBisDatum = skontoBisDatum;
    }
    return docDaten.docContent.skontoBisDatum;
  };

  const calculatedSkonto = (docDaten) => {
    if (docDaten.docContent.skontoZiel === 0) {
      return docDaten.docContent.documentValueBrutto;
    }
    let skontoValue =
      docDaten.docContent.documentValueBrutto -
      (docDaten.docContent.documentValueBrutto *
        docDaten.docContent.skonto) /
      100;
    docDaten.docContent.skontoValue = skontoValue;
    return skontoValue;
  };

  const calculateSummenRabatt = (docDaten) => {
    // der Betrag entspricht das prozentuale als Betrag ausgenommen sind Positionen mit isDeposit = true und Versandkosten, der berechnete Wert wird in rabatt gespeichert
    let summenRabatt = 0.0;
    docDaten.itemList.forEach((position) => {
      if (position.isDeposit) {
        return;
      }
      if (position.itemOutPrice < 0.01) {
        return;
      }
      summenRabatt +=
        (position.itemOutPrice * docDaten.docContent.rabattProzentual) / 100;

      if (position.VPE_QTY) {
        summenRabatt = summenRabatt * position.VPE_QTY;
      }

      docDaten.docContent.rabatt = summenRabatt;
    });
  };

  const calculateProzentRabatt = (docDaten) => {
    let totalPrice = 0.0;
    let totalDiscount = docDaten.docContent.rabatt; // Der eingegebene Rabattbetrag

    docDaten.itemList.forEach((position) => {
      if (position.isDeposit) {
        return;
      }
      if (position.itemOutPrice < 0.01) {
        return;
      }
      let itemPrice = position.itemOutPrice;
      if (position.VPE_QTY) {
        itemPrice = itemPrice * position.VPE_QTY;
      }
      totalPrice += itemPrice;
    });

    if (totalPrice > 0) {
      docDaten.docContent.rabattProzentual =
        (totalDiscount / totalPrice) * 100;
    } else {
      docDaten.docContent.rabattProzentual = 0;
    }
  };

  const deletePos = (docDaten, position) => {

  console.log("🚀 ~ file: documentUtils.js:275 ~ position:", position)


    console.log("🚀 ~ file: documentUtils.js:275 ~ docDaten:", docDaten)

    let index = docDaten.itemList.indexOf(position);
    if (index !== -1) {
      deleteLinkedItems(docDaten, position);
      let deleted = docDaten.itemList.splice(index, 1);
    } else {
      console.log("Position nicht gefunden");
    }
    //removePosValidation(index, docDaten);

    if (docDaten.itemList.length == 0) {
      addPosition(docDaten.itemList);
    }

    //es wird durch die itemliste gegangen und die posNr der jeweiligen Positionen position.posNr, je nach index mit index+1 neu gesetzt
    docDaten.itemList.forEach((position, index) => {
      position.posNr = index + 1;
    });
  };


  const selectionDone = async (event, position,docDaten) => {
    fillPosition(event, position,docDaten);
    syncLinkedItems(docDaten, position, fillPosition);
  };

  let VPEPOSNR = null;
const selectionVPEDone = (event, position,docDaten) => {
  VPEPOSNR = position.posNr;
  syncLinkedItems(docDaten, position, fillPosition);
};

  const fillPosition = async (event, position,docDaten) => {
    if (
      position?.selectedItems?.value &&
      typeof position?.selectedItems?.value !== "object"
    ) {
      console.log("Kein Artikel ausgewählt");
      return;
    }
  
    if (position.selectedItem?.item_id_company) {
      position.selectedItem.VPE_QTY = position.selectedItem.itemParameters
        ?.forbidSingleSale
        ? position.selectedItem.VPE.list[0].VPE_QTY
        : null;
      position.selectedItem.isVPE =
        position.selectedItem?.itemParameters?.forbidSingleSale;
  
      if (position?.selectedItem?.VPE?.list[0]?.VPE_QTY) {
        position.selectedItem.VPE_QTY = position.selectedItem.VPE.list[0].VPE_QTY;
        position.selectedItem.isVPE = true;
      }
  
      Object.assign(position, position.selectedItem);
/*       position.quantity = showToastAndReturnStockCount(
        position,
        toast,
        docDaten
      ); */
      console.log("🚀 ~ file: documentUtils.js:350 ~ docDaten:", docDaten)

      if (position.selectedItem.manuelPriceType) {
        position.itemOutPrice = position.selectedItem.manuelPrice;
        position.vkStufen = position.selectedItem.manuelPriceType;
      } else {
        const vkStufe = docDaten.docContent.vkStufen;


        setPriceAndDiscount(
          position,
          vkStufe,
          position.selectedItem,
          position.itemParameters
        );
      }
    } else {
      const newItem = new Item();
  
      console.log("🚀 ~ file: OrdersEditComponent.vue:1621 ~ newItem:", newItem);
  
      newItem.posNr = position.posNr;
      newItem.quantity = position.quantity;
      //prüfen ob selectedItem ein object ist
      if (position.selectedItem && typeof position.selectedItem === "object") {
        newItem.itemName = position.selectedItem.itemName;
      } else {
        newItem.itemName = position.selectedItem;
      }
      newItem.selectedItem = position.selectedItem;
      Object.assign(position, newItem);
    }
    if (VPEPOSNR) markiereQuantityFeld(VPEPOSNR);
    //else if (sideDetailsShow.value == false && event)
    else if ( event)
      markiereQuantityFeld(position.posNr);
  };

  const markiereQuantityFeld = async (posNr, isLinkedItem) => {
    await nextTick();
  
    // Fügen  kurze Verzögerung hinzu, bevor Fokus setzen
    setTimeout(() => {
      // Finden des Element und setzen Sie den Fokus darauf
  
      if(store.debug){
      console.log(
        "🚀 ~ file: OrdersEditComponent.vue:1876 ~ 'pos-quantity-'+posNr):",
        "pos-quantity-" + posNr
      );
      console.log(
        "🚀 ~ file: OrdersEditComponent.vue:1896 ~ pos-quantity-L-'+posNr:",
        "pos-quantity-L-" + posNr
      );
    }
      //Erst prüfen ob das Mengenelement vorhanden ist, wegen mobile ansicht
      const inputElementMobile = document.getElementById("pos-quantity-" + posNr);
      const inputElementLargerDisplays = document.getElementById(
        "pos-quantity-L-" + posNr
      );
      if (!inputElementMobile || !inputElementLargerDisplays) return;
  
      if (
        inputElementMobile &&
        inputElementMobile.offsetWidth === 0 &&
        inputElementMobile.offsetHeight === 0
      ) {
        if (inputElementLargerDisplays) {
          inputElementLargerDisplays.focus();
          inputElementLargerDisplays.select();
        }
      } else {
        inputElementMobile.focus();
        inputElementMobile.select();
      }
      VPEPOSNR = null;
    }, 10);
  };

  function setPriceAndDiscount(position, vkStufe, selectedItem, itemParameters) {
    const { key, discountIndex } = vkStufen[vkStufe || 'VK-1'];
    if (selectedItem[key]) {
      position.itemOutPrice = selectedItem[key];
      position.vkStufen = vkStufe;
      const discount = itemParameters?.discount?.[discountIndex] || 0;
      if (discount > 0) {
        position.rabatt = discount;
        let itemOutPriceOhneAbzug = position.itemOutPrice;
        position.itemOutPrice -= (position.itemOutPrice * discount) / 100;
        position.rabattAbzug = itemOutPriceOhneAbzug - position.itemOutPrice;
      }
    } else {
      position.itemOutPrice = selectedItem.itemOutPrice;
      position.vkStufen = "VK-1";
      position.rabattAbzug = 0;
    }
  };

  const checkNaNCatalogItem = (entryItem, position,docDaten) => {
    if (entryItem && typeof entryItem === "object") return;
    if (entryItem.length > 0) {
      position.itemName = entryItem;
      if (position?.selectedItem?.itemName !== entryItem) {
        position.item_id_company = "";
      }
      syncLinkedItems(docDaten, position, fillPosition);
    }
  };

//überprüft ob auftragsDaten.docContent.otherLegalNotices bereits einen gefunden Wert hinzugefügt hat,
// und wenn nicht wird der Wert hinzugefügt. Es prüft ob die taxId eine LegalNotice hat und
// ob showInPrints true ist. Wenn ja wird der Wert hinzugefügt, wenn er nicht bereits vorhanden ist.
// Der Funktion wird die Position übergeben, anhand dieser wird mit der Funktion getTaxOptionById(id).legalNotice und getTaxOptionById(id).showInPrints geprüft.
// Wenn die Bedingungen erfüllt sind wird legalNotice hinzugefügt.

const putTaxValueLegals = (position) => {
  const taxId = position.itemTaxId;
  const taxOption = calcUtils.getTaxOptionById(taxId);
  if (taxOption?.legalNotice && taxOption?.showInPrints) {
    if (
      !auftragsDaten.docContent.otherLegalNotices.includes(
        taxOption.legalNotice
      )
    ) {
      auftragsDaten.docContent.otherLegalNotices.push(taxOption.legalNotice);
    }
  }
};

  return {
    checkStockQuantityField,
    addPosition,
    calculateTaxValue,
    calculateGesamtNetto,
    calculateGesamtUst,
    berechneFaelligkeitsDatum,
    berechneSkontoBisDatum,
    calculatedSkonto,
    calculateSummenRabatt,
    calculateProzentRabatt,
    deletePos,
    selectionDone,
    selectionVPEDone,
    markiereQuantityFeld,
    fillPosition,
    markiereItemNameFeld,
    setPriceAndDiscount,
    checkNaNCatalogItem,

  };
}
