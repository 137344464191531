import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const setDocumentStatus = async (offerId, status) => {
  let url = process.env.VUE_APP_BASE_API_URL + "/company/offer/" + offerId;
  let response = await iAxios.post(url, { documentStatus: status });
  return response;
}

export const OffersService = {
  async getData(timeStamp) {
    try {
      const store = userStore();
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/offer-list");
      const offers = await response.data;
      store.dataStates.offers = timeStamp;
      store.setDataToStorage("offers", offers);
      return Promise.resolve(offers);
    } catch (e) {
      console.log(e);
    }
  },

  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL +"/company/change-stats ");
    const data = await response.data;

    console.log("🚀 ~ file: OffersService.js:56 ~ data:", data)

    return Promise.resolve(data);
  },

  async getOffers() {
    const key = "offers";
    const store = userStore();
    const states = await this.checkForChanges();
    const timestampBE = states?.offers;
    const timestampFE = store?.dataStates?.offers;

    if (states.cid != store.dataStates.cid) {
      store.dataStates.cid = states.cid;
      return Promise.resolve(this.getData(timestampBE));
    }

    const foragePassed = await store.forageCheckForKey(key);
    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
      return Promise.resolve(this.getData(timestampBE));
    } else {
      return store.getDataFromStorage(key);
    }
  },

};
