import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';



//formatiert das Datum nach dem Schema: dd.mm.jjjj
const formatDatum = (datum) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(datum);
    return date.toLocaleDateString(undefined, options);
};

function createAddressString(deliverTo) {
    let addressParts = [
        deliverTo.name1,
        deliverTo.name2,
        deliverTo.address1,
        deliverTo.address2,
        deliverTo.postCode,
        deliverTo.city
    ];
    addressParts = addressParts.filter(part => part != null);
    return addressParts.join(" ");
}

/*
Neue Rechnung/Entwurf Erstellung:
//FE-Api
Kein Entwurf vorhanden: -> keine invoiceDocumentId vorhanden
Erstellen mit Vorschau: (UI: nach Validierung) Es wird ein neue Rechnung erstellt und die Vorschau angezeigt. // post //create-invoice
Erstellen mit Download: (UI: nach Validierung) Es wird ein neue Rechnung erstellt und direkt zum Download übergeben. // post //create-invoice
Entwurf vorhanden:
Erstellen mit Vorschau: Entwurf wird überschrieben und danach wird mit den Daten der Entwurf in eine Rechnung umgewandelt mit der Entwurf-ID und die Vorschau angezeigt. //Schritt1. patch //create-invoice-draft, Schritt 2. get //draft-to-invoice
Erstellen mit Download: Entwurf wird überschrieben und danach wird mit den Daten der Entwurf in eine Rechnung umgewandelt mit der Entwurf-ID und direkt zum Download übergeben. //Schritt1. patch //create-invoice-draft, Schritt 2. get //draft-to-invoice
Wenn nur Speichern: Es wird ein neuer Entwurf erstellt ohne Vorschau. // post //create-invoice-draft
Wenn Speichern mit Vorschau: Es wird ein neuer Entwurf erstellt und die Vorschau angezeigt. //post //create-invoice-draft
Löschen: (UI: Nach Bestätigung des Löschvorgangs) wird der vorhandene Entwurf gelöscht. //delete //delete-draft
Wenn Drucken: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt zum Ausdrucken übergeben. //post oder patch //create-invoice-draft
Wenn Download: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt zum Download übergeben. //post oder patch //create-invoice-draft
HORNY: Wenn per Email senden: Falls ein Entwurf vorhanden ist, wird dieser überschrieben ansonsten wird ein neuer Entwurf erstellt und direkt per Email (invoiceDocumentId) versendet. Schritt1. //post oder patch //create-invoice-draft Schrit2. //post //send-email

//FE-Api
Bereits erstellte Rechnung: ->isInvoice (invoiceDocumentId vorhanden & isDraft=false || readOnly=true)
Wenn Vorschau: Rechnungs-PDF wird mit get und invoiceDocumentId geholt und die Vorschau angezeigt. //get //create-invoice
Wenn Drucken: Rechnung-PDF wird mit get und invoiceDocumentId geholt und direkt zum Ausdrucken übergeben. //get //create-invoice
Wenn Herunterladen: Rechnungs-PDF wird mit get und invoiceDocumentId geholt und direkt zum Download übergeben. //get //create-invoice
Rechnung Klonen: Es wird ein Dialog gestartet, mit den Werten der Rechnung, die geklont werden soll.
HORNY: Wenn per Email senden: Rechnung wird invoiceDocumentId dem EP zum Versand per Email gesendet.

//FE-UI
Wenn Stornieren: Wird ein Dialog gestartet, mit folgenden Werten:
-rechnungsDaten.docContent.invoiceTitel="Rechnungskorrektur zu Rechnung: "+rechnungsDaten.docContent.invoiceTitel
-rechnungsDaten.relationFrom={"canceled-invoice":id der zu stornieren Rechnung} 
-alle items von itemsList werden mit negativen Werten in die itemsList von rechnungsDaten übernommen.

//FE-Api
Rechnungskorrektur:
-Gleicher Prozess wie Rechnung erstellen, nur mit den Werten von der zu stornierenden Rechnung.

*/


/**
 * Speichert die Rechnungsdaten und erhält die PDF-Datei.
 * @param {Object} injectedValue - Die injizierten Werte, die die Rechnungsdaten enthalten.
 * @param {string} proceedType - Der Vorgangstyp, der angibt, wie die Funktion ausgeführt werden soll.
 *                   Falls Entwurf vorhanden, wird der Entwurf überschrieben, falls nicht wird ein neuer Entwurf erstellt.
 * Mögliche Werte:  "save" => default bei null speichert und lädt direkt runter. Erstellt eine Rechnung.
 *                  "print"=> direkt Ausdruck, ohne speichern,
 *                  "saveDraftAndPreview"=>Vorschau mit speichern,  
 *                  "previewOnly"=>Vorschau ohne speichern als Entwurf, 
 *                  "download=>nur download ohne speichern".
 * @param {boolean} preview - Gibt an, ob eine Vorschau angezeigt werden soll.
 * @returns {Promise} - Ein Promise-Objekt, das die Antwort der Serveranfrage enthält.
 */
export const saveAndGetPDF = async (injectedValue, proceedType, preview, setDownload) => {
    const store = userStore();
    injectedValue.data.loading = true;
    console.log("🚀 ~ file: invoiceUtils.js:69 ~ saveAndGetPDF ~ setDownload:", setDownload)


    console.log("🚀 ~ file: invoiceUtils.js:69 ~ saveAndGetPDF ~ preview:", preview)


    console.log("🚀 ~ file: invoiceUtils.js:69 ~ saveAndGetPDF ~ proceedType:", proceedType)


    console.log("🚀 ~ file: invoiceUtils.js:69 ~ saveAndGetPDF ~ injectedValue:", injectedValue)

    //console.clear();
    const isDraftToInvoice = (proceedType === "draftToInvoice");
    const isInvoice = (proceedType === "saveInvoice");
    const saveInvoice = isInvoice || isDraftToInvoice;
    const saveDraft = (proceedType === "saveDraft");
    const readOnly = injectedValue.data.invoice.readOnly;
    const print = (proceedType === "print");
    const printInvoice = (print && readOnly);
    const previewOnly = (proceedType === "previewOnly");
    const downloadOnly = (proceedType === "download");
    const downloadDraft = (proceedType === "downloadDraft");
    const isDownload = (proceedType === "download") || setDownload;
    const deleteDraft = (proceedType === "deleteDraft");
    const rechnungsDaten = injectedValue.data.rechnungsDaten;
    const invoiceDocumentId = injectedValue.data.invoice.invoiceDocumentId;
    const isinvoiceDocumentId = invoiceDocumentId != null;
    const overwriteDraft = isinvoiceDocumentId && saveDraft;
    const documentDownlaod = invoiceDocumentId ? invoiceDocumentId + "/download" : "";
    const isCancelled = injectedValue.data.invoice.isCancelled;
    const isDraft = injectedValue.data.invoice.isDraft || injectedValue.data.rechnungsDaten.eventAction === "INVOICE_DRAFT";
    const isCloned = injectedValue.data.invoice.isCloned;
    const isFromOrder = injectedValue.data.rechnungsDaten?.isFromOrder;

    console.log("🚀 ~ file: invoiceUtils.js:104 ~ njectedValue.data.invoice?:", injectedValue.data)
    const deliverToAddresse = ((rechnungsDaten?.deliverTo?.name1)
        ? createAddressString(rechnungsDaten.deliverTo)
        : '=Rechnungsanschrift');
/*     if (true) {
        rechnungsDaten.docContent.invoiceFooterNotes = "Lieferanschrift: " + deliverToAddresse;
    }; */
    rechnungsDaten.docContent.invoiceFooterNotes = "";


    //let flatRechnung = rechnungsDaten;
    //flatRechnung.storno = injectedValue.data.invoice.storno;
    //const docId = (preview ||saveInvoice || isDraftToInvoice || downloadDraft) && invoiceDocumentId ? "/" + invoiceDocumentId : "";
    //wenn store.companySettings.showEanInDocuments true ist, dann wird der itemDescription inder itemList die Ean mit EAN:+ EAN vorangesetzt,
    // bei sämtlichen artikel in der itemList in rechnungsDaten




    if (store.companySettings.showEanInDocuments) {
        rechnungsDaten.itemList?.forEach((item) => {
            if (item.EAN) {
                item.itemDescription ??= "";
                item.itemDescription = item?.itemDescription?.replaceAll("EAN: " + item.EAN + " / ", "");
                item.itemDescription = "EAN: " + item.EAN + " / " + item.itemDescription;
            }
        });
    }
    
    const docId = invoiceDocumentId != null ? "/" + invoiceDocumentId : '';

    console.log("🚀 ~ file: invoiceUtils.js:102 ~ saveAndGetPDF ~ invoiceDocumentId:", invoiceDocumentId)


    console.log("🚀 ~ file: invoiceUtils.js:102 ~ saveAndGetPDF ~ isDraftToInvoice:", isDraftToInvoice)


    console.log("🚀 ~ file: invoiceUtils.js:102 ~ saveAndGetPDF ~ saveInvoice:", saveInvoice)

    rechnungsDaten.docContent.documentTitel = rechnungsDaten.docContent.invoiceTitel;
    rechnungsDaten.docContent.documentIntroduction = rechnungsDaten.docContent.invoiceIntroduction;
    rechnungsDaten.docContent.documentFinalText = rechnungsDaten.docContent.invoiceFinalText;
    rechnungsDaten.as_attachment = isDownload;
    //delete flatRechnung.productItemList;
    let url = "/company";
    switch (proceedType) {
        case "previewOnly": url += isDraft?"/download-invoice-draft" + docId:"/download-invoice" + docId; break;
        case "saveInvoice": url += "/create-invoice"; break;
        case "draftToInvoice": url += "/draft-to-invoice" + docId; break;
        case "saveDraft": url += "/create-invoice-draft" + docId; break;
        case "downloadDraft": url += "/create-invoice-draft" + docId; break;
        case "saveDraftAndPreview": url += "/create-invoice-draft"; break;
        case "print": (readOnly ? url += "/download-invoice" + docId : url += "/create-invoice-draft" + docId); break;
        case "download": (readOnly ? url += "/download-invoice/" + documentDownlaod : url += "/create-invoice-draft/") + documentDownlaod; break;
        case "deleteDraft": break;
        default: console.log("Unbehandelter Proceed Type!"); break;
    }
    console.log("🚀 ~ file: invoiceUtils.js:132 ~ saveAndGetPDF ~ url:", url)
    console.log("🚀 ~ file: invoiceUtils.js:133 ~ saveAndGetPDF ~ docId:", docId)

    //patch
    if ((docId && !isInvoice && !isDraftToInvoice && !printInvoice && !previewOnly && !downloadOnly) || downloadDraft || overwriteDraft) {
        await iAxios.patch(url, rechnungsDaten, {
            responseType: 'blob',
        })
            .then((response) => {

                console.log("🚀 ~ file: invoiceUtils.js:142 ~ .then ~ response:", response)

                setDocNameFromHeader(response, injectedValue);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                    case "download": download(response.data, "Rechnung_" + rechnungsDaten.receiver.name1 + "_" + formatDatum(rechnungsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                    case "print": { printPDF(fileURL); break; }

                    default:
                        {
                            downloadPdf(blob, rechnungsDaten, injectedValue); break;
                        }
                }
            })

    }
    //get
    else if (isDraftToInvoice || printInvoice || previewOnly || downloadOnly) {
        await iAxios.get(url, {


            responseType: 'blob',
        })
            .then((response) => {
                console.log("🚀 ~ file: invoiceUtils.js:153 ~ saveAndGetPDF ~ url:", url)

                console.log("🚀 ~ file: invoiceUtils.js:156 ~ .then ~ response:", response)

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "draftToInvoice":
                       { 
                        setDocNameFromHeader(response, injectedValue);
//                        injectedValue.data.invoice.isDraft = false; //hinzugefügt am 16.11.2024 17:44
                       if (isFromOrder) setInvoiceRelationsInOrder(injectedValue.data.rechnungsDaten);
                       }
                        if (isDownload) {
                            download(response.data, "Rechnung_" + rechnungsDaten.receiver.name1 + "_" + formatDatum(rechnungsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                        } else if (preview) {
                            injectedValue.data.pdfFile = fileURL; break;
                        } else {
                            break;
                        }
                    case "print": { console.log("print"); printPDF(fileURL); break; }
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "downloadOnly": download(response.data, "Rechnung_" + rechnungsDaten.receiver.name1 + "_" + formatDatum(rechnungsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;

                    default:
                        {
                            downloadPdf(blob, rechnungsDaten, injectedValue); break;
                        }
                }
            })
    }
    else {
        //post
        console.log("rechnungsDaten", rechnungsDaten);
        await iAxios.post(url, rechnungsDaten, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then((response) => {
            console.log("🚀 ~ file: invoiceUtils.js:211 ~ rechnungsDaten:", rechnungsDaten);
            console.log("🚀 ~ file: invoiceUtils.js:212 ~ saveAndGetPDF ~ response:", response);

            setDocNameFromHeader(response, injectedValue);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            switch (proceedType) {
                case "print": printPDF(fileURL); break;
                case "saveInvoice":
                    if (rechnungsDaten.forShipping) {
                        sendToShippingList(injectedValue.data.rechnungsDaten);
                    }
                    if (preview) {
                        injectedValue.data.pdfFile = fileURL;
                        injectedValue.data.invoice.isDraft = false;
                        break;
                    } else {
                        injectedValue.data.invoice.isDraft = false;
                        break;
                    } case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                case "download": downloadPdf(blob, rechnungsDaten, injectedValue); break;
                case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                default:
                    {
                        downloadPdf(blob, rechnungsDaten, injectedValue); break;
                    }
            }
            if (isCancelled && isInvoice) {
                console.log("🚀 ~ file: invoiceUtils.js:210 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)

                setDocumentStatus(injectedValue.data.rechnungsDaten.relationFrom.invoiceCancelledFromId, "INVOICE_CANCELED");
                setDocumentStatus(injectedValue.data.rechnungsDaten.invoiceDocumentId, "INVOICE_CORRECTION");
                setRelationToInvoice(injectedValue.data.rechnungsDaten);
            }

            console.log("🚀 ~ file: invoiceUtils.js:267 ~ isFromOrder:", isFromOrder)

            if (isFromOrder) {
                setInvoiceRelationsInOrder(injectedValue.data.rechnungsDaten);
            }


        })
            .catch((error) => {
                console.error(error);
            });
    }
    injectedValue.data.loading = false;

    return injectedValue;
};


//setzt nachträglich toShipping und sendet die Rechnung an die Versandliste
export const setToShippingList = async (rechnungsDaten, value) => {
    const invoiceDocumentId = rechnungsDaten.invoiceDocumentId;

    sendToShippingList(rechnungsDaten, value);
    /*     try{
            const url="/company/create-invoice";
            const response = await iAxios.patch(url, {invoiceDocumentId:invoiceDocumentId,toShipping:value});
            console.log("🚀 ~ file: invoiceUtils.js:315 ~ response:", response)
        }
        catch(error){
            console.error(error);
        } */
};

const sendToShippingList = (rechnungsDaten, value) => {

    console.log("🚀 ~ file: invoiceUtils.js:266 ~ rechnungsDaten:", rechnungsDaten)

    try {
        const invoiceDocumentId = rechnungsDaten.invoiceDocumentId;

        console.log("🚀 ~ file: invoiceUtils.js:269 ~ invoiceDocumentId:", invoiceDocumentId)

        const url = process.env.VUE_APP_BASE_API_URL + "/company/shipping-list";
        const bodyData = { invoiceList: [invoiceDocumentId], removeFromShippingList: !value };
        iAxios.post(url, bodyData)
            .then((response) => {
                console.log("🚀 ~ file: invoiceUtils.js:273 ~ response:", response)
            })
            .catch((error) => {
                console.error(error);
            });
    }
    catch (error) {
        console.error(error);
    }
};

const setRelationToInvoice = async (rechnungsDaten) => {
    console.log("setRelationToInvoice", rechnungsDaten);

    const invoiceDocumentId = rechnungsDaten.relationFrom.invoiceCancelledFromId;
    const relationToId = rechnungsDaten.invoiceDocumentId;
    const relationToDocName = rechnungsDaten.relationFrom.docName;
    let url = process.env.VUE_APP_BASE_API_URL + "/company/order-edit";
    const bodyData =
    {
        "invoiceDocumentId": parseInt(invoiceDocumentId),
        "relationTo": { invoiceCorrectionId: relationToId, invoiceCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

/* const setRelationToInvoice = async (invoiceDocumentId, relationToId, relationToDocName) => {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/invoice-edit";
    const bodyData =
    {
        "invoiceDocumentId": parseInt(invoiceDocumentId),
        "relationTo": { invoiceCorrectionId: relationToId, invoiceCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
}; */

const setInvoiceRelationsInOrder = async (rechnungsDaten) => {
    const orderDocuments = rechnungsDaten.relationFrom.generatedFromOrder;
    const relationToId = rechnungsDaten.invoiceDocumentId;
    const relationToDocName = rechnungsDaten.docName;
    //die forschleife geht sämtliche einträge von orderDocuments durch und fügt die invoiceDocumentId und die docName in die jeweiligen order mit the-order/orderDocId ein
    for (let i = 0; i < orderDocuments.length; i++) {
        const orderDocumentId = orderDocuments[i].orderDocId;
        await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/the-order/" + orderDocumentId)
            .then((responseRelations) => {
                let relationTo = responseRelations.data.relationTo;
                let invoice = ({ invoiceDocumentId: relationToId, invoiceDocName: relationToDocName });
                relationTo = { invoice: invoice };
                let url = process.env.VUE_APP_BASE_API_URL + "/company/order-edit";
                const bodyData =
                {
                    "orderDocumentId": parseInt(orderDocumentId),
                    "relationTo": relationTo,
                    "createShipping": false,
                };
                let response = iAxios.patch(url, bodyData)
                    .then((response) => {
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    /*     let invoices = [];
        let relationTo = {};
        await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/the-order/" + orderDocumentId)
            .then((responseRelations) => {
                if (responseRelations.data?.relationTo) { relationTo = responseRelations.data.relationTo; };
                if (responseRelations.data.relationTo?.orders) { invoices = responseRelations.data.relationTo.invoices; };
                relationTo.invoices = invoices;
            })
            .catch((error) => {
                console.error(error);
            });
        invoices.push({ invoiceDocumentId: relationToId, invoiceDocName: relationToDocName });
    
        let url = process.env.VUE_APP_BASE_API_URL + "/company/order-edit";
        const bodyData =
        {
            "orderDocumentId": parseInt(orderDocumentId),
            "relationTo": relationTo,
            "createShipping": false,
        };
        console.log("🚀 ~ file: invoiceUtils.js:348 ~ bodyData:", bodyData)
    
        let response = await iAxios.patch(url, bodyData)
            .then((response) => {
    
            console.log("🚀 ~ file: invoiceUtils.js:355 ~ response:", response)
    
            })
            .catch((error) => {
                console.error(error);
            }); */
    return true;
};

const setDocNameFromHeader = (response, injectedValue) => {
    console.log('Alle Header:');
    Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    });

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {
        injectedValue.data.rechnungsDaten.invoiceDocumentId = response.headers.invoicedocumentid;
        injectedValue.data.invoice.invoiceDocumentId = response.headers.invoicedocumentid;
        injectedValue.data.rechnungsDaten.docName = response.headers.invoicedocname;
        injectedValue.data.rechnungsDaten.deliveryDocumentId = response.headers.deliverydocumentid;
        injectedValue.data.rechnungsDaten.deliveryDocName = response.headers.deliverydocname;

    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    }
}

const downloadPdf = (blob, rechnungsDaten, injectedValue) => {
    console.log("🚀 ~ file: invoiceUtils.js:285 ~ downloadPdf ~ injectedValue:", injectedValue)

    download(blob, "Rechnung_" + rechnungsDaten.receiver.name1 + "_" + formatDatum(rechnungsDaten.docContent.documentDatum) + "_" + rechnungsDaten.docName + ".pdf", "application/pdf");
};



/**Vorraussetzung eines iframe mit der id=pdfFrame setzen in der Komponente von der aus der Druck aufgerufen wird.
 * <iframe id="pdfFrame" style="display:none"></iframe>
 * @param {*} pdfFile -url der PDF-Datei.
 * @returns
 */
export const printPDF = async (pdfFile) => {
    const iframe = document.getElementById('pdfFrame');
    iframe.src = pdfFile;

    // Warten, bis die PDF-Datei geladen wurde
    await new Promise((resolve) => {
        iframe.onload = resolve;
    });
    iframe.contentWindow.print();
};

export const setDocumentStatus = async (invoiceDocumentId, status) => {
    console.log("🚀 ~ file: invoiceUtils.js:294 ~ setDocumentStatus ~ status:", status)
    console.log("🚀 ~ file: invoiceUtils.js:295 ~ setDocumentStatus ~ invoiceDocumentId:", invoiceDocumentId)
    if (isNaN(invoiceDocumentId)) {
        console.error("invoiceDocumentId ist keine Zahl!: " + invoiceDocumentId);
        return false;
    }
    let url = process.env.VUE_APP_BASE_API_URL + "/company/invoice-status";
    const formData = new FormData();
    formData.append("invoiceDocumentId", parseInt(invoiceDocumentId));
    formData.append("documentStatus", status);
    let response = await iAxios.post(url, formData)
        .then((response) => {
            //  console.log("🚀 ~ file: invoiceUtils.js:18 ~ .then ~ response:", response)
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

export const setInvoicePayStatus = (rechnungsDaten) => {
    const payProgress = rechnungsDaten?.payProgress;
    const payInput = payProgress?.paymentInput;
    let totalAmount = rechnungsDaten?.docContent?.documentValueBrutto;
    if (!payProgress || !payInput) return { totalPaid: 0, status: "OFFEN" };
    let totalPaid = 0.0;
    payInput.forEach((payment) => {
        if (payment.event != "PAYMENT_DELETED") {
            totalPaid += payment.paymentAmount;
            }
    });
    totalPaid = parseFloat(totalPaid.toFixed(2)); // ist nötig wegen Rundungsfehler in javascript
    totalAmount = parseFloat(totalAmount.toFixed(2));
    if (totalPaid >= totalAmount) {
        setDocumentStatus(rechnungsDaten.invoiceDocumentId, "INVOICE_PAID");
        return { totalPaid: totalPaid, status: "BEZAHLT" };
    } if (totalPaid < totalAmount && totalPaid > 0) {
        setDocumentStatus(rechnungsDaten.invoiceDocumentId, "INVOICE_PARTIAL");
        return { totalPaid: totalPaid, status: "TEILZAHLUNG" };
    }
    setDocumentStatus(rechnungsDaten.invoiceDocumentId, "INVOICE_OPEN");
    return { totalPaid: totalPaid, status: "OFFEN" };
};

export const getInvoiceDeliverySlip = async (injectedValue, preview) => {

    console.log("🚀 ~ file: invoiceUtils.js:346 ~ injectedValue:", injectedValue)

    const deliveryDocumentId = injectedValue.data.rechnungsDaten.deliveryDocumentId;
    const rechnungsDaten = injectedValue.data.rechnungsDaten;
    rechnungsDaten.createShipping = false;
    rechnungsDaten.docContent.documentTitel = rechnungsDaten.docContent.deliveryTitel;
    rechnungsDaten.docContent.documentIntroduction = rechnungsDaten.docContent?.deliveryIntroduction?.replaceAll("[DOCNR]", rechnungsDaten.invoiceDocName);
    rechnungsDaten.docContent.documentFinalText = rechnungsDaten.docContent.deliveryFinalText;

    console.log("🚀 ~ file: invoiceUtils.js:2352 ~ getInvoiceDeliverySlip ~ rechnungsDaten:", rechnungsDaten)

    if (isNaN(deliveryDocumentId)) {
        console.error("deliveryDocumentId ist keine Zahl!: ");
        return false;
    }
    const inline = preview ? "/inline" : "";
    const url = process.env.VUE_APP_BASE_API_URL + "/company/delivery-doc/" + deliveryDocumentId + inline;
    let response = await iAxios.put(url, rechnungsDaten, {
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        if (preview) {
            injectedValue.data.pdfFile = fileURL;
        } else {
            download(blob, "Lieferschein_" + deliveryDocumentId + ".pdf", "application/pdf");
        }
    }).catch((error) => {
        console.error(error);
    });
    return true;
}

///TODO: invoice: Säubern des Codes und optimieren sowie entfernen der Consolenausgaben
///TODO: invoice: FACK: Teilzahlung

///TODO: DokumentenSettings: design anpassen
///TODO: Order/Auftragsverwaltung: erstellen von Sammelrechnungen
///TODO: Order/Auftragsverwaltung: umwandeln in Angebote
///TODO: Order/Auftragsverwaltung: erstellen von Rechnungen
///TODO: Authentifikation: Login Prozess korrieren
///TODO: Benutzer: Mitarbeiter anlegen, bearbeiten, löschen, Username
///TODO: Offer: Angebot erstellen, bearbeiten, löschen, versenden, drucken, downloaden, in Auftrag verwandeln, in Rechnung umwandeln
///TODO: Backend: Lieferschein QRCODE Navigation starten