import iAxios from "@/store/axiosInterface";
import download from 'downloadjs'
import { markRaw } from "vue";
import { formatDate } from "./calculationUtils";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';



//formatiert das Datum nach dem Schema: dd.mm.jjjj
const formatDatum = (datum) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const date = new Date(datum);
    return date.toLocaleDateString(undefined, options);
};

function createAddressString(deliverTo) {
    let addressParts = [
        deliverTo.name1,
        deliverTo.name2,
        deliverTo.address1,
        deliverTo.address2,
        deliverTo.postCode,
        deliverTo.city
    ];
    addressParts = addressParts.filter(part => part != null);
    return addressParts.join(" ");
}



/**
 * Speichert die Angebotsdaten und erhält die PDF-Datei.
 * @param {Object} injectedValue - Die injizierten Werte, die die Angebotsdaten enthalten.
 * @param {string} proceedType - Der Vorgangstyp, der angibt, wie die Funktion ausgeführt werden soll.
 *                   Falls Entwurf vorhanden, wird der Entwurf überschrieben, falls nicht wird ein neuer Entwurf erstellt.
 * Mögliche Werte:  "save" => default bei null speichert und lädt direkt runter. Erstellt eine Angebot.
 *                  "print"=> direkt Ausdruck, ohne speichern,
 *                  "saveDraftAndPreview"=>Vorschau mit speichern,  
 *                  "previewOnly"=>Vorschau ohne speichern als Entwurf, 
 *                  "download=>nur download ohne speichern".
 * @param {boolean} preview - Gibt an, ob eine Vorschau angezeigt werden soll.
 * @returns {Promise} - Ein Promise-Objekt, das die Antwort der Serveranfrage enthält.
 */
export const saveAndGetPDF = async (injectedValue, proceedType, preview, setDownload) => {
    const store = userStore();
injectedValue.data.loading=true;
console.log("🚀 ~ file: offerUtils.js:69.4 ~ saveAndGetPDF ~ setDownload:", setDownload)


console.log("🚀 ~ file: offerUtils.js:69.3 ~ saveAndGetPDF ~ preview:", preview)


console.log("🚀 ~ file: offerUtils.js:69.2~ saveAndGetPDF ~ proceedType:", proceedType)


    console.log("🚀 ~ file: offerUtils.js:69.1 ~ saveAndGetPDF ~ injectedValue:", injectedValue)

    //console.clear();
    const isDraftToOffer = (proceedType === "draftToOffer");
    const saveOffer = (proceedType === "saveOffer");
    const createOffer = (proceedType === "createOffer");
    const saveDraft = (proceedType === "saveDraft");
    const readOnly = injectedValue.data.offer.readOnly;
    const print = (proceedType === "print");
    const printOffer = (print && readOnly);
    const previewOnly = (proceedType === "previewOnly");
    const downloadOnly = (proceedType === "download") || (proceedType === "downloadDraft") || setDownload;
    const downloadDraft = (proceedType === "downloadDraft");
    const isDownload = (proceedType === "download") || setDownload;
    const deleteDraft = (proceedType === "deleteDraft");
    const angebotsDaten = injectedValue.data.angebotsDaten;
    const offerDocumentId = injectedValue.data.offer.offerDocumentId;
    const isofferDocumentId= offerDocumentId!=null;
    const overwriteDraft = isofferDocumentId && saveDraft;
    const documentDownlaod = offerDocumentId ? offerDocumentId + "/download" : "";
    const isCancelled = injectedValue.data.offer.isCancelled;
    const isDraft = injectedValue.data.offer.isDraft;
    const isCloned = injectedValue.data.offer.isCloned;
    //let flatAngebot = angebotsDaten;
    //flatAngebot.storno = injectedValue.data.offer.storno;
    //const docId = (preview ||createOffer || isDraftToOffer || downloadDraft) && offerDocumentId ? "/" + offerDocumentId : "";
    //wenn store.companySettings.showEanInDocuments true ist, dann wird der itemDescription inder itemList die Ean mit EAN:+ EAN vorangesetzt,
// bei sämtlichen artikel in der itemList in Auftragsdaten

/* if (store.companySettings.showEanInDocuments) {
    angebotsDaten.productItemList?.forEach((item) => {
        if (item.ean) {
            item.itemDescription = "EAN: " + item.EAN + " " + item.itemDescription;
        }
    });
} */

if (store.companySettings.showEanInDocuments) {
    angebotsDaten.itemList?.forEach((item) => {
        if (item.EAN) {
            item.itemDescription = item?.itemDescription?.replaceAll("EAN: " + item.EAN + " / ", "");
            item.itemDescription = "EAN: " + item?.EAN + " / " + item?.itemDescription;
        }
    });
}

const deliverToAddresse = ((angebotsDaten?.deliverTo?.name1)
? createAddressString(angebotsDaten.deliverTo)
: '=Rechnungsanschrift');
/* if (true) {
    angebotsDaten.docContent.invoiceFooterNotes = "Lieferanschrift: " + deliverToAddresse;
}; */
    const docId = offerDocumentId!=null?"/" + offerDocumentId:'';

    console.log("🚀 ~ file: offerUtils.js:102 ~ saveAndGetPDF ~ offerDocumentId:", offerDocumentId)


    console.log("🚀 ~ file: offerUtils.js:102 ~ saveAndGetPDF ~ isDraftToOffer:", isDraftToOffer)


    console.log("🚀 ~ file: offerUtils.js:102 ~ saveAndGetPDF ~ createOffer:", createOffer)

    angebotsDaten.docContent.documentTitel=angebotsDaten.docContent.offerTitel;
    angebotsDaten.docContent.documentIntroduction=angebotsDaten.docContent.offerIntroduction;
    angebotsDaten.docContent.documentFinalText=angebotsDaten.docContent.offerFinalText;
    angebotsDaten.as_attachment = isDownload;
    //delete flatAngebot.productItemList;
    let url = "/company";
    switch (proceedType) {
        case "previewOnly": url += isDraft?"/download-offer-draft" + docId :"/download-offer" + docId; break;
        case "createOffer": url += "/create-offer"; break;
        case "saveOffer": url += "/offer-edit"; break;
        case "draftToOffer": url += "/draft-to-offer" + docId; break;
        case "saveDraft": url += "/create-offer-draft" + docId; console.log("1");break;
        case "downloadDraft" : url += "/download-offer-draft" + docId; break;
        case "saveDraftAndPreview": url += "/create-offer-draft"; console.log("2");break;
        case "print": (readOnly ? url += "/download-offer" + docId : url += "/create-offer-draft" + docId); console.log("3");break;
        case "download":  url += "/download-offer/" + documentDownlaod; console.log("4");break;
        case "deleteDraft": break;
        default: console.log("Unbehandelter Proceed Type!"); break;
    }
    console.log("🚀 ~ file: offerUtils.js:112 ~ saveAndGetPDF ~ url:", url)
    console.log("🚀 ~ file: offerUtils.js:112 ~ saveAndGetPDF ~ docId:", docId)

    //patch
    if ((docId && !createOffer && !isDraftToOffer && !printOffer && !previewOnly && !downloadOnly) || overwriteDraft || saveOffer) {
        console.log("patch: ",url);
        await iAxios.patch(url, angebotsDaten, {
            responseType: 'blob',
        })
            .then((response) => {

                console.log("🚀 ~ file: offerUtils.js:140 ~ .then ~ response:", response)

                setDocNameFromHeader(response, injectedValue,saveOffer);

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                    case "saveOffer": {
                        break;
                    }
                    case "download": download(response.data, "Angebot_" + angebotsDaten.receiver.name1 + "_" + formatDatum(angebotsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                    case "print": { printPDF(fileURL); break; }

                    default:
                        {
                            downloadPdf(blob, angebotsDaten, injectedValue); break;
                        }
                }
            })

    }
    //get
    else if (isDraftToOffer || printOffer || previewOnly || downloadOnly) {
        console.log("get: ",url);

        await iAxios.get(url, {


            responseType: 'blob',
        })
            .then((response) => {
                console.log("🚀 ~ file: offerUtils.js:153 ~ saveAndGetPDF ~ url:", url)

                console.log("🚀 ~ file: offerUtils.js:156 ~ .then ~ response:", response)

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(blob);
                switch (proceedType) {
                    case "draftToOffer":
                        setDocNameFromHeader(response, injectedValue,saveOffer);
                        if (isDownload) {
                            download(response.data, "Angebot_" + angebotsDaten.receiver.name1 + "_" + formatDatum(angebotsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;
                        } else if (preview) {
                            injectedValue.data.pdfFile = fileURL; break;
                        } else {
                            break;
                        }
                    case "print": { console.log("print"); printPDF(fileURL); break; }
                    case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                    case "downloadOnly": download(response.data, "Angebot_" + angebotsDaten.receiver.name1 + "_" + formatDatum(angebotsDaten.docContent.documentDatum) + ".pdf", "application/pdf"); break;

                    default:
                        {
                            downloadPdf(blob, angebotsDaten, injectedValue); break;
                        }
                }
            })
    }
    else {
        //post
        console.log("post: ",url);
        await iAxios.post(url, angebotsDaten, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            }
        }).then((response) => {

            console.log("🚀 ~ file: offerUtils.js:210 ~ saveAndGetPDF ~ response:", response)

            setDocNameFromHeader(response, injectedValue,saveOffer);
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(blob);
            switch (proceedType) {
                case "print": printPDF(fileURL); break;
                case "createOffer":
                    if (preview) {
                        injectedValue.data.pdfFile = fileURL;
                        injectedValue.data.offer.isDraft = false;
                        break;
                    } else {
                        injectedValue.data.offer.isDraft = false;
                        break;
                    } case "saveDraft": if (preview) {
                        injectedValue.data.pdfFile = fileURL; break;
                    } else {
                        break;
                    }
                case "download": downloadPdf(blob, angebotsDaten, injectedValue); break;
                case "previewOnly": injectedValue.data.pdfFile = fileURL; break;
                case "saveDraftAndPreview": injectedValue.data.pdfFile = fileURL; break;
                default:
                    {
                        downloadPdf(blob, angebotsDaten, injectedValue); break;
                    }
            }
            if (isCancelled && isOffer) {
                console.log("🚀 ~ file: offerUtils.js:210 ~ saveAndGetPDF ~ injectedValue.data:", injectedValue.data)

                setDocumentStatus(injectedValue.data.angebotsDaten.relationFrom.offerCancelledFromId, "INVOICE_CANCELED");
                setDocumentStatus(injectedValue.data.angebotsDaten.offerDocumentId, "INVOICE_CORRECTION");
                setRelationToOffer(injectedValue.data.angebotsDaten.relationFrom.offerCancelledFromId,
                    injectedValue.data.angebotsDaten.offerDocumentId,
                    injectedValue.data.angebotsDaten.docName);
            }


        })
            .catch((error) => {
                console.error(error);
            });
    }
    injectedValue.data.loading=false;

    return injectedValue;
};

const setRelationToOffer = async (offerDocumentId, relationToId, relationToDocName) => {
    let url = process.env.VUE_APP_BASE_API_URL + "/company/offer-edit";
    const bodyData =
    {
        "offerDocumentId": parseInt(offerDocumentId),
        "relationTo": { offerCorrectionId: relationToId, offerCorrectionDocName: relationToDocName },
        "createShipping": false,
    };
    let response = await iAxios.patch(url, bodyData)
        .then((response) => {
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};


const setDocNameFromHeader = (response, injectedValue,saveOffer) => {
    if (saveOffer) return;
    console.log('Alle Header:');
    Object.entries(response.headers).forEach(([name, value]) => {
        console.log(`${name}: ${value}`);
    });

    // Header auslesen
    if (typeof response.headers === 'object' && response.headers !== null) {
        injectedValue.data.angebotsDaten.offerDocumentId = response.headers.offerdocumentid;
        injectedValue.data.offer.offerDocumentId = response.headers.offerdocumentid;
        injectedValue.data.angebotsDaten.docName = response.headers.offerdocname;
        injectedValue.data.angebotsDaten.deliveryDocumentId = response.headers.deliverydocumentid;
        injectedValue.data.angebotsDaten.deliveryDocName = response.headers.deliverydocname;


    } else {
        console.log('Die Header-Informationen sind nicht verfügbar.');
    }
}

const downloadPdf = (blob, angebotsDaten, injectedValue) => {
    console.log("🚀 ~ file: offerUtils.js:285 ~ downloadPdf ~ injectedValue:", injectedValue)

    download(blob, "Angebot_" + angebotsDaten.receiver.name1 + "_" + formatDatum(angebotsDaten.docContent.documentDatum) + "_" + angebotsDaten.docName + ".pdf", "application/pdf");
};



/**Vorraussetzung eines iframe mit der id=pdfFrame setzen in der Komponente von der aus der Druck aufgerufen wird.
 * <iframe id="pdfFrame" style="display:none"></iframe>
 * @param {*} pdfFile -url der PDF-Datei.
 * @returns
 */
export const printPDF = async (pdfFile) => {
    const iframe = document.getElementById('pdfFrame');
    iframe.src = pdfFile;

    // Warten, bis die PDF-Datei geladen wurde
    await new Promise((resolve) => {
        iframe.onload = resolve;
    });
    iframe.contentWindow.print();
};

export const setDocumentStatus = async (offerDocumentId, status) => {

    console.log("🚀 ~ file: offerUtils.js:294 ~ setDocumentStatus ~ status:", status)


    console.log("🚀 ~ file: offerUtils.js:295 ~ setDocumentStatus ~ offerDocumentId:", offerDocumentId)

    if (isNaN(offerDocumentId)) {
        console.error("offerDocumentId ist keine Zahl!: " + offerDocumentId);
        return false;
    }
    let url = process.env.VUE_APP_BASE_API_URL + "/company/offer-status";
    const formData = new FormData();
    formData.append("offerDocumentId", parseInt(offerDocumentId));
    formData.append("documentStatus", status);
    let response = await iAxios.post(url, formData)
        .then((response) => {
            //  console.log("🚀 ~ file: offerUtils.js:18 ~ .then ~ response:", response)
        })
        .catch((error) => {
            console.error(error);
        });
    return true;
};

export const getOfferDeliverySlip = async (injectedValue, preview) => {
    const deliveryDocumentId = injectedValue.data.angebotsDaten.deliveryDocumentId;
    const angebotsDaten = injectedValue.data.angebotsDaten;
    angebotsDaten.createShipping = false;
    angebotsDaten.docContent.documentTitel = angebotsDaten.docContent.deliveryTitel;
    angebotsDaten.docContent.documentIntroduction = angebotsDaten.docContent.deliveryIntroduction;
    angebotsDaten.docContent.documentFinalText = angebotsDaten.docContent.deliveryFinalText;

    console.log("🚀 ~ file: offerUtils.js:285 ~ getOfferDeliverySlip ~ angebotsDaten:", angebotsDaten)



    if (isNaN(deliveryDocumentId)) {
        console.error("deliveryDocumentId ist keine Zahl!: ");
        return false;
    }
    const inline = preview ? "/inline" : "";
    const url = process.env.VUE_APP_BASE_API_URL + "/company/delivery-doc/" + deliveryDocumentId + inline;
    let response = await iAxios.put(url, angebotsDaten, {
        responseType: 'blob',
    }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(blob);
        if (preview) {
            injectedValue.data.pdfFile = fileURL;
        } else {
            download(blob, "Lieferschein_" + shideliveryDocumentIdppingId + ".pdf", "application/pdf");
        }
    }).catch((error) => {
        console.error(error);
    });
    return true;
}

///TODO: offer: Säubern des Codes und optimieren sowie entfernen der Consolenausgaben
///TODO: offer: FACK: Teilzahlung

///TODO: DokumentenSettings: design anpassen
///TODO: Order/Auftragsverwaltung: erstellen von Sammelangeboten
///TODO: Order/Auftragsverwaltung: umwandeln in Angebote
///TODO: Order/Auftragsverwaltung: erstellen von Angeboten
///TODO: Authentifikation: Login Prozess korrieren
///TODO: Benutzer: Mitarbeiter anlegen, bearbeiten, löschen, Username
///TODO: Offer: Angebot erstellen, bearbeiten, löschen, versenden, drucken, downloaden, in Auftrag verwandeln, in Angebot umwandeln
///TODO: Backend: Lieferschein QRCODE Navigation starten