import { ItemsService } from '@/service/ItemsService';
import { ref } from 'vue';
import { userStore } from "@/store/userStore.js";
import { Item } from "@/models/Item";
import { useDocumentUtils } from "@/utils/documentUtils";

export const findItemWithItem_id_company = (item_id_company) => {
   const store = userStore();
    return store.items.find(
      (item) => item.item_id_company === item_id_company
    );
  };

/**
 * Finds the next linked items of the given position. Returns an array in any case.
 * 
 * @param {*} xDaten the structure which contains the itemsList to check.
 * @param {*} position the position of the table document
 * 
 * @returns an array of all linked items belonging to the position (VPE and normal ones)
 */
export const findAllLinkedItemsOf = (xDaten, position) => {
    let linkedItemsOf = [];
    let index = xDaten.itemList.indexOf(position);
    xDaten.itemList.length > index + 1;
    for (let i = index + 1; i < xDaten.itemList.length; i++) {
        let potLinkedItem = xDaten.itemList.at(i);
        if (!potLinkedItem.linkedItem) {
            break;
        }
        linkedItemsOf.push(potLinkedItem);
    }
    return linkedItemsOf;
}

/**
 * 
 * @param {*} xDaten the structure which contains the itemsList to check.
 * @param {*} position the position of the table document
 * @returns tue linked item which is an VPE linked item.
 */
export const getVPELinkedItem = (xDaten, position) => {
    let allLinkedItemsOf = findAllLinkedItemsOf(xDaten, position);
    return allLinkedItemsOf.find(o => o.linkedVPEItem);
}

/**
 * 
 * @param {*} xDaten the structure which contains the itemsList to check.
 * @param {*} position the position of the table document
 * @returns the linked item which isn't an VPE linked item
 */
export const getItemsLinkedItem = (xDaten, position) => {
    let allLinkedItemsOf = findAllLinkedItemsOf(xDaten, position);
    return allLinkedItemsOf.find(o => !o.linkedVPEItem);
}

export const deleteLinkedItems = (xDaten, position) => {
    let linkedItems = findAllLinkedItemsOf(xDaten, position);
    linkedItems.forEach(lI => {
        let indexToDelete = xDaten.itemList.indexOf(lI);
        xDaten.itemList.splice(indexToDelete, 1);
    });
}

/**
 * This method synchronizes the linked items with the itemList. Its able to add,
 * remove or adjust the belonging linked item according the the position above.
 * 
 * @param {*} xDaten the structure which contains the itemsList to check.
 * @param {*} position the position which could be a holder of a linked Item.
 */
export const syncLinkedItems = async (xDaten, position) => {
    if (!position.selectedItem || typeof position.selectedItem === "string") {
        return;
    }
    // Sync Linked Item of Items
    let refLinkedItem = ref(checkAndReplaceDummyItem(position?.selectedItem?.itemParameters?.linkedItem));
    console.log("🚀 ~ file: linkedItemsUtils.js:64 ~ refLinkedItem:", refLinkedItem)

    let linkedItem = getItemsLinkedItem(xDaten, position);
    synchronizeLinkedItem(xDaten, position, refLinkedItem, linkedItem, false);

    // Sync Linked Item of VPE
    refLinkedItem = ref(checkAndReplaceDummyItem(getVPEOfPosition(position)));
    linkedItem = getVPELinkedItem(xDaten, position);
    synchronizeLinkedItem(xDaten, position, refLinkedItem, linkedItem, true);
}

const checkAndReplaceDummyItem = (refLinkedItem) => {
    if (!refLinkedItem || Object.keys(refLinkedItem).includes('itemOutPrice')) {
        return refLinkedItem;
    }
    return findItemWithItem_id_company(refLinkedItem.item_id_company);
}

const synchronizeLinkedItem = (xDaten, position, refLinkedItem, linkedItem, vpeItem) => {
    let index = xDaten.itemList.indexOf(position);
    const {fillPosition} = useDocumentUtils();
    if (refLinkedItem.value && !linkedItem) {
        // add Linked Item
        const item = new Item();

        console.log("🚀 ~ file: linkedItemsUtils.js:103 ~ item:", item)

        item.linkedItem = true;
        item.linkedVPEItem = vpeItem;
        item.selectedItem = refLinkedItem.value;
        item.posNr = xDaten.itemList.length + 1;
        fillPosition(item.linkedByItem, item,xDaten);

        xDaten.itemList.splice(index + 1, 0, { ...item });
    } else if (!refLinkedItem.value && linkedItem) {
        // remove Linked item
        let indexLinkedItem = xDaten.itemList.indexOf(linkedItem);
        xDaten.itemList.splice(indexLinkedItem, 1);
    } else if (refLinkedItem.value && linkedItem) { // condition: 
        // change linkedItems selectedItem if necessary.
        if (linkedItem.selectedItem != refLinkedItem.value) {
            linkedItem.selectedItem = refLinkedItem.value;
            fillPosition(refLinkedItem.value, linkedItem,xDaten);
        }
    } else {
       // case for !refLinkedItem && !linkedItem  
    }
}

/**
 * Returns the VPEs linked item.
 * 
 * @param {*} position the position of which the VPE_QTY will be checked to find the according VPE linked item.
 * @returns 
 */
export const getVPEOfPosition = (position) => {
    if (position.isVPE && position.VPE_QTY) {
        for (let i = 0; i < position.VPE.list.length; i++) {
            if (position.VPE.list[i].VPE_QTY == position.VPE_QTY && 
                position.VPE.list[i].linkedItem) {
                return position.VPE.list[i].linkedItem;
            }
        }
    }
    return null;    
}

/**
 * @param {*} position the position of which the quantities should be applied
 */
export const applyLinkedItemQuantities = (xDaten, position) => {
    if (position.linkedItem) {
        let linkedTo = getLinkedTo(xDaten, position);
        if (!linkedTo) {
            return;
        }
        let quantity = linkedTo.quantity;
        if (!position.linkedVPEItem) {
            let VPE = getVPELinkedItem(xDaten, linkedTo);
            // it must be a VPE and also not itself (because VPE has also linkedItems)
            if ((VPE && VPE != position) || linkedTo.isVPE) {
                position.quantity = quantity * linkedTo.VPE_QTY;
            } else {
                position.quantity = quantity;
            }
        } else {
            position.quantity = quantity;
        }
    }
}
export const applyLinkedItemQuantitiesByIstQuantity = (xDaten, position) => {
    if (position.linkedItem) {
        let linkedTo = getLinkedTo(xDaten, position);
        if (!linkedTo) {
            return;
        }
        let quantity = linkedTo.istQuantity;
        if (!position.linkedVPEItem) {
            let VPE = getVPELinkedItem(xDaten, linkedTo);
            // it must be a VPE and also not itself (because VPE has also linkedItems)
            if ((VPE && VPE != position) || linkedTo.isVPE) {
                position.istQuantity = quantity * linkedTo.VPE_QTY;
            } else {
                position.istQuantity = quantity;
            }
        } else {
            position.istQuantity = quantity;
        }
    }
}

export const getLinkedTo = (xDaten, position) => {
    if (!position.linkedItem) {
        return null;
    }

    let indexOfLinkedItem = xDaten.itemList.indexOf(position);
    for (let i = indexOfLinkedItem-1; i >= 0; i--) {
        let potLinkedto = xDaten.itemList[i];
        if (!potLinkedto.linkedItem) {
            return potLinkedto;
        }
    }
    return null;
}

export const getItemMap = async (customerUid) => {
    let store = userStore();
    if (!store.itemMap || store.itemMapCustomerUid != customerUid) {
        let itemMap = {};
        let items = await ItemsService.getItemslistPredefinedVK(customerUid,"ben1");
        items.forEach(item => {
            itemMap[item.item_id_company] = item;
        });

        store.itemMapCustomerUid = customerUid;
        store.itemMap = itemMap;
    }
    return store.itemMap;
}

export const convertToOrFromVertriebsItems = async (xDaten, show) => {
    const {fillPosition} = useDocumentUtils();
    console.log("🚀 ~ file: linkedItemsUtils.js:218 ~ xDaten:", xDaten)

    //Wenn Auftrag bereits erstellt wurde, dann nicht umwandeln
    if (xDaten.eventAction === 'ORDER' && xDaten.orderDocumentId) return;

    let itemMap = await getItemMap(xDaten.customerUid);

    if (!show) {
        // transform quantity array into rechnung daten.
        xDaten.itemList = [];
        Object?.entries(xDaten.vertriebSelection).forEach(o => {
          let itemID = o[0]; // key
          let value = o[1]; // value
            
          if (!value.quantity) {
            return;
          }

          let newPosition = new Item();
          xDaten.itemList.push(newPosition);
          
          newPosition.selectedItem = itemMap[itemID];
          fillPosition(null, newPosition,xDaten);
          syncLinkedItems(xDaten, newPosition);
          newPosition.quantity = value.quantity;
          if (value.VPE_QTY) {
            newPosition.VPE_QTY = value.VPE_QTY;
            newPosition.isVPE = true;
          }
        });
    
        if (!xDaten.itemList.length) {
          let newPosition = new Item();
          xDaten.itemList.push(newPosition);
        }
        delete xDaten.vertriebSelection;
      } else {
        // transform rechnung daten into quantity array
        xDaten.vertriebSelection = {};
        xDaten.itemList.forEach(pos => {
          if (!pos?.item_id_company) {
            return;
          }
          if (pos.linkedItem) {
            return;
          }
          xDaten.vertriebSelection[pos.item_id_company] = { quantity: pos.quantity, VPE_QTY: pos.VPE_QTY };
        });
      }
}