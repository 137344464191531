<template>
<template v-if="!store.rules.activatedModules.creditNote">
<UpgradeLicence />
  </template>
<template v-else>
  <SpeedDial
      :model="MenuItems"
      :radius="80"
      type="semi-circle"
      direction="left"
      :style="{ top: 'calc(50% - 2rem)', right: 0 }"
      :tooltipOptions="{ position: 'left' }"
      showIcon="pi pi-bars"
      hideIcon="pi pi-times" />
  <!--  <div class="response2pdf">
    <transition name="transition-anim">
      <section class="pdf-preview" v-if="pdfFile">
        <button @click.self="closePreview()">&times;</button>
        <iframe :src="pdfFile" width="100%" height="100%" />
      </section>
    </transition>
  </div> -->
  <div class="card p-fluid">
    <Loading v-show="loading" />
    <MultiSelect v-show="showAdvanced"
      :modelValue="selectedColumns"
      :options="customerUid ? columnsCustomer : columns"
      optionLabel="header"
      @update:modelValue="onToggle"
      display="chip"
      placeholder="Spalten Ansicht wählen"
      :class="dtClass"
      class="w-full" />
    <DataTable
      v-model:filters="filters"
      :value="creditnotes"
      editMode="row"
      :class="dtClass"
      scrollable
      scrollHeight="85vh"
      :filterDisplay="showAdvanced?'row':''"
      tableStyle="min-width: 10%"
      resizableColumns
      columnResizeMode="fit"
      stripedRows
      selectionMode="single"
      dataKey="creditNoteDocumentId"
      sortField="created_us"
      :sortOrder="-1"
      class="p-datatable-sm"
      @row-dblclick="openReadOnlyCreditNote"
      ref="dataTableRef">
      <template #empty>
          <div
            v-if="loading"
            class="flex justify-content-center align-items-center">
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
            <span class="text-4xl px-4"> Daten werden geladen...</span>
            <span
              class="text-center pi pi-spin pi-spinner text-4xl font-bold text-primary"></span>
          </div>
          <div v-else>
            <span class="text-2xl font-bold text-primary"
              >Keine Einträge gefunden</span
            >
          </div>
        </template>
      <Column
        :headerStyle="showAdvanced?'width: 2rem; text-align: center':'display: none'"
        :bodyStyle="showAdvanced?'text-align: center; overflow: visible':'display: none'">
        <template #header>
          <div>
            <div>
              <Button
              v-show="showAdvanced"
                type="button"
                outlined
                id="isNew"
                class="p-1"
                :class="dtClass"
                icon="pi pi-plus"
                :icon="dtClass"
                @click="handleNewRow" />
            </div>
          </div>
        </template>
        <template #body="{ data }">
          <Button
          v-show="showAdvanced"
            type="button"
            :pt="{ icon: { class: 'text-xl' } }"
            id="editCustomer"
            text
            :class="dtClass"
            icon="pi pi-file-edit"
            rounded
            @click="openReadOnlyCreditNote({ data })" />
        </template>
      </Column>

      <Column
        field="docName"
        header="Gutschriftsnummer"
        :footer="showAdvanced?'Gutschriftsnummer':''"
        key="docName"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <template v-if="data.eventAction != 'CREDITNOTE_DRAFT'">
            {{ data.docName }}
          </template>
          <template v-else>
            <span class="font-light">Entwurf</span>
          </template>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            v-model="filterModel.value"
            v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
            type="text"
            @keydown.enter="filterCallback()"
            @input="filterModel.value || filterCallback()"
            :class="dtClass"
            class="p-column-filter" />
        </template>
      </Column>
      <Column
        v-for="col of selectedColumns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        :footer="showAdvanced?col.header:''"
        :showFilterMenu="false"
        sortable
        :sortField="isSortable(col.field) ? col.field.replace('_de', '_us') : col.field">
        <template #filter="{ filterModel, filterCallback }">
          <div v-if="col.field === 'created_de' || col.field === 'updated_de'">
            <Calendar
              v-model="filterModel.value"
              selectionMode="range"
              hideOnRangeSelection
              placeholder="Auswahl"
              mask="99.99.9999"
              showButtonBar
              showIcon
              :inputClass="dtClass"
              :class="dtClass"
              iconDisplay="input"
              @date-select="filterCallback()" />
          </div>
          <div v-else>
            <InputText
              v-model="filterModel.value"
              v-tooltip.top.focus="'Zum Anwenden Enter drücken'"
              type="text"
              @keydown.enter="filterCallback()"
              @input="filterModel.value || filterCallback()"
              :class="dtClass"
              class="p-column-filter" />
          </div>
        </template>
        <template #body="{ data, field }">
          {{ data[field] }}
        </template>
        <template #loading>
          <Skeleton width="60%" height="2rem" />
        </template>
      </Column>
      <Column
        field="documentStatus"
        header="Status"
        :footer="showAdvanced?'Status':''"
        key="documentStatus"
        :showFilterMenu="false"
        :filterMenuStyle="{ width: '15%' }"
        style="min-width: 15%"
        sortable>
        <template #body="{ data }">
          <Tag
            :severity="getGutschriftSeverity(data.documentStatus)"
            :icon="getGutschriftIcon(data.documentStatus)"
            :value="getActionLabel(data.documentStatus)"
            :class="dtClass"></Tag>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @change="filterCallback()"
            :options="statuses"
            optionLabel="label"
            optionValue="value"
            placeholder="Auswählen"
            class="p-column-filter"
            :inputClass="dtClass"
            :class="dtClass"
            style="min-width: 10%"
            :showClear="true">
            <template #option="slotProps">
              <Tag
                :value="slotProps.option.label"
                :class="slotProps.option.label"
                :severity="getGutschriftSeverity(slotProps.option.value)" />
            </template>
          </Dropdown>
        </template>
      </Column>

      <!--       <Column headerStyle="width: 2rem; text-align: center" header="" footer=""
        bodyStyle="text-align: center; overflow: visible">
        <template #body="{ data }">
          <Button type="button" :userData="data" outlined text size="small" icon="pi pi-book" label="Storno"
            v-tooltip="'Korrektur Gutschrift anlegen'" severity="danger" rounded @click="openGutschrift(data)" />
        </template>
      </Column> -->
    </DataTable>
  </div>
</template>
</template>
<script setup>
import {
  ref,
  onMounted,
  onBeforeMount,
  defineAsyncComponent,
  markRaw,
  reactive,
  onUnmounted,
} from "vue";
import { FilterMatchMode, FilterService } from "primevue/api";
import { CreditNotesService } from "@/service/CreditNotesService";
import { formatCurrency } from "@/utils/formatUtils";
import Loading from '@/components/Loading';
import { useRouter } from "vue-router";
import { useDialog } from "primevue/usedialog";
import { saveAndGetPDF } from "@/utils/creditnoteUtils";
import { nextTick } from "vue";
import iAxios from "@/store/axiosInterface";
import { useConfirm } from "primevue/useconfirm";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import { useToast } from "primevue/usetoast";
const CreditNotesEditComponent = defineAsyncComponent(() =>
  import("@/views/CreditNotesEditComponent.vue")
);
const DefaultFooter = defineAsyncComponent(() =>
  import("@/views/CreditNotesEditFooter.vue")
);
const DefaultHeader = defineAsyncComponent(() =>
  import("@/views/CreditNotesEditHeader.vue")
);

const OrdersEditComponent = defineAsyncComponent(() =>
  import("@/views/OrdersEditComponent.vue")
);
const OrderEditFooter = defineAsyncComponent(() =>
  import("@/views/OrdersEditFooter.vue")
);
const OrderEditHeader = defineAsyncComponent(() =>
  import("@/views/OrdersEditHeader.vue")
);

const OffersEditComponent = defineAsyncComponent(() =>
  import("@/views/OffersEditComponent.vue")
);
const OffersEditFooter = defineAsyncComponent(() =>
  import("@/views/OffersEditFooter.vue")
);
const OffersEditHeader = defineAsyncComponent(() =>
  import("@/views/OffersEditHeader.vue")
);

const store = userStore();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const { dtClass, customerUid,showAdvanced } = defineProps({
  dtClass: {
    type: String,
    required: false,
    default: "",
  },
  customerUid: {
    type: String,
    required: false,
    default: "",
  },
  showAdvanced: {
    type: Boolean,
    required: false,
    default: true,
  },
});
FilterService.register("dateFilter", dateFilter);

const columns = ref([
  { field: "kundenNummer", header: "Kundennr" },
  { field: "name1", header: "Name" },
  { field: "postCode", header: "PLZ" },
  { field: "documentValueBrutto", header: "Gutschriftsbetrag" },
  { field: "documentValueNetto", header: "Gutschriftsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumns = ref([
  { field: "name1", header: "Name" },
  { field: "documentValueBrutto", header: "Gutschriftsbetrag" },
  { field: "created_de", header: "Erstelldatum" },
]);
const columnsCustomer = ref([
  { field: "documentValueBrutto", header: "Gutschriftsbetrag" },
  { field: "documentValueNetto", header: "Gutschriftsbetrag Netto" },
  { field: "created_de", header: "Erstelldatum" },
  { field: "updated_de", header: "Zuletzt Geändert" },
  { field: "createdUser", header: "Erstellt Benutzer" },
  { field: "updatedUser", header: "Geändert Benutzer" },
  { field: "bezahlArten", header: "Bezahlart/en" },
]);
const startColumnsCustomer = ref([
  { field: "created_de", header: "Erstelldatum" },
  { field: "documentValueBrutto", header: "Gutschriftsbetrag" },
]);

  const selectedColumns = customerUid? ref(startColumnsCustomer.value): ref(startColumns.value);
const onToggle = (val) => {
  const cols=customerUid ? columnsCustomer : columns;
  const valFields = val.map((v) => v.field);
  cols.value.forEach((col) => {
    if (
      valFields.includes(col.field) &&
      !selectedColumns.value.some(
        (selectedCol) => selectedCol.field === col.field
      )
    ) {
      selectedColumns.value.push(col);
    } else if (!valFields.includes(col.field)) {
      selectedColumns.value = selectedColumns.value.filter(
        (selectedCol) => selectedCol.field !== col.field
      );
    }
  });
};

function dateFilter(value, filter) {
  // Konvertieren ob das Datum in das Format 'yyyy-MM-dd'
  const [day, month, yearTime] = value.split(".");
  const [year, time] = yearTime.split(" ");
  const date = new Date(`${year}-${month}-${day} ${time}`);
  const formattedDate =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0");

  // Überprüfen, ob der Filterwert ein Bereich ist
  if (Array.isArray(filter)) {
    const [start, end] = filter.map((dateStr) => {
      const date = new Date(dateStr);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        date.getDate().toString().padStart(2, "0")
      );
    });
    return formattedDate >= start && formattedDate <= end;
  }

  // Überprüfen, ob das formatierte Datum den Filterwert enthält
  return formattedDate.includes(filter);
}

const filters = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name1: { value: null, matchMode: FilterMatchMode.CONTAINS },
  postCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  kundenNummer: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bezahlArten: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

/* const filtersCustomer = ref({
  docName: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueBrutto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  documentValueNetto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  created_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  updatedUser: { value: null, matchMode: FilterMatchMode.CONTAINS },
  updated_de: {
    value: null,
    matchMode: "dateFilter",
    filterFunction: dateFilter,
    dateRange: [null, null],
  },
  documentStatus: { value: null, matchMode: FilterMatchMode.CONTAINS },
  bezahlArten: { value: null, matchMode: FilterMatchMode.CONTAINS },
}); */

function isSortable(field) {
  return field === "updated_de" || field === "created_de";
}
const isMobile = ref(false);
const loading = ref(true);

const creditnotes = ref([]);
const dialog = useDialog();
let rowData = null;
const dataTableRef = ref();
const popUpLabel = reactive({ label: "" });

const statuses = reactive([
  { label: "Entwurf", value: "CREDITNOTE_DRAFT_OPEN" },
  { label: "Storniert", value: "CREDITNOTE_CANCELED" },
  { label: "Gutschrift", value: "CREDITNOTE_CORRECTION" },
  { label: "Offen", value: "CREDITNOTE_OPEN" },
  { label: "Bezahlt", value: "CREDITNOTE_PAID" },
  { label: "Teilzahlung", value: "CREDITNOTE_PARTIAL" },
]);

const popUptoggle = (event, data) => {
  rowData = data;
  popUpLabel.label = "Gutschrift " + rowData.docName;
  menu.value.toggle(event);
};

/* const menu = ref();
const popUpitems = computed(() => [
  {
    label: popUpLabel.label,
    items: [
      {
        label: "Offen",
        icon: 'pi pi-money-bill',
        command: () => {
          if (rowData && rowData.documentStatus != 'CREDITNOTE_OPEN') {
            setDocumentStatus(rowData.creditNoteDocumentId, 'CREDITNOTE_OPEN');
            rowData.documentStatus = 'CREDITNOTE_OPEN';
          }
        },
      },
      {
        label: "Bezahlt",
        icon: 'pi pi-dollar',
        command: () => {
          if (rowData && rowData.documentStatus != 'CREDITNOTE_PAID') {
            setDocumentStatus(rowData.creditNoteDocumentId, 'CREDITNOTE_PAID');
            rowData.documentStatus = 'CREDITNOTE_PAID';
          }
        },
      }
    ],
  },
]); */

const pdfFile = ref();
const handleNewRow = async (event) => {
  const dialogRef = dialog.open(CreditNotesEditComponent, {
    props: {
      header: "Gutschrift erstellen",
      style: {
        width: "35vw",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    data: {
      creditnote: {
        creditNoteDocumentId: null,
        readOnly: false,
        storno: false,
        isNew: true,
      },
      gutschriftsDaten: event.data,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let responseFile = await saveAndGetPDF(dialogRef);
          pdfFile.value = dialogRef.data.pdfFile;
        } else if (buttonType == "Generate") {
          let response = speichern(dialogRef, true);
        } else if (buttonType == "Delete") {
          //von virtalCreditNotes löschen mit creditNoteDocumentId
          refreshReRenderTable();
          /*           const index = virtualCreditNotes.value.findIndex((creditnote) => creditnote.creditNoteDocumentId == options.data.creditNoteDocumentId);
                    if (index > -1) {
                      virtualCreditNotes.value.splice(index, 1);
                    }
                    //von creditnotes löschen mit creditNoteDocumentId
                    const index2 = creditnotes.value.findIndex((creditnote) => creditnote.creditNoteDocumentId == options.data.creditNoteDocumentId);
                    if (index2 > -1) {
                      creditnotes.value.splice(index2, 1);
                    } */
        } else if (buttonType == "GoToCreditNote") {
          openCreditNote(options.data.creditNoteDocumentId);
        } else refreshReRenderTable();
      }
    },
  });
};

const refreshReRenderTable = async () => {
  await werteLaden();
};

const openReadOnlyCreditNote = async (event, proceedType) => {
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "CREDITNOTE_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.creditnoteCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;
  console.log(
    "🚀 ~ file: CreditNotesView.vue:392 ~ openReadOnlyCreditNote ~ isDraft:",
    isDraft
  );

  //event.data.responsed.creditNoteDocumentId = event.data.creditNoteDocumentId || event.data.creditNoteDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(CreditNotesEditComponent, {
    props: {
      header: isCancelled ? "Gutschriftskorrektur " : "Gutschrift",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: false,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader),
    },
    data: {
      creditnote: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        creditNoteDocumentId: event.data.creditNoteDocumentId
          ? event.data.creditNoteDocumentId
          : event.data.creditNoteDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.creditNoteDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      gutschriftsDaten: event.data,
    },
    onClose: async (options) => {
      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          //von virtalCreditNotes löschen mit creditNoteDocumentId
          refreshReRenderTable();
          /*           const index = virtualCreditNotes.value.findIndex((creditnote) => creditnote.creditNoteDocumentId == options.data.creditNoteDocumentId);
                    if (index > -1) {
                      virtualCreditNotes.value.splice(index, 1);
                    }
                    //von creditnotes löschen mit creditNoteDocumentId
                    const index2 = creditnotes.value.findIndex((creditnote) => creditnote.creditNoteDocumentId == options.data.creditNoteDocumentId);
                    if (index2 > -1) {
                      creditnotes.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "Cancellation") {
        cloneCreditNote(event, "Cancellation", dialogRef);
      } else if (buttonType == "Clone") {
        cloneCreditNote(event, "Clone", dialogRef);
      } else if (buttonType == "GoToCreditNote") {
        openCreditNote(options.data.creditNoteDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const openCreditNote = async (creditNoteDocumentId) => {
  await werteLaden();
  if (creditnotes.value) {
    creditnotes.value.forEach((creditnote) => {
      if (creditnote.creditNoteDocumentId == creditNoteDocumentId) {
        openReadOnlyCreditNote({ data: creditnote });
      }
    });
  }
};

const cloneCreditNote = async (event, proceedType, dialogRef) => {

console.log("🚀 ~ file: CreditNoteView.vue:615 ~ event:", event)
console.log("🚀 ~ file: CreditNoteView.vue:616 ~ proceedType:", proceedType)

  const copyEvent = JSON.parse(JSON.stringify(event));
  const gutschriftsDatenCopy = copyEvent.data;
  let docNameFrom = gutschriftsDatenCopy.docName;
  let creditnoteDocumentIdFrom = gutschriftsDatenCopy.creditNoteDocumentId;
  event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
  event.data.docName = null;
  event.data.creditNoteDocumentId = null;
  event.data.storno = true;
  ///TODO open paid status setzen
  switch (proceedType) {
    case "Cancellation":
      {
        event.data.docContent.creditnoteTitel = "Gutschriftskorrektur/ Gutschrift";
        event.data.documentStatus = "CREDITNOTE_CANCELED";
        event.data.docContent.creditNoteIntroduction =
          " Sie erhalten anbei die Gutschriftskorrektur/ Gutschrift zur Gutschrift " +
          docNameFrom;
        event.data.relationFrom = {
          creditnoteCancelledFromId: creditnoteDocumentIdFrom,
          creditnoteCancelledDocName: docNameFrom,
        };
        const itemList = event.data.itemList;
        itemList.forEach((item) => {
          item.quantity = item.quantity * -1;
        });
      }
      break;
    case "Clone":
      {
        event.data.docContent.creditnoteTitel = "Gutschrift";
        event.data.documentStatus = "CREDITNOTE_OPEN";
        event.data.docContent.creditNoteIntroduction =
          " Sie erhalten anbei unsere Gutschrift.";
        event.data.relationFrom = {
          duplicatedFromId: creditnoteDocumentIdFrom,
          duplicatedFromDocName: docNameFrom,
        };
        event.data.isNew = true;
      }
      break;
  }
  openReadOnlyCreditNote(event, proceedType);
};

const openOffer = (offerDocumentId) => {
  iAxios.get("/company/the-offer/" + offerDocumentId).then((response) => {
    if (response.data) {
      openOfferDialog({ data: response.data });
    }
  });
};

const openOfferDialog = async (event, proceedType) => {
  const isCloned = proceedType == "Clone";
  const isDraft = event.data.eventAction == "ORDER_DRAFT" || isCloned;
  const isCancelledDone = event.data.relationFrom.offerCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;
  const isOrderToOffer = proceedType == "fromOrder";

  //event.data.responsed.offerDocumentId = event.data.offerDocumentId || event.data.offerDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OffersEditComponent, {
    props: {
      header: isCancelled ? "Angebotskorrektur " : "Angebot",
      subHeader: isOrderToOffer
        ? "(Entwurf erstellt von Auftrag " + event.data.docName + ")"
        : isDraft
        ? "(Entwurf)"
        : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OffersEditFooter),
      header: markRaw(OffersEditHeader),
    },
    data: {
      offer: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isDraft: isDraft,
        offerDocumentId: event.data.offerDocumentId
          ? event.data.offerDocumentId
          : event.data.offerDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: false,
        storno: isCancelled,
      },
      angebotsDaten: event.data,
    },
    onClose: async (options) => {
      console.log(
        "🚀 ~ file: OffersView.vue:387 ~ onClose: ~ options:",
        options
      );

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          //von virtalOffers löschen mit offerDocumentId
          refreshReRenderTable();
          /*           const index = virtualOffers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index > -1) {
                      virtualOffers.value.splice(index, 1);
                    }
                    //von offers löschen mit offerDocumentId
                    const index2 = offers.value.findIndex((offer) => offer.offerDocumentId == options.data.offerDocumentId);
                    if (index2 > -1) {
                      offers.value.splice(index2, 1);
                    } */
        }
        refreshReRenderTable();
      } else if (buttonType == "offerToOrder") {
        console.log("offerToOrder");
        openOrderDialog(convertToOrder(event), "fromOffer");
      } else if (buttonType == "Clone") {
        openOfferDialog(cloneOffer(event, "Clone", dialogRef), "Clone");
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToOrder") {
        openOrder(options.data.orderDocumentId);
      } else refreshReRenderTable();
    },
  });
};

const convertToOrder = (event) => {
    const copyEvent = JSON.parse(JSON.stringify(event));
    const angebotsDatenCopy = copyEvent.data;
  
    let docName = angebotsDatenCopy.docName;
    let offerDocumentId = angebotsDatenCopy.offerDocumentId;
    let orderModel = angebotsDatenCopy;
  
    orderModel.docContent.documentDatum = new Date().toISOString().slice(0, 10);
    orderModel.relationFrom = {
      generatedFromOfferId: offerDocumentId,
      generatedFromOfferDocName: docName,
    };
    orderModel.docContent.orderTitel = "Auftrag";
    orderModel.salutationText = store.companySettings.salutationText;
    orderModel.docContent.orderIntroduction =
      "Sie erhalten anbei den Auftrag zum Angebot " + docName;
    orderModel.orderFinalText = store.companySettings.orderFinalText;
    orderModel.documentStatus = "ORDER_DRAFT_OPEN";
    orderModel.eventAction = "ORDER_DRAFT";
    orderModel.eventText = "ORDER_CREATED";
    orderModel.orderDocumentId = null;
    orderModel.docContent.subHeader = null;
    orderModel.isNew = true;
    return { data: orderModel };
  };

const openOrder = (orderDocumentId) => {
  iAxios.get("/company/the-order/" + orderDocumentId).then((response) => {
    if (response.data) {
      openOrderDialog({ data: response.data });
    }
  });
};

const cloneOffer = async (event, proceedType, dialogRef) => {
    const copyEvent = JSON.parse(JSON.stringify(event));
    const angebotsDatenCopy = copyEvent.data;
    let docNameFrom = angebotsDatenCopy.docName;
    let offerDocumentIdFrom = angebotsDatenCopy.offerDocumentId;
    event.data.docContent.documentDatum = new Date().toISOString().slice(0, 10);
    event.data.docName = null;
    event.data.offerDocumentId = null;
    event.data.storno = true;
    ///TODO open paid status setzen
    switch (proceedType) {
      case "Cancellation":
        {
          event.data.docContent.offerTitel = "Angebotskorrektur/ Gutschrift";
          event.data.documentStatus = "OFFER_CANCELED";
          event.data.docContent.offerIntroduction =
            " Sie erhalten anbei die Angebotskorrektur/ Gutschrift zur Angebot " +
            docNameFrom;
          event.data.relationFrom = {
            offerCancelledFromId: offerDocumentIdFrom,
            offerCancelledDocName: docNameFrom,
          };
          const itemList = event.data.itemList;
          itemList.forEach((item) => {
            item.quantity = item.quantity * -1;
          });
        }
        break;
      case "Clone":
        {
          event.data.docContent.offerTitel = "Angebot";
          event.data.documentStatus = "OFFER_PENING";
          event.data.docContent.offerIntroduction =
            " Sie erhalten anbei unsere Angebot.";
          event.data.relationFrom = {
            duplicatedFromId: offerDocumentIdFrom,
            duplicatedFromDocName: docNameFrom,
          };
          event.data.isNew = true;
        }
        break;
    }
    return event;
  };


const openOrderDialog = async (event, proceedType) => {
  console.log("openOrderDialog");
  const isCloned = proceedType == "Clone";
  const isFromCreditNote = proceedType == "fromCreditNote";
  const isDraft = event.data.eventAction == "OFFER_DRAFT" || isCloned;
  const isCancelledDone = event.data?.relationFrom?.orderCancelledFromId;
  const isCancelled = proceedType == "Cancellation" || isCancelledDone;
  const isDuplicated = isCloned || isCancelled;

  //event.data.responsed.orderDocumentId = event.data.orderDocumentId || event.data.orderDocumentId;
  // event.data.responsed.documentName = event.data.docName;
  const dialogRef = dialog.open(OrdersEditComponent, {
    props: {
      header: "Auftrag",
      subHeader: isDraft ? "(Entwurf)" : event.data.docName,
      subHeader: isDraft
        ? "(Entwurf)"
        : isFromCreditNote
        ? "(Entwurf Auftrag erstellt von Angebot " + event.data.docName + ")"
        : event.data.docName,
      style: {
        width: "",
      },
      maximizable: true,
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
    },
    templates: {
      footer: markRaw(OrderEditFooter),
      header: markRaw(OrderEditHeader),
    },
    data: {
      order: {
        isLoading: loading.value,
        isEditable: isDuplicated || isDraft,
        isCancelled: isCancelled,
        isCloned: isCloned,
        isFromOffer: true,
        isDraft: isDraft,
        orderDocumentId: event.data.orderDocumentId
          ? event.data.orderDocumentId
          : event.data.orderDocumentId,
        docName: !isDuplicated ? event.data.docName : null,
        readOnly: (event.data.orderDocumentId != null) & !isDraft,
        storno: isCancelled,
      },
      auftragsDaten: event.data,
    },
    onClose: async (options) => {
      console.log("🚀 ~ file: CreditNoteView.vue:629 ~ options:", options);

      const buttonType = options?.data?.buttonType;
      if (options.data && isDraft) {
        if (buttonType == "Save") {
        } else if (buttonType == "Delete") {
          console.log("order deleted1");
          refreshReRenderTable();
        }
        refreshReRenderTable();
      } else if (buttonType == "Cancellation") {
        cloneOrder(event, "Cancellation", dialogRef);
      } else if (buttonType == "Clone") {
        cloneOrder(event, "Clone", dialogRef);
      } else if (buttonType == "GoToOffer") {
        openOffer(options.data.offerDocumentId);
      } else if (buttonType == "GoToCreditNote") {
        openCreditNote(options.data.creditNoteDocumentId);
      } else confirmChangeToOrderWindow();
    },
  });
};

const confirmChangeToOrderWindow = () => {
  confirm.require({
    message: "Möchten Sie zum Bereich Aufträge wechseln?",
    header: "Zu Aufträge verlassen",
    icon: "pi pi-exclamation-triangle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      store.headerTitel = "AUFTRAGSVERWALTUNG";
      router.push("/OrdersView");
    },
    reject: () => {},
  });
};

const getGutschriftIcon = (statusGutschrift) => {
  switch (statusGutschrift) {
    case "CREDITNOTE_OPEN":
      return "pi pi-money-bill";
    case "CREDITNOTE_PAID":
      return "pi pi-star-fill";
    case "CREDITNOTE_PARTIAL":
      return "pi pi-star-half-fill";
    case "CREDITNOTE_CANCELED":
      return "pi pi-times";
    case "CREDITNOTE_CORRECTION":
      return "pi pi-file-edit";
  }
  return "pi pi-file-edit";
};

const getGutschriftSeverity = (statusGutschrift) => {
  switch (statusGutschrift) {
    case "CREDITNOTE_OPEN":
      return "danger";

    case "CREDITNOTE_PAID":
      return "success";

    case "CREDITNOTE_PARTIAL":
      return "warning";

    case "CREDITNOTE_CANCELED":
      return "contrast";

    case "CREDITNOTE_CORRECTION":
      return "info";
  }
  return "secondary";
};

/* const openGutschrift = (event) => {
  const dialogRef = dialog.open(CreditNotesEditComponent, {
    props: {
      header: 'Storno Gutschrift Erstellen',
      style: {
        width: '90vw',
      },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      modal: true,
    },
    templates: {
      footer: markRaw(DefaultFooter),
      header: markRaw(DefaultHeader)
    },
    data: {
      creditnote: {
        userObj: null,
        creditnoteId: data.creditnoteId,
        readOnly: false,
        storno: true
      },
      gutschriftsDaten: event.data,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let response = speichern(dialogRef);
          console.log(response.data);
        }
      }
    }
  });
}; */

const getActionLabel = (label) => {
  let status = statuses.find((o) => o.value == label);
  if (!status) {
    return label;
  }
  return status.label;
};

const formatDate = (value) => {
  const date = new Date(value);
  return date.toLocaleDateString("de-DE", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

async function zeitMessen(funktion) {
  const start = performance.now();
  await funktion();
  const end = performance.now();
  const dauer = end - start;
  console.log(`CreditNoteView Komplett geladen in ${dauer / 1000} Sekunden.`);
}

const getBezahltArten = (creditnote) => {
  const paymentMethods = creditnote?.payProgress?.paymentInput;
  if (!paymentMethods) return "";
  //paymentMethod, wird immer jeweils 1x von jeder Zahlungsart in einem String gespeichert, bspw. Rückgabe "Überweisung, Bar" für 4 Einträge paymentMethod="Überweisung, Bar, Überweisung, Bar", es wird nur jeweils das vorkommen der Zahlungsart 1x übernommen
  const uniquePaymentMethods = paymentMethods
    .map((bezahltArt) => bezahltArt.paymentMethod)
    .filter((value, index, self) => self.indexOf(value) === index);
  return uniquePaymentMethods.join(", ");
};

async function werteLaden() {
  loading.value = true;
  let allCreditNotes = null;
  await CreditNotesService.getCreditNotes(customerUid).then((data) => {
    if (data && data.creditnotes) {
      allCreditNotes = data.creditnotes.map((creditnote) => ({
        ...creditnote,
        name1: creditnote.receiver.name1,
        postCode: creditnote.receiver.plz,
        documentValueBrutto: formatCurrency(
          creditnote.docContent.documentValueBrutto*-1
        ),
        documentValueNetto: formatCurrency(
          creditnote.docContent.documentValueNetto*-1
        ),
        bezahlArten: getBezahltArten(creditnote),
      }));
    }
  });
  if (customerUid && allCreditNotes) {
    creditnotes.value = await allCreditNotes.filter(
      (d) => d.customerUid === customerUid
    );
  } else creditnotes.value = allCreditNotes;

  if (store.debug)
    console.log(
      "🚀 ~ file: CreditNotesView.vue:1035 ~ creditnotes.value:",
      creditnotes.value
    );
  loading.value = false;
}

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf
};

onMounted(() => {
  console.log("customerUid", customerUid);
  checkMobile();
  window.addEventListener("resize", checkMobile);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkMobile);
});

onBeforeMount(async () => {
  await zeitMessen(werteLaden);
  //await werteLaden();
});

function closePreview() {
  pdfFile.value = null;
}


const MenuItems = ref([
  {
    label: "Exportieren",
    icon: "pi pi-download",
    command: () => {
      dataTableRef.value.exportCSV();
      toast.add({
        severity: "success",
        summary: "Exportieren",
        detail: "Exportierte Daten herunterladen",
        life: 3000,
      });
    },
  },
  {
    label: "Neue Gutschrift erstellen",
    icon: "pi pi-file",
    command: () => {
      handleNewRow({ data: {} });
    },
  },
]);
</script>

<style scoped>
:deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

:deep(.titelline) {
  border-color: #8085a1;
  border-right-width: 0 !important;
  border-bottom-width: 0 !important;
  border-style: solid;
}

:deep(.titel) {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5rem;
  padding: 40px 0px 0px 50px;
  text-align: left;
  line-height: 0;
}

:deep(.p-card .p-card-title) {
  font-size: 1.2rem;
  font-weight: 400;
}

@media (max-width: 768px) {
  :deep(.p-fluid .p-inputtext) {
    width: 100%;
    min-width: 52px;
  }

  :deep(.p-inputtext) {
    padding: 0.75rem 0.25rem 0.75rem 0.25rem;
  }
}

:deep(.p-datatable .p-datatable-tbody) {
  padding: 0;
}

:deep(.p-inputswitch) {
  width: 3rem;
  height: 1.45rem;
  margin-top: 2px;
  top: -2px;
}

.no-outline:focus {
  outline: none !important;
  box-shadow: none !important;
}
</style>
