<template>
    <div>
      <vue-cal
        class="custom-vuecal"
        :events="events"
        locale="de"
        @cell-click="handleCellClick"
      />
    </div>
  </template>

  
  <script setup>
  import VueCal from 'vue-cal';  // Import der Vue Cal Komponente
  import 'vue-cal/dist/vuecal.css';  // Import der Standard-CSS-Datei von Vue Cal
  
  const events = [
    { start: '2024-12-01', end: '2024-12-01', title: 'Gebuchter Termin' }
  ];
  
  // Funktion zum Verarbeiten eines Zellenklicks
  function handleCellClick({ date }) {
    console.log(`Ausgewähltes Datum: ${date}`);
    // Hier könntest du zum Beispiel ein Formular öffnen, um einen neuen Termin hinzuzufügen
  }
  </script>
  
  <style scoped>
  /* Importiere die originalen Vue Cal Styles */
  @import "~vue-cal/dist/vuecal.css";
  
  /* Allgemeine Anpassungen für das Design */
  .vuecal {
    font-family: 'Roboto', sans-serif; /* Verwende eine moderne Schriftart, passend zum Interface */
    background-color: #f5f5f5; /* Hellgrau, passend zu den meisten Hintergründen */
    color: #333333; /* Dunkelgrau für Text */
  }
  
  /* Header Anpassung */
  .vuecal__header {
    background-color: #1c2531; /* Dunkler Hintergrund passend zur Menüleiste */
    color: #ffffff; /* Weißer Text */
  }
  
  /* Anpassung der Zellen und Kalendertage */
  .vuecal__cell {
    border: 1px solid #e0e0e0; /* Leichtes Grau für Zellenbegrenzungen */
    background-color: #ffffff; /* Weiß als Standardhintergrund */
  }
  
  .vuecal__cell--day {
    background-color: #f8f8f8; /* Leichtes Grau für nicht aktive Tage */
  }
  
  /* Event-Anpassungen */
  .vuecal__event {
    background-color: #0073e6; /* Primärblau für Events, um eine klare Hervorhebung zu erreichen */
    color: #ffffff; /* Weiß für Event-Texte */
    border-radius: 4px; /* Leicht abgerundete Kanten, passend zu den abgerundeten Buttons im Interface */
    padding: 5px;
  }
  
  /* Hover-Effekt für Events */
  .vuecal__event:hover {
    background-color: #005bb5; /* Dunkleres Blau für den Hover-Effekt */
  }
  
  /* Navigation-Button Anpassungen */
  .vuecal__navigation button {
    background-color: #0073e6; /* Blau für Buttons */
    color: #ffffff; /* Weißer Text */
    border: none;
    padding: 8px 14px;
    border-radius: 3px; /* Leicht abgerundet */
    transition: background-color 0.3s;
  }
  
  .vuecal__navigation button:hover {
    background-color: #005bb5; /* Hover-Effekt, etwas dunkler */
  }
  
  /* Anpassung der ausgewählten Tage */
  .vuecal__cell--current {
    background-color: #cce7ff; /* Heller Blauton für den aktuellen Tag */
  }
  
  /* Wochenenden hervorheben */
  .vuecal__cell--weekend {
    background-color: #f0f0f0; /* Leichtes Grau für das Wochenende, um es optisch etwas hervorzuheben */
  }
  
  /* Pop-up (Modal) Anpassungen */
  .vuecal__event-popover {
    background-color: #ffffff; /* Weiß für den Hintergrund */
    border: 1px solid #e0e0e0; /* Leichtes Grau für die Umrandung */
    color: #333333; /* Dunkelgrauer Text */
  }
  </style>
  
  
  