<template>
    <template v-if="store?.whoAmIData?.rules?.activatedModules?.shopify">

        <Card class="m-3">
            <template #title>Shopify Konfiguration</template>
            <template #content>
                <div class="p-3 m-auto">
                    <FloatLabel>
                        <InputText id="subject" class="w-12" type="text" v-model="shopifyData.shopifyShopName" required
                            :disabled="!invalid" />
                        <label for="subject">Shop Name</label>
                    </FloatLabel>
                </div>
                <div class="p-3 m-auto">
                    <FloatLabel>
                        <InputText id="subject" class="w-12" type="text" v-model="shopifyData.shopifyApiKey"
                            :disabled="!invalid" required />
                        <label for="subject">Shopify Access Token</label>
                    </FloatLabel>
                </div>
                <div class="p-3 m-auto">
                    <Button class="align-self-center flex" icon="pi pi-save" size="small" label="Validieren"
                        @click="validateForm" />
                </div>

                <Fieldset v-if="Object.keys(validatedData).length > 0" legend="Erforderliche Berechtigungen"
                    :toggleable=true>
                    <p class="m-0">
                        <template v-for="right in neccessaryRights">
                            <Tag v-if="hasRight(right)" severity="success" :value="right" rounded></Tag>
                            <Tag v-else severity="danger" :value="right" rounded></Tag>
                        </template>
                    </p>
                </Fieldset>

                <div class="p-3 m-auto">
                    <Button class="align-self-end flex" icon="pi pi-save" size="small" label="Speichern"
                        @click="saveForm" :disabled="invalid || Object.keys(validatedData).length === 0" />
                </div>
            </template>
        </Card>

        <Button v-if="originalShopifyData.shopifyStatus === 'ACTIVE'" icon="pi pi-warehouse" label="Katalog Abgleichen" @click="openCatalogBindings"></Button>

        <div class="flex flex-wrap p-3 m-auto" v-if="importInfo">
            {{ importInfo }}
        </div>
        <div class="flex flex-wrap p-3 m-auto" v-if="progress > 0">
            <span class="w-12 flex m-auto pb-3">Setzen der Werte in Shopify:</span>
            <div class="w-12 flex m-auto">
                <ProgressBar :value="progress" style="height: 10px" class="w-6"></ProgressBar>
            </div>
        </div>

        <div class="flex p-3 m-auto">
            <Button icon="pi pi-save" size="small" label="Load Customers" @click="loadCustomers" />
        </div>

        <div class="flex p-3 m-auto">
            <Button icon="pi pi-save" size="small" label="Load Customers" @click="loadCustomer" />
        </div>
    </template>
</template>
<script setup>
import iAxios from '@/store/axiosInterface';
import { userStore } from '@/store/userStore';
import { defineAsyncComponent, onMounted, ref } from 'vue';
import ProgressBar from 'primevue/progressbar';
import { useToast } from 'primevue/usetoast';
import { useDialog } from 'primevue/usedialog';


const store = userStore();
const toast = useToast();
const dialog = useDialog();

const ShopifyCatalogSettings = defineAsyncComponent(() => import("./ShopifyCatalogSettings.vue"));

const neccessaryRights = ['write_inventory', 'read_inventory', 'read_product_listings', 'read_products', 'read_product_feeds', 'write_locations', 'read_locations', 'write_products', 'write_customers', 'read_customers'];

const customers = ref();

// Bound to UI, manipulations shouldn't be concerned until its saved.
const shopifyData = ref({});
// Will be loaded onMounted and will be used for shopify endpoints
const originalShopifyData = ref({});

// Contains the API permissions of the given shop
const validatedData = ref({});

// If its invalid, we shouldn't save it.
const invalid = ref(true);

const openCatalogBindings = () => {
    dialog.open(ShopifyCatalogSettings, {
        props: {
            header: "Katalog Bindings",
            subHeader: "",
            style: {
                width: "65vw",
            },
            breakpoints: {
                "960px": "55vw",
                "640px": "65vw",
            },
            modal: true,
        },
        onClose: (options) => {
        },
    });
}

const hasRight = (right) => {
    return validatedData.value.permissions.find(o => {
        return o.scope === right;
    });
}

onMounted(async () => {
    let resp = await iAxios.get(process.env.VUE_APP_BASE_API_URL_COMPANY + '/shopify-data');
    shopifyData.value = resp.data;
    originalShopifyData.value = resp.data;
});

const loadCustomer = () => {
    let customerID = '7672989515939'
    iAxios.get(process.env.VUE_APP_BASE_API_URL_SHOPIFY_BRIDGE + '/shopify/customer/' + customerID + '?shopName=' + originalShopifyData.value.shopifyShopName + '&accessToken=' + originalShopifyData.value.shopifyApiKey)  // URL der API, die du abfragen möchtest
        .then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
}

const loadCustomers = () => {
    iAxios.get(process.env.VUE_APP_BASE_API_URL_SHOPIFY_BRIDGE + '/shopify/customers?shopName=' + originalShopifyData.value.shopifyShopName + '&accessToken=' + originalShopifyData.value.shopifyApiKey)  // URL der API, die du abfragen möchtest
        .then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });
}

const saveForm = () => {
    shopifyData.value.shopifyStatus = 'ACTIVE';
    iAxios.patch(process.env.VUE_APP_BASE_API_URL_COMPANY + '/shopify-data', shopifyData.value).then((resonse) => {
        toast.add({
            severity: "success",
            summary: "Success",
            detail: "Daten gespeichert",
            life: 3000,
        });
        originalShopifyData.value = shopifyData.value;
    }).catch((exception) => {
        toast.add({
            severity: "danger",
            summary: "Daten nicht gespeichert",
            detail: "Daten konnten nicht gespeichert werden.",
            life: 3000,
        });
        shopifyData.value = originalShopifyData.value;
    })
};

const validateForm = async () => {
    const url = process.env.VUE_APP_BASE_API_URL + "/company/settings";

    let resp = await iAxios.get(process.env.VUE_APP_BASE_API_URL_SHOPIFY_BRIDGE + '/shopify/permissions?shopName=' + shopifyData.value.shopifyShopName + '&accessToken=' + shopifyData.value.shopifyApiKey);
    validatedData.value = resp.data;

    let missingRight = false;
    neccessaryRights.forEach(right => {
        if (!validatedData.value.permissions.find(o => {
            return o.scope === right;
        })) {
            // Erforderliche berechtigung fehlt
            missingRight = true;
        }
    });
    invalid.value = missingRight;
}

</script>