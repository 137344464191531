import { reactive } from "vue";
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const UserService = {
  async getData() {
    try {
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/sub-list");
      const data = await response.data;
      const subs = await data.subs;
      return Promise.resolve(subs);
    } catch (e) {
      console.log(e);
    }
  },

  async getUsers() {
      return Promise.resolve(this.getData());
  },

  /**
   * 
   * @param {*} userUid - UserUid
   * @param {*} userList - Optional bei Eingabe wird die Liste nicht neu geladen
   * @returns - Benutzerdaten
   */
  async getUserDetails(userUid, userList) {
    try {
      //hole die userListe von getData und filtere nach userUid und gebe den User zurück
      userList?? await this.getData();
      const user = userList.filter((user) => user.uid === userUid);
      return Promise.resolve(user);
    } catch (e) {
      console.log(e);
    }
  },

  async createUser(user) {
    try {
      const response = await iAxios.post(process.env.VUE_APP_BASE_API_URL + "/company/sub-register", user);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

  async updateUser(user) {
    try {
      const response = await iAxios.put(process.env.VUE_APP_BASE_API_URL + "/company/sub-modify", user);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

  async deleteUser(userUid) {
    try {
      const response = await iAxios.delete(process.env.VUE_APP_BASE_API_URL + "/company/sub-delete/" + userUid);
      const data = await response.data;
      return Promise.resolve(data);
    } catch (e) {
      console.log(e);
    }
  },

};

