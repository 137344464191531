import { reactive } from "vue";
import iAxios from "@/store/axiosInterface";
import { userStore } from "@/store/userStore";

export const CustomerService = {
  async getData(timeStampBE) {
    try {
      const store = userStore();
      const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/customers");
      const data = await response.data;
      const customers = await data.customers;

      //von customers-activity-warning/nolimit Felder daysSinceLastActivity, lastActivity_en holen schnellste methode z.b. mappen oder so und danach mit den customers mergen anhand der customerUid
      const response2 = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/customers-activity-warning/nolimit");
      const data2 = await response2.data;
      const customersActivity = await data2.customersWithLessActivity;
      await customers.forEach((customer) => {
        const customerActivity = customersActivity.find((activity) => activity.customerUid === customer.customerUid);
        if (customerActivity) {
          customer.daysSinceLastActivity = customerActivity.daysSinceLastActivity;
          customer.lastActivity_en = customerActivity.lastActivity_en;
        }else{
          customer.daysSinceLastActivity = null;
          customer.lastActivity_en = null;
        }
      }
      );
      store.setDataToStorage("customers",customers);
      store.dataStates.customers = timeStampBE;
      return Promise.resolve(customers);
      //return data;
    } catch (e) {
      console.log(e);
    }
  },

  async getMetaData() {
    try {
      const res = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/customers");
/*       const res =  await iAxios.get(process.env.VUE_APP_BASE_API_URL+"/company/customers-meta");
 */      const data = await reactive(res.data.customers);
      return data;
    } catch (e) {
      console.log(e);
    }
  },
  async checkForChanges() {
    const response = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/change-stats ");
    const data = await response.data;

    console.log("🚀 ~ file: CustomerService.js:40 ~ data:", data)

    return Promise.resolve(data);
  },

  /*   async getCustomerXLarge(kim) {
      console.log(kim);
      return Promise.resolve(this.getData());
    }, */


  async getCustomerXLarge() {
    const key = "customers";
    const store = userStore();
    const states = await this.checkForChanges();
    const timestampBE = states?.customers;
    const timestampFE = store?.dataStates?.customers;

    if (store.debug) {
      console.log("🚀 ~ timestamp auf BE", timestampBE)
      console.log("🚀 ~ timestamp auf FE", timestampFE)
      console.log("🚀 ~ states:", states)
    }

    if (states.cid != store.dataStates.cid) {
      if (store.debug)console.log("CID geändert, hole Daten vom BE")
      store.dataStates.cid = states.cid;
      return Promise.resolve(this.getData(timestampBE));
    }

    const foragePassed = await store.forageCheckForKey(key);

    if (store.debug)console.log("🚀  ~ foragePassed:", foragePassed)

    if (!foragePassed || !timestampBE || !timestampFE || (timestampBE > timestampFE)) {
      if (store.debug)console.log("Hole "+key+" Daten vom BE", timestampBE, timestampFE)
      return Promise.resolve(this.getData(timestampBE));
    } else {
      if (store.debug)console.log("lade "+key+" vom Storage!")
      return store.getDataFromStorage(key);
    }
  },


  async getCustomerMetas() {
    return Promise.resolve(this.getMetaData());
  },

  async getCustomerDetails(cusomterUid) {
    try {
      const customerDetail = await iAxios.get(process.env.VUE_APP_BASE_API_URL + "/company/customer-details/" + cusomterUid);
      const data = await reactive(customerDetail.data.customer);
      return data;
    } catch (e) {
      console.log(e);
    }
  },

  async getCustomerExtraAddressList(cusomterUid) {
    try {
      const customerDetail = await this.getCustomerDetails(cusomterUid);
      const extraAddress = await customerDetail.extraAddress;
      if (extraAddress) {
        const listExtraAddress = await reactive(extraAddress.list);
        if (listExtraAddress) {
          const mainAdress = {
            bezeichnung: 'Hauptadresse',
            name1: customerDetail.isPrivateCustomer ? customerDetail.salutation + ' ' + customerDetail.firstName + ' ' + customerDetail.surName : customerDetail.firmenName,
            name2: customerDetail.name2,
            street: customerDetail.street,
            houseNumber: customerDetail.houseNumber,
            address1: customerDetail.street + ' ' + customerDetail.houseNumber,
            address2: customerDetail.address2,
            postCode: customerDetail.postCode,
            city: customerDetail.city,
            customerEditorField: customerDetail.customerEditorField,
          };
          listExtraAddress.push(mainAdress);
          return listExtraAddress;
        }
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  },

};

