<template>
  <Dialog v-if="docData?.data?.pdfFile"
    modal
    header="Vorschau"
    v-model:visible="docData.data.pdfFile"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <iframe
      :style="{ width: '50rem', height: '75rem' }"
      :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
      :src="docData?.data?.pdfFile" />
  </Dialog>
  <div class="grid">
    <div class="col-12">
      <label for="mailReceiver" class="feldLabel"> Empfänger </label>
      <div v-if="showAddCustomer">
      <Button
        v-if="!addReceiver"
        icon="pi pi-search"
        class="text-xs py-1 mt-1"
        label="Empfänger einfügen"
        text
        @click="addReceiver = true" />
      <div class="m-4" v-if="addReceiver">
        <CustomerInputSearch
          v-model="customer"
          :openPaymentsCheck=false
          @clearInput="closeAddCustomer"
          @inputSelected="handleCustomerSelected" />
      </div>
      </div>
      <InputGroup class="flex">
        <Chips
          ref="chipSendTo"
          class="flex-1"
          v-model="mailData.sendTo"
          id="mailReceiver"
          separator=";"
          addOnTab
          addOnBlur
          :pt="{
            container: { class: 'w-full border-noround-right' },
          }" />
        <Button
          class="flex-0 w-10rem border-noround-left text-gray-500"
          outlined
          :icon="bccShow == true ? 'pi pi-minus-circle' : 'pi pi-plus-circle'"
          outline
          label="CC /BCC"
          @click="toggleBCC" />
      </InputGroup>
    </div>
    <div v-if="bccShow" class="col-12">
      CC
      <Chips
        class="w-full"
        v-model="mailData.cc"
        separator=";"
        addOnTab
        addOnBlur
        :pt="{ container: { class: 'w-full' } }" />
      BCC
      <Chips
        class="w-full"
        v-model="mailData.bcc"
        separator=";"
        addOnTab
        addOnBlur
        :pt="{ container: { class: 'w-full' } }" />
    </div>
    <div class="col"></div>
    <div class="col-12">
      Betreff
      <InputText class="w-full" v-model="mailData.subject" />
    </div>
    <div class="col-12">
      Nachricht
      <Textarea
        class="w-full"
        v-model="mailData.messageRaw"
        rows="10"
        cols="30"
        placeholder="Nachricht" />
    </div>
    <div class="col flex align-items-center">
      <span>Anlage:</span>
      <Button
        size="small"
        text
        class="py-1 px-1 text-sm bg-gray-100 text-gray-900"
        :label="attachmentName"
        @click="previewAttachment" />
    </div>
    <div class="col">
      <Button
        outlined
        icon="pi pi-send"
        label="E-Mail Senden"
        @click="sendEmail" />
    </div>
  </div>
</template>

<script setup>
import { ref, inject, reactive } from "vue";
import { userStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import iAxios from "@/store/axiosInterface";
import { useToast } from "primevue/usetoast";
import { onMounted } from "vue";
import { getOrderDeliverySlip } from "@/utils/orderUtils";
import { getInvoiceDeliverySlip } from "@/utils/invoiceUtils";
import CustomerInputSearch from "@/components/CustomerInputSearch.vue";
const customer = ref();
const addReceiver = ref(false);
const mailData = reactive({
  docTitel: "",
  docType: "",
  documentId: 0,
  sendTo: [],
  cc: [],
  bcc: [],
  replyTo: "",
  subject: "",
  messageRaw: "",
  message: "",
});

const chipSendTo = ref();
const toast = useToast();
const store = userStore();
const dialogRef = inject("dialogRef");
const pdfFile = ref();
const bccShow = ref(false);
const companyName = ref(store.whoAmIData.cname);
const attachmentName = ref();
const showAddCustomer = ref(false);

const replaceAll = (str, find, replace) => {
  return str.replace(new RegExp(find, "g"), replace);
};
const sendEmail = async () => {
  let sendUrl = "/company/sendmail";
  if (mailData.docType == "CATALOG") sendUrl = "/company/sendmail-cat";
  mailData.message = mailData.messageRaw.replace(/\n/g, "<br>");
  delete mailData.messageRaw; //messageRaw wieder entfernen weil faruk es so wollte
  iAxios.post(sendUrl, mailData).then((response) => {
    if (response.status == 200) {
      toast.add({
        severity: "info",
        summary: "Email senden",
        detail:
          dialogRef.value.data.docTitel + "  Email-Sendeauftrag gestartet.",
        life: 3000,
      });
      setTimeout(() => {
        closeDialog({ buttonType: "apply" });
      }, 3000);
    } else {
      console.error("Fehler beim Senden der Email", response);
      toast.add({
        severity: "error",
        summary: "Email senden",
        detail: "Fehler beim Senden der Email",
        life: 3000,
      });
    }
  });
};

const toggleBCC = () => {
  bccShow.value = !bccShow.value;
};

const closeDialog = (data) => {
  dialogRef.value.close(data);
};
const docData=ref();
const previewAttachment = async () => {
  const isShipping = dialogRef.value.data.docType == "SHIPPING";
  const isCatalog = dialogRef.value.data.docType == "CATALOG";
   docData.value = dialogRef.value.data.docData;
  if (isShipping) {
    if (dialogRef.value.data.mainDocType == "ORDER")
      getOrderDeliverySlip(docData.value, true);
    if (dialogRef.value.data.mainDocType == "INVOICE")
      getInvoiceDeliverySlip(docData, true);
  } else if (isCatalog) {
    const pdfFile=dialogRef.value.data.previewCatalog(
      dialogRef.value.data.catalogSettings,
      false,
      docData.value
    );
  } else {
    iAxios.get(dialogRef.value.data.previewURL, mailData).then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      docData.data.pdfFile = fileURL;
    });
  }
};

const closeAddCustomer = () => {
    setTimeout(() => {
      addReceiver.value = false;
    }, 500);
};

const handleCustomerSelected = (customer) => {
  //  addReceiver.value = false
if (customer.value.emailAddress){
    // wenn email bereits in sendTo vorhanden, dann nicht hinzufügen
    if (!mailData.sendTo.includes(customer.value.emailAddress))
  mailData.sendTo.push(customer.value.emailAddress);
}else{
    toast.add({
        severity: "error",
        summary: "Email senden",
        detail: "Keine Email Adresse vorhanden",
        life: 3000,
    });
    }
};

onMounted(() => {
  if (store.debug)   console.log("🚀 ~ file: EmailEditDialog.vue:266 ~ dialogRef.value.data:", dialogRef.value.data);
 showAddCustomer.value = dialogRef.value.data?.showAddCustomer;
  mailData.docType = dialogRef.value.data.docType;
  mailData.settings = dialogRef.value.data.catalogSettings;
  mailData.mainDocType = dialogRef.value.data.mainDocType;
  mailData.itemList = dialogRef.value.data.itemList;
  mailData.customerUid = dialogRef.value.data.customerUid;
  mailData.documentId = dialogRef.value.data.documentId;
  mailData.replyTo =
    dialogRef.value.data.docEmailSettings.replyTo == null
      ? store.whoAmIData.email
      : dialogRef.value.data.docEmailSettings.replyTo;
  mailData.docTitel = dialogRef.value.data.docTitel;
  const docName = dialogRef.value.data.docName;
  mailData.subject =
    mailData.docTitel +
    " " +
    (dialogRef.value.data.mainDocType == "LETTER" || "CATALOG" ? "" : docName);
  attachmentName.value = docName + ".pdf";
  //sendTo.value = dialogRef.value.data.emailAddress;
  if (dialogRef.value.data.emailAddress)
    mailData.sendTo.push(dialogRef.value.data.emailAddress);
  if (dialogRef.value.data.cc)
    mailData.cc.push(dialogRef.value.data.docEmailSettings.cc);
  if (store.companySettings.sendCopyToAnswerEmailAddress) {
    if (dialogRef.value.data.docEmailSettings.bcc)
      mailData.bcc.push(dialogRef.value.data.docEmailSettings.bcc);
    else {
      mailData.bcc.push(store.whoAmIData.email);
    }
  }
  if (dialogRef.value.data.docEmailSettings.message) {
    mailData.messageRaw = replaceAll(
      dialogRef.value.data.docEmailSettings.message,
      "_DOC_NR",
      docName
    )
      .replace("_DOC_TITEL", mailData.docTitel)
      .replaceAll("_COMPANY_NAME", companyName.value);
  }
});
</script>
