<template>
  <div>
    <Card>
      <template #title class="p-card">
        <div class="flex justify-content-between flex-wrap">
          <div
            v-if="store.firstLogin === true"
            class="flex align-items-left w-20rem h-4rem m-1">
            <img
              src="@/assets/famtura-with-name-sh.png"
              alt="Image"
              height="80"
              class="mb-3" />
          </div>

          <div
            v-if="
              store.whoAmIData?.firstLogin === true &&
              store.whoAmIData?.role == 'ADMIN'
            "
            class="bg-bluegray-900 text-gray-50 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap">
            <div class="align-items-center hidden lg:flex">
              <span class="line-height-2">
                Erster Schritt: Bitte vervollständigen Sie Ihre Daten</span
              >
            </div>
            <span class="flex align-items-center ml-2 mr-8">
              <a class="text-white" href="#" @click="moreinfos = true"
                ><span class="underline font-bold">Mehr Infos</span></a
              >
            </span>
            <a
              v-ripple
              class="flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150 p-ripple"
              style="width: 2rem; height: 2rem">
              <i class="pi pi-times"></i>
            </a>
          </div>
          <Dialog
            v-model:visible="moreinfos"
            modal
            header="Erster Schritt: Bitte vervollständigen Sie Ihre Daten"
            :style="{ width: '100vw' }">
            <p>Bitte kontrollieren Sie die Angaben sehr genau</p>
            <p>
              Wichtig: Der Firmenname kann nahträglich nicht mehr geändert
              werden.
              <br />
              Nachdem die Daten ausgefüllt sind, gelangen Sie auf die Plattform.
            </p>
          </Dialog>
          <Dialog
            v-model:visible="changePassword"
            modal
            header="Passwort ändern"
            :style="{ width: '100vw' }">
            <ChangePasswordForm></ChangePasswordForm>
          </Dialog>

          <div class="flex align-items-right w-5.5rem h-4rem m-1">
            <Button
              class="shadow-7"
              label="Speichern"
              icon="pi pi-check"
              size="small"
              @click="saveForm()"
              outlined />
            <Button
              v-if="store.firstLogin"
              class="shadow-7"
              label="Abmelden"
              icon="pi pi-sign-out"
              size="small"
              @click="confirmLogOut()"
              severity="danger"
              outlined />
          </div>
        </div>
      </template>
      <template #content>
        <form @submit.prevent="saveForm">
          <div class="p-card p-3">
            <div class="grid">
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title">Anschrift</h2>
                <div class="p-fluid">
                  <div class="p-field pb-1">
                    <!-- Firmenname Feld -->
                    <div class="p-input-icon-right">
                      <i class="pi pi-building" />
                      <InputText
                        :disabled="!store.firstLogin"
                        id="cname"
                        v-model="formData.cname"
                        placeholder="Firmennname"
                        :invalid="validationErrors.cname"
                        @input="clearError('cname')"
                        @blur="generateSlug" />
                      <small v-if="validationErrors.cname" class="p-error">{{
                        validationErrors.cname
                      }}</small>
                    </div>
                  </div>
                  <!-- Straße Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-map-marker" />
                      <InputText
                        id="streetName"
                        v-model="formData.streetName"
                        placeholder="Straße"
                        :invalid="validationErrors.streetName"
                        @input="clearError('streetName')" />
                      <small
                        v-if="validationErrors.streetName"
                        class="p-error"
                        >{{ validationErrors.streetName }}</small
                      >
                    </div>
                  </div>
                  <!-- Hausnummer Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-home" />
                      <InputText
                        id="houseNumber"
                        v-model="formData.houseNumber"
                        placeholder="Hausnummer"
                        :invalid="validationErrors.houseNumber"
                        @input="clearError('houseNumber')" />
                      <small
                        v-if="validationErrors.houseNumber"
                        class="p-error"
                        >{{ validationErrors.houseNumber }}</small
                      >
                    </div>
                  </div>
                  <!-- Adresszusatz Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-info-circle" />
                      <InputText
                        id="adressZusatz"
                        v-model="formData.adressZusatz"
                        placeholder="Adresszusatz" />
                    </div>
                  </div>
                  <!-- Postleitzahl Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-inbox" />
                      <InputText
                        id="postCode"
                        v-model="formData.postCode"
                        placeholder="Postleitzahl"
                        :invalid="validationErrors.postCode"
                        @input="clearError('postCode')" />
                      <small v-if="validationErrors.postCode" class="p-error">{{
                        validationErrors.postCode
                      }}</small>
                    </div>
                  </div>
                  <!-- Stadt Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-globe" />
                      <InputText
                        id="city"
                        v-model="formData.city"
                        placeholder="Stadt"
                        :invalid="validationErrors.city"
                        @input="clearError('city')" />
                      <small v-if="validationErrors.city" class="p-error">{{
                        validationErrors.city
                      }}</small>
                    </div>
                  </div>
                  <!-- Steuernummer oder Umsatzsteuer-ID Feld -->
                  <div class="pt-5">
                    <div class="formgroup-inline">
                      <div class="field-radiobutton">
                        <label for="strNr">Steuernummer</label>
                        <RadioButton
                          v-model="formData.steuerNrArt"
                          name="steuerArt"
                          inputId="strNr"
                          value="strNr" />
                      </div>
                      <div class="field-radiobutton">
                        <label for="ustId"> Umsatzsteuer-Id</label>
                        <RadioButton
                          v-model="formData.steuerNrArt"
                          name="steuerArt"
                          inputId="ustId"
                          value="Ust-Id" />
                      </div>
                    </div>

                    <div class="p-input-icon-right">
                      <i class="pi pi-credit-card" />
                      <InputText
                        id="steuerUmsatz"
                        :disabled="!formData.steuerNrArt"
                        v-model="formData.steuerUmsatzId"
                        placeholder="Steuernummer oder Umsatzsteuer-ID"
                        :invalid="validationErrors.steuerUmsatzId"
                        @input="clearError('steuerUmsatzId')" />
                      <small
                        v-if="validationErrors.steuerUmsatzId"
                        class="p-error"
                        >{{ validationErrors.steuerUmsatzId }}</small
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title pt-1">Bankverbindung</h2>
                <div class="p-fluid">
                  <!-- Kontoinhaber Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        id="kontoInhaber"
                        v-model="formData.kontoInhaber"
                        placeholder="Kontoinhaber"
                        :invalid="validationErrors.kontoInhaber"
                        @input="clearError('kontoInhaber')" />
                      <small
                        v-if="validationErrors.kontoInhaber"
                        class="p-error"
                        >{{ validationErrors.kontoInhaber }}</small
                      >
                    </div>
                  </div>
                  <!-- IBAN Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-credit-card" />
                      <InputText
                        id="iban"
                        v-model="formData.iban"
                        placeholder="IBAN"
                        :invalid="validationErrors.iban"
                        @input="clearError('iban')" />
                      <small v-if="validationErrors.iban" class="p-error">{{
                        validationErrors.iban
                      }}</small>
                    </div>
                  </div>
                  <!-- BIC Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-money-bill" />
                      <InputText
                        id="bic"
                        v-model="formData.bic"
                        placeholder="BIC"
                        :invalid="validationErrors.bic"
                        @input="clearError('bic')" />
                      <small v-if="validationErrors.bic" class="p-error">{{
                        validationErrors.bic
                      }}</small>
                    </div>
                  </div>
                  <!-- Name der Bank Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-building" />

                      <InputText
                        id="bankName"
                        v-model="formData.bankName"
                        placeholder="Name der Bank"
                        :invalid="validationErrors.bankName"
                        @input="clearError('bankName')" />
                      <small v-if="validationErrors.bankName" class="p-error">{{
                        validationErrors.bankName
                      }}</small>
                    </div>
                  </div>
                  <!-- Firmen Alias Feld -->

                  <h2 class="p-card-title">Firmen Alias</h2>
                  <div class="p-fluid">
                    <div class="p-field">
                      <div class="p-input-icon-right">
                        <i class="pi pi-cloud" />
                        <InputText
                          :disabled="!store.firstLogin"
                          id="slug"
                          v-model="formData.slug"
                          placeholder="Aliasname"
                          @input="checkSlug()" />
                      </div>
                      <span v-show="warnExistSlug" style="color: red"
                        >Bereits vergeben!</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 md:col-6 lg:col-4">
                <h2 class="p-card-title">Kontakt</h2>
                <div class="p-fluid">
                  <!-- Webseite Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-link" />
                      <InputText
                        id="website"
                        v-model="formData.website"
                        placeholder="Webseite" />
                    </div>
                  </div>
                  <!-- Mobile Nummer Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-mobile" />
                      <InputText
                        id="mobileNummer"
                        v-model="formData.mobileNummer"
                        placeholder="Mobile Nummer" />
                    </div>
                  </div>
                  <!-- Festnetz Nummer Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-phone" />
                      <InputText
                        id="phone"
                        v-model="formData.phone"
                        placeholder="Festnetz Nummer"
                        :invalid="validationErrors.phone"
                        @input="clearError('phone')" />
                      <small v-if="validationErrors.phone" class="p-error">{{
                        validationErrors.phone
                      }}</small>
                    </div>
                  </div>
                  <!-- Rechtsform Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-info-circle" />
                        <Dropdown 
      :options="rechtsformen" 
      v-model="formData.rechtsForm" 
      optionLabel="label" 
      optionValue="value"
      placeholder="Rechtsform auswählen" 
    />
                    </div>
                  </div>
                  <!-- Inhaber/Geschäftsführer Vorname und Nachname Feld -->
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-user" />
                      <InputText
                        id="ownerFirstName"
                        v-model="formData.firstName"
                        placeholder="Inhaber/Geschäftsführer Vorname"
                        :invalid="validationErrors.firstName"
                        @input="clearError('firstName')" />
                      <small
                        v-if="validationErrors.firstName"
                        class="p-error"
                        >{{ validationErrors.firstName }}</small
                      >

                      <i class="pi pi-user" />
                      <InputText
                        id="ownerSurname"
                        v-model="formData.surName"
                        placeholder="Inhaber/Geschäftsführer Nachname"
                        :invalid="validationErrors.surName"
                        @input="clearError('surName')" />
                        <small v-if="(validationErrors.surName)" class="p-error">{{ validationErrors.surName }}</small>
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-briefcase" />
                      <InputText
                        id="registerGericht"
                        v-model="formData.registerGericht"
                        placeholder="Registergericht" />
                    </div>
                  </div>
                  <div class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <i class="pi pi-id-card" />
                      <InputText
                        id="hrbNummer"
                        v-model="formData.hrbNummer"
                        placeholder="HRB Nummer" />
                    </div>
                  </div>
                  <div v-if="store.firstLogin === false" class="p-field pb-1">
                    <div class="p-input-icon-right">
                      <Button
                        label="Passwort ändern"
                        icon="pi pi-key"
                        id="changePassword"
                        size="small"
                        outlined
                        v-model="formData.changePassword"
                        @click="changePassword = true" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted, nextTick } from "vue";
import { useToast } from "primevue/usetoast";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import { userStore } from "@/store/userStore";
import { storeToRefs } from 'pinia';
import ChangePasswordForm from "@/api/ChangePasswordForm.vue";
import { useRouter } from "vue-router";
import iAxios from "@/store/axiosInterface";
import isBICValidator from "validator/lib/isBIC";
import isIbanValidator from "validator/lib/isIBAN";
import { useConfirm } from "primevue/useconfirm";
import { debugToast, errorToast } from "@/components/useDebugToast";

const confirm = useConfirm();
const store = userStore();
const moreinfos = ref(false);
const changePassword = ref(false);
const warnExistSlug = ref(false);
const toast = useToast();
const router = useRouter();

async function checkSlug() {
  formData.slug = formData.slug.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  if (formData.slug.length > 3) {
    const response = await iAxios.get(
      process.env.VUE_APP_BASE_API_URL + "/company/slug/" + formData.slug
    );
    debugToast(toast, response);
    await nextTick();
    warnExistSlug.value = response.status == 294;
  }
}

const rechtsformen = [
  { label: "Einzelunternehmer", value: "Einzelunternehmer" },
  { label: "Kleinstunternehmer", value: "Kleinstunternehmer" },
  { label: "Freiberufler", value: "Freiberufler" },
  { label: "GbR (Gesellschaft bürgerlichen Rechts)", value: "GbR" },
  { label: "OHG (Offene Handelsgesellschaft)", value: "OHG" },
  { label: "KG (Kommanditgesellschaft)", value: "KG" },
  { label: "GmbH (Gesellschaft mit beschränkter Haftung)", value: "GmbH" },
  { label: "UG (haftungsbeschränkt)", value: "UG" },
  { label: "AG (Aktiengesellschaft)", value: "AG" },
  { label: "SE (Europäische Aktiengesellschaft)", value: "SE" },
  { label: "KGaA (Kommanditgesellschaft auf Aktien)", value: "KGaA" },
  { label: "GmbH & Co. KG", value: "GmbH & Co. KG" },
  { label: "AG & Co. KG", value: "AG & Co. KG" },
  { label: "eG (eingetragene Genossenschaft)", value: "eG" },
  { label: "e.V. (eingetragener Verein)", value: "e.V." },
  { label: "PartG (Partnerschaftsgesellschaft)", value: "PartG" },
  { label: "PartGmbB (Partnerschaftsgesellschaft mit beschränkter Berufshaftung)", value: "PartGmbB" },
  { label: "Stiftung", value: "Stiftung" },
  { label: "AöR (Anstalt des öffentlichen Rechts)", value: "AöR" },
];

const ibanWhitelist = [
  "AD",
  "AE",
  "AL",
  "AT",
  "AZ",
  "BA",
  "BE",
  "BG",
  "BH",
  "BR",
  "BY",
  "CH",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "EE",
  "EG",
  "ES",
  "FI",
  "FO",
  "FR",
  "GB",
  "GE",
  "GI",
  "GL",
  "GR",
  "GT",
  "HR",
  "HU",
  "IE",
  "IL",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JO",
  "KW",
  "KZ",
  "LB",
  "LC",
  "LI",
  "LT",
  "LU",
  "LV",
  "MC",
  "MD",
  "ME",
  "MK",
  "MR",
  "MT",
  "MU",
  "MZ",
  "NL",
  "NO",
  "PK",
  "PL",
  "PS",
  "PT",
  "QA",
  "RO",
  "RS",
  "SA",
  "SC",
  "SE",
  "SI",
  "SK",
  "SM",
  "SV",
  "TL",
  "TN",
  "TR",
  "UA",
  "VA",
  "VG",
  "XK",
];
// Validierungsschema mit yup
const validationSchema = yup.object({
  cname: yup.string().required("Firmenname ist erfoderlich"),
  firstName: yup
    .string()
    .required("Inhaber/Geschäftsführer Vorname ist erforderlich"),
  surName: yup
    .string()
    .required("Inhaber/Geschäftsführer Nachname ist erforderlich"),
  streetName: yup.string().required("Straße ist erforderlich"),
  houseNumber: yup.string().required("Hausnummer ist erforderlich"),
  postCode: yup.string().required("Posteitzahl ist erforderlich"),
  city: yup.string().required("Stadt ist erforderlich"),
  phone: yup.string().required("Festnetz Telefon ist erforderlich"),
  slug: yup.string().required("Aliasname ist erforderlich"),
  bankName: yup.string().required("Bankname ist erforderlich"),
  steuerUmsatzId: yup
    .string()
    .required("Steuernummer oder Umsatzsteuer-ID ist erforderlich"),

  kontoInhaber: yup.string().required("Kontoinhaber ist erforderlich"),
  /*   iban: yup.string().test("is-valid", "die eingegebene IBAN ist ungültig.", (value) => (value ? isIbanValidator(value, [{whitelist:ibanWhitelist}]) : true)),
  bic: yup.string().test("is-valid", "Die eingegebene BIC ist ungültig.", (value) => (value ? isBICValidator(value) : true)), */
  iban: yup
    .string()
    .required("Iban ist erforderlich")
    .test("is-valid", "die eingegebene IBAN ist ungültig.", (value) =>
      value ? isIbanValidator(value, [{ whitelist: ibanWhitelist }]) : true
    ),
  bic: yup
    .string()
    .required("BIC ist erforderlich")
    .test("is-valid", "Die eingegebene BIC ist ungültig.", (value) =>
      value ? isBICValidator(value) : true
    ),

  /*   iban: yup.string().test("is-valid", "IBAN ist erforderlich und muss gültig sein.", (value) => {
  if (!value) {
    return false; // Gibt einen Fehler zurück, wenn der Wert leer ist
  }
  value = value.replace(/\s/g, ''); // Leerzeichen entfernen
  return isIbanValidator(value, [{whitelist:ibanWhitelist}]);
}),
bic: yup.string().test("is-valid", "BIC ist erforderlich und muss gültig sein.", (value) => {
  if (!value) {
    return false; // Gibt einen Fehler zurück, wenn der Wert leer ist
  }
  value = value.replace(/\s/g, ''); // Leerzeichen entfernen
  return isBICValidator(value);
}), */
});

const validationErrors = reactive({});
const clearError = (field) => {
  delete validationErrors[field];
};

// Formulardaten
let formData = reactive({
  cname: "",
  city: "",
  postCode: "",
  streetName: "",
  houseNumber: "",
  phone: "",
  adressZusatz: "",
  firstName: "",
  surName: "",

  steuerUmsatzId: "",
  steuerNrArt: "strNr",
  kontoInhaber: "",
  iban: "",
  bic: "",
  bankName: "",
  website: "",
  mobileNummer: "",

  rechtsForm: "",
  registerGericht: "",
  hrbNummer: "",
  slug: "",
});

const saveForm = async () => {
  try {
    formData.bic = formData?.bic?.replace(/\s+/g, '');
    formData.iban = formData?.iban?.replace(/\s+/g, '');
    await validationSchema.validate(formData, { abortEarly: false });
    const response = await iAxios.patch(
      process.env.VUE_APP_BASE_API_URL + "/company/fullfill",
      formData
    );

    if (response.status == 200) {
      store.getWhoAmIData;
    }
    debugToast(toast, response);

    if (store.firstLogin) store.setRegistrationConfirmed(true);
    toast.add({
      severity: "success",
      summary: "Erfolg",
      detail: "Profildaten erfolgreich gespeichert",
      life: 2000,
    });
    setTimeout(() => {
      store.firstLogin = false;
      router.push("/faststart");
    }, 2000);
  } catch (error) {
    //errorToast(toast, error);
    if (error instanceof yup.ValidationError) {
      // err.inner ist ein Array von Validierungsfehlern
      for (let err of error.inner) {
        validationErrors[err.path] = err.message;
        toast.add({
          severity: "error",
          summary: "Eingabefehler",
          detail: `${err.message}`,
          life: 3000,
        });
      }
    }
  }
};

function generateSlug() {
  let text = formData.cname ?? "";
  text = text.replace(/\s+/g, ""); // Alle Leerzeichen entfernen

  // Unicode-Normalisierung auf Form C anwenden
  text = text.normalize("NFC");

  // Umlaute und Buchstaben mit Akzenten ersetzen
  text = text
    .replace(/ü/g, "u")
    .replace(/ö/g, "o")
    .replace(/ä/g, "a")
    .replace(/_/g, "-")
    .replace(/ /g, "") // Alle Leerzeichen entfernen
    .replace(/[^\w\s]/gi, "") // Alle Sonderzeichen entfernen
    .toLowerCase(); // Text in Kleinbuchstaben umwandeln

  // Text in Kleinbuchstaben umwandeln
  text = text.toLowerCase();

  if (text.length > 3 && (!formData.slug || formData.slug == "")) {
    formData.slug = text;
    checkSlug();
  }
}

async function werteLaden() {
  const companyData = await iAxios.get(
    process.env.VUE_APP_BASE_API_URL + "/company/details"
  );
  store.companyData = companyData.data;
  debugToast(toast, companyData);
  if (store.debug)
    console.log(
      "🚀 ~ file: CompanySettingsForm.vue:523 ~ companyData:",
      companyData
    );

  Object.assign(formData, companyData.data);
}

onMounted(() => {
  werteLaden();
});

const confirmLogOut = () => {
  confirm.require({
    message: `Das Abmelden führt dazu, das die Eingaben verloren gehen! Sind Sie sicher?`,
    header: "Abmelden bestätigen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      logout();
    },
    reject: () => {},
  });
};

const logout = () => {
  try {
    store.logout();
    router.push("/");
  } catch (e) {
    console.log("\n\n logout error", e);
  }
};
</script>

<style scoped>
.p-card {
  text-align: left !important;
}
.p-card-title {
  font-size: large !important;
}
.form-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.form-column {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 10px;
}

button {
  margin-top: 20px;
}
</style>
