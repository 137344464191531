// @/utils/paymentUtils.js
import { ref,defineAsyncComponent } from 'vue';
import iAxios from "@/store/axiosInterface";
import { CustomerService } from "@/service/CustomerService"; // Korrigierter Import
import { useDialog } from "primevue/usedialog";
import { userStore } from "@/store/userStore";

export const usePaymentUtils = () => {
  const dialog = useDialog();
  const store=userStore();
const CustomerPaymentInput = defineAsyncComponent(() =>
  import("@/components/CustomerPaymentInput.vue")
);
  //const allCustomers = ref([]);
  const customerHistory = ref([]); // State für die Kundenhistorie

  // Funktion zum Laden der Kundendaten
  const loadCustomerData = async (cusomterUid) => {
    try {
      let data = null;
      if (store.customerLoaded) {
        data = store.customers;}
        else {
      data= await CustomerService.getCustomerDetails(cusomterUid);
      store.customers=data;
      store.customerLoaded=true;
      }
/*       if (!data) {
        allCustomers.value = [];
        return;
      }
      allCustomers.value = data.map((customer) => ({
        ...customer,
      })); */
    } catch (error) {
      console.log(error);
    }
  };

  // Funktion zum Laden der Kundenhistorie
  const loadCustomerHistory = async (customerUid) => {
    try {
      let url = "/company/customer-history/" + customerUid;
      const response = await iAxios.get(url);
      customerHistory.value = response.data.customerHistory; // Setze die Kundenhistorie
      return response.data.customerHistory;
    } catch (error) {
      console.log(error);
    }
  };

  // Alle Zahlungsinputs zusammenfassen
  const concatPayInputs = (customer, history) => {
    const customerPayprogress = customer?.extended?.payProgress?.paymentInput || [];
    const allInvoicePaymentInputs =
      history?.invoices?.flatMap((invoice) =>
        invoice.payProgress?.paymentInput ? invoice.payProgress.paymentInput : []
      ) || [];
    const allOrderPaymentInputs =
      history?.orders
        ?.filter((order) => !order.relationTo?.invoice)
        .flatMap((order) =>
          order.payProgress?.paymentInput ? order.payProgress.paymentInput : []
        ) || [];
    const allCreditNotePaymentInputs =
      history?.creditnotes?.flatMap((creditNote) =>
        creditNote.payProgress?.paymentInput ? creditNote.payProgress.paymentInput : []
      ) || [];

    return [...customerPayprogress, ...allInvoicePaymentInputs, ...allOrderPaymentInputs, ...allCreditNotePaymentInputs];
  };

  // Alle Dokumente zusammenfassen
  const concatAllDocuments = (history) => {
    const allInvoices = history?.invoices || [];
    const allCreditNotes = history?.creditnotes    || [];
    const allOrders = history?.orders?.filter(
      (order) => !order.relationTo?.invoice
    ) || [];
    return [...allInvoices, ...allOrders, ...allCreditNotes];
  };

  // Total Amount berechnen
  const totalAmount = (history, timeFrom, timeTo) => {
    let total = 0;
    const allDocs = concatAllDocuments(history);
    if (timeFrom && timeTo) {
      const filteredDocs = allDocs.filter((doc) =>
        new Date(doc.docContent.leistungsdatum) >= timeFrom &&
        new Date(doc.docContent.leistungsdatum) <= timeTo
      );
      total = filteredDocs.reduce((sum, doc) => sum + doc.docContent.documentValueBrutto, 0);
    } else {
      total = allDocs.reduce((sum, doc) => sum + doc.docContent.documentValueBrutto, 0);
    }
    return total;
  };

  // Total Paid berechnen
  const totalPaid = (allPayInputs) => {
    return allPayInputs.reduce((sum, payment) => {
      //return payment.event == "PAYMENT_BALANCE" ? sum - payment.paymentAmount  :payment.event !== "PAYMENT_DELETED" ? sum + payment.paymentAmount : sum;
      return payment.event !== "PAYMENT_DELETED" &&  payment.event !== "PAYMENT_BALANCE"? sum + payment.paymentAmount : sum;

    }, 0);
  };

  // Account Balance berechnen (überladen)
  const accountBalance = (totalPaidValue, totalAmountValue) => {
    return totalPaidValue - totalAmountValue;
  };

/*   const getCustomer=(customerUid)=>{
    return allCustomers.value.find(c => c.customerUid === customerUid);
  } */

  // Hauptfunktion zum Berechnen des Kontostands
  const getAccountBalance = async (customerUid) => {
    const customer = await loadCustomerData(customerUid); // Lade die Kundendaten

    //const customer = getCustomer(customerUid); // Finde den Kunden

    await loadCustomerHistory(customerUid); // Lade die Kundenhistorie

/*     if (!customer || customerHistory.value.length === 0) {
      throw new Error('Kunde oder Kundenhistorie nicht gefunden');
    } */

    const allPayInputs = concatPayInputs(customer, customerHistory.value);
    const totalPaidValue = totalPaid(allPayInputs);
    const totalAmountValue = totalAmount(customerHistory.value); // hier keine Zeitfilterung, wenn nicht benötigt
    return accountBalance(totalPaidValue, totalAmountValue);
  };

  const openPayment = async (customer) => {
    const customerHistory = await loadCustomerHistory(customer.customerUid); // Finde den Kunden
    const allPayInputs = concatPayInputs(customer, customerHistory);
    const totalPaidValue = totalPaid(allPayInputs);
    const totalAmountValue = totalAmount(customerHistory); // hier keine Zeitfilterung, wenn nicht benötigt

    console.log("🚀 ~ file: CustomerPaymentList.vue:511 ~ openPayment ~ openPayment");
    dialog.open(CustomerPaymentInput, {
      props: {
        header: "Zahlung",
        style: {
          width: "40vw",
        },
        breakpoints: {
          // breakpoints so lassen! bei fragen an abdullah
          "831px": "100vw",
        },
        modal: true,
        closable: true,
      },
      data: {
        customer: customer,
        totalAmount: totalAmountValue,
        totalPaid: totalPaidValue,
        allDocuments: concatAllDocuments(customerHistory),
        payInputs: allPayInputs,
      },
      templates: {},
      onClose: async (options) => {
        const data = options?.data;
        if (data) {
  
        }
      },
    });
  };

  return {
    loadCustomerData,
    loadCustomerHistory,
    concatPayInputs,
    concatAllDocuments,
    totalAmount,
    totalPaid,
    accountBalance,
    getAccountBalance,
    openPayment,
    customerHistory, // Geben Sie auch die Kundenhistorie zurück
  };
};
